import React, { useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Question from "./Question";
import Privacy from "./Privacy";
import { withRouter } from "react-router-dom";
import SelectBar from "./SelectBar";
import Slider from "./Slider";
import { SaveBanner } from "./styles";

const Form = ({ history }) => {
  const [data, setData] = useState({
    name: "",
    year: "",
    gender: "",
    class: "",
    interest: "2",
    "binge-study": "2",
    "student-org": "",
    email: "",
    "time-zone": "",
  });

  const [animate, setAnimate] = useState(false); // for Save Confirmation Banner

  const keys = [
    "name",
    "year",
    "gender",
    "class",
    "interest",
    "binge-study",
    "student-org",
    "email",
    "time-zone",
  ];

  function bannerUp() {
    setAnimate(false);
  }

  function checkEmail() {
    const spamWords = [
      "a",
      "test",
      "spam",
      "shit",
      "fuck",
      "bitch",
      "cock",
      "cunt",
      "dick",
      "faggot",
      "ass",
      "titty",
      "titties",
    ];
    const specialChars = ["!", "*", "&", "^", "$", "#"];

    if (!data["email"].toLowerCase().includes("@umich.edu")) {
      alert("Please enter your @umich.edu email");
      return false;
    }

    let tempEmail = data["email"].toLowerCase().trim();
    let before = tempEmail.substr(0, tempEmail.indexOf("@"));

    let spam = false;
    spamWords.map((word, index) => {
      if (before == word) {
        spam = true;
      }
    });

    specialChars.map((char, index) => {
      if (tempEmail.includes(char)) {
        spam = true;
      }
    });

    if (tempEmail.split("@").length - 1 > 1) {
      spam = true;
    }
    if (
      !(
        tempEmail.toLowerCase().slice(tempEmail.length - 10) === "@umich.edu"
      ) ||
      before.includes(" ") ||
      spam ||
      before.length <= 1
    ) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  }

  function pushToSheets(anotherClass) {
    if (!checkEmail()) {
      return false;
    }

    // UNCOMMENT to check for all filled in
    for (var i = 0; i < keys.length; i++) {
      if (!data[keys[i]]) {
        alert("Please fill in all fields");
        return false;
      }

      if (keys[i] == "class") {
        let selected = data[keys[i]];
        let found = false;
        for (var k = 0; k < options.length; ++k) {
          // iterate through all classes
          if (selected == options[k]["value"]) {
            found = true;
          }
        }
        if (!found) {
          alert("Please enter a class on the list");
          window.scrollTo({
            top: window.innerHeight * 4,
            left: 0,
            behavior: "smooth",
          });
          return false;
        }
      }
    }

    var formData = new FormData();
    for (var key in data) {
      if (key == "email") {
        formData.append(key, data[key].toLowerCase().trim());
      } else {
        formData.append(key, data[key].trim());
      }
    }
    setData({ ...data, class: "", interest: "2", ambition: "" });
    fetch(
      "https://script.google.com/macros/s/AKfycbyMJ9lO7HWmfgXEvW52HOMYkCGk5w5ZMWS4WZk80cYFO3vXggZ5_0iR/exec",
      { method: "POST", body: formData }
    );
    if (anotherClass) {
      setAnimate(true);
      setTimeout(bannerUp, 4000);
      window.scrollTo({
        top: window.innerHeight * 4,
        left: 0,
        behavior: "smooth",
      });
    }
    return true;
  }

  function submit() {
    if (pushToSheets()) {
      history.push("/submitted");
    }
  }

  function onChangeListener(key, value) {
    setData({ ...data, [key]: value });
  }

  useEffect(() => {}, [data]);

  return (
    <ReactFullpage
      //fullpage options
      scrollingSpeed={1000} /* Options here */
      autoScrolling={false}
      fitToSection={false}
      licenseKey={"C30CB3E3-6F854137-B837B6F5-B56F03A9"}
      render={({ state, fullpageApi }) => {
        return (
          <>
            <ReactFullpage.Wrapper>
              <SaveBanner animate={animate}>
                Saved your class, fill out these 3 fields for another
              </SaveBanner>
              <Privacy
                message="Once you fill this out, we'll match you with 3 buddies in your class based on the similarity of your responses"
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                buttonMessage="Let's get started"
              />

              <Question
                title="To start off, what's your full name?"
                label="Full Name"
                keyName={keys[0]}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[0]]}
              />

              <SelectBar
                title="What year are you?"
                label="Year"
                keyName={keys[1]}
                choices={year}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[1]]}
              />

              <SelectBar
                title="How do you identify?"
                label="Gender"
                keyName={keys[2]}
                choices={gender}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[2]]}
              />

              <SelectBar
                title="Which class are you taking?"
                label="Class"
                keyName={keys[3]}
                choices={options}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[3]]}
              />

              <Slider
                title="I am interested in taking this class"
                keyName={keys[4]}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[4]]}
              />

              <Slider
                title="I usually binge study a couple of days before a midterm"
                keyName={keys[5]}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[5]]}
              />

              <SelectBar
                title="Which type of student org are you most involved in?"
                label="Select affiliation"
                keyName={keys[6]}
                choices={orgs}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[6]]}
              />

              <SelectBar
                title="When it is 12 pm (midnight) in Ann Arbor, what time is it where you are?"
                label="Time"
                keyName={keys[8]}
                choices={timeZones}
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                onChange={onChangeListener}
                initial={data[keys[8]]}
              />

              <Privacy
                message="Just a heads up, we will be sharing your email and any similarities you have with your buddies"
                moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                buttonMessage="Continue"
              />

              <Question
                title="What's your UMich email?"
                label="Email"
                keyName={keys[7]}
                moveSectionDown={pushToSheets}
                onChange={onChangeListener}
                submitFunction={submit}
                submit={true}
                initial={data[keys[7]]}
              />
            </ReactFullpage.Wrapper>
          </>
        );
      }}
    />
  );
};

export default withRouter(Form);

const orgs = [
  { value: "Business", label: "Business" },
  { value: "Engineering", label: "Engineering" },
  { value: "Greek Life", label: "Greek Life" },
  { value: "Medical", label: "Medical" },
  { value: "Military", label: "Military" },
  { value: "Religious", label: "Religious" },
  { value: "Sports", label: "Sports" },
  {
    value: "Social - but not Greek Life",
    label: "Social - but not Greek Life",
  },
  { value: "Other", label: "Other" },
  { value: "None yet!", label: "None yet!" },
];

const year = [
  { value: "Freshman", label: "Freshman" },
  { value: "Sophomore", label: "Sophomore" },
  { value: "Junior", label: "Junior" },
  { value: "Senior", label: "Senior" },
  { value: "5th Year", label: "5th Year" },
  { value: "First Year Grad", label: "First Year Grad" },
  { value: "Second Year Grad", label: "Second Year Grad" },
  { value: "Third Year Grad", label: "Third Year Grad" },
  { value: "Fourth Year Grad +", label: "Fourth Year Grad +" },
];

const timeZones = [
  { value: "Midnight", label: "Midnight" },
  { value: "1 am", label: "1 am" },
  { value: "2 am", label: "2 am" },
  { value: "3 am", label: "3 am" },
  { value: "4 am", label: "4 am" },
  { value: "5 am", label: "5 am" },
  { value: "6 am", label: "6 am" },
  { value: "7 am", label: "7 am" },
  { value: "8 am", label: "8 am" },
  { value: "9 am", label: "9 am" },
  { value: "10 am", label: "10 am" },
  { value: "11 am", label: "11 am" },
  { value: "noon", label: "noon" },
  { value: "1 pm", label: "1 pm" },
  { value: "2 pm", label: "2 pm" },
  { value: "3 pm", label: "3 pm" },
  { value: "4 pm", label: "4 pm" },
  { value: "5 pm", label: "5 pm" },
  { value: "6 pm", label: "6 pm" },
  { value: "7 pm", label: "7 pm" },
  { value: "8 pm", label: "8 pm" },
  { value: "9 pm", label: "9 pm" },
  { value: "10 pm", label: "10 pm" },
  { value: "11 pm", label: "11 pm" },
];

const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Non-Binary", label: "Non-Binary" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

const options = [
  { value: "AAS 201 - Andre", label: "AAS 201 - Andre" },
  { value: "AAS 208 - Miller", label: "AAS 208 - Miller" },
  { value: "AAS 224 - Doris", label: "AAS 224 - Doris" },
  { value: "AAS 232 - Poulson-Bryant", label: "AAS 232 - Poulson-Bryant" },
  { value: "AAS 247 - Peterson", label: "AAS 247 - Peterson" },
  { value: "AAS 248 - Ellsworth", label: "AAS 248 - Ellsworth" },
  { value: "AAS 251 - Woods", label: "AAS 251 - Woods" },
  { value: "AAS 262 - Countryman", label: "AAS 262 - Countryman" },
  { value: "AAS 303 - Mahan", label: "AAS 303 - Mahan" },
  { value: "AAS 330 - Ward", label: "AAS 330 - Ward" },
  { value: "AAS 337 - Simmons", label: "AAS 337 - Simmons" },
  { value: "AAS 338 - Levy-Hussen", label: "AAS 338 - Levy-Hussen" },
  { value: "AAS 347 - Zelner", label: "AAS 347 - Zelner" },
  { value: "AAS 418 - Hutchings", label: "AAS 418 - Hutchings" },
  { value: "AAS 460 - Stein", label: "AAS 460 - Stein" },
  { value: "AAS 116 - Mwipopo", label: "AAS 116 - Mwipopo" },
  { value: "AAS 126", label: "AAS 126" },
  { value: "AAS 126 - Ayoola", label: "AAS 126 - Ayoola" },
  { value: "AAS 226 - Ayoola", label: "AAS 226 - Ayoola" },
  { value: "AAS 316 - Mpesha", label: "AAS 316 - Mpesha" },
  { value: "AAS 426 - Murray", label: "AAS 426 - Murray" },
  { value: "AAS 516 - Mwipopo", label: "AAS 516 - Mwipopo" },
  { value: "AAS 518", label: "AAS 518" },
  { value: "AAS 518 - Ayoola", label: "AAS 518 - Ayoola" },
  { value: "AAS 536 - Mpesha", label: "AAS 536 - Mpesha" },
  { value: "AAS 103 - Klein", label: "AAS 103 - Klein" },
  { value: "AAS 104 - Wells", label: "AAS 104 - Wells" },
  { value: "AAS 104 - Pearson", label: "AAS 104 - Pearson" },
  { value: "AAS 202 - Boisseron", label: "AAS 202 - Boisseron" },
  { value: "AAS 206 - Ampene", label: "AAS 206 - Ampene" },
  { value: "AAS 290 - Ellsworth", label: "AAS 290 - Ellsworth" },
  { value: "AAS 290 - Opoku-Agyemang", label: "AAS 290 - Opoku-Agyemang" },
  { value: "AAS 317 - Sweeney", label: "AAS 317 - Sweeney" },
  { value: "AAS 358 - Wilson", label: "AAS 358 - Wilson" },
  { value: "AAS 358 - Jimenez", label: "AAS 358 - Jimenez" },
  { value: "AAS 358 - Jones", label: "AAS 358 - Jones" },
  { value: "AAS 358 - Roby", label: "AAS 358 - Roby" },
  { value: "AAS 358 - Mpesha", label: "AAS 358 - Mpesha" },
  { value: "AAS 358 - Strongman", label: "AAS 358 - Strongman" },
  { value: "AAS 358 - Ampene", label: "AAS 358 - Ampene" },
  { value: "AAS 358 - Klein", label: "AAS 358 - Klein" },
  { value: "AAS 362 - Doris", label: "AAS 362 - Doris" },
  { value: "AAS 394 - Poulson-Bryant", label: "AAS 394 - Poulson-Bryant" },
  { value: "AAS 395 - Thurman", label: "AAS 395 - Thurman" },
  { value: "AAS 453 - Pitcher", label: "AAS 453 - Pitcher" },
  { value: "AAS 458 - Jones", label: "AAS 458 - Jones" },
  { value: "AAS 458 - Davis", label: "AAS 458 - Davis" },
  { value: "AAS 458 - Ricco", label: "AAS 458 - Ricco" },
  { value: "AAS 458 - Stein", label: "AAS 458 - Stein" },
  { value: "AAS 495 - Ashforth", label: "AAS 495 - Ashforth" },
  { value: "AAS 495 - Ekotto", label: "AAS 495 - Ekotto" },
  { value: "AAS 498 - Ward", label: "AAS 498 - Ward" },
  { value: "AAS 498 - Thompson", label: "AAS 498 - Thompson" },
  { value: "AAS 558 - Santamarina", label: "AAS 558 - Santamarina" },
  { value: "AAS 558", label: "AAS 558" },
  { value: "AAS 558 - Settles", label: "AAS 558 - Settles" },
  { value: "AAS 558 - Kelow-Bennett", label: "AAS 558 - Kelow-Bennett" },
  { value: "ACC 301 - Oswald", label: "ACC 301 - Oswald" },
  { value: "ACC 301 - Kama", label: "ACC 301 - Kama" },
  { value: "ACC 301 - Drogt", label: "ACC 301 - Drogt" },
  { value: "ACC 301 - Williams", label: "ACC 301 - Williams" },
  { value: "ACC 312 - Ball", label: "ACC 312 - Ball" },
  { value: "ACC 336 - Nezafat", label: "ACC 336 - Nezafat" },
  { value: "ACC 338 - Dittmar", label: "ACC 338 - Dittmar" },
  { value: "ACC 418 - Lehavy", label: "ACC 418 - Lehavy" },
  { value: "ACC 471 - Williams", label: "ACC 471 - Williams" },
  { value: "ACC 471", label: "ACC 471" },
  { value: "ACC 533 - Miller", label: "ACC 533 - Miller" },
  { value: "ACC 551 - Nagar", label: "ACC 551 - Nagar" },
  { value: "ACC 552 - Omartian", label: "ACC 552 - Omartian" },
  { value: "ACC 558 - Oswald", label: "ACC 558 - Oswald" },
  { value: "ACC 564 - Ball", label: "ACC 564 - Ball" },
  { value: "ACC 565 - Krolick", label: "ACC 565 - Krolick" },
  { value: "AERO 102 - Yau, Kober", label: "AERO 102 - Yau, Kober" },
  { value: "AERO 202 - Penry", label: "AERO 202 - Penry" },
  { value: "AERO 311 - Wilkinson", label: "AERO 311 - Wilkinson" },
  { value: "AERO 411 - Smith", label: "AERO 411 - Smith" },
  { value: "AEROSP 200 - Halow", label: "AEROSP 200 - Halow" },
  { value: "AEROSP 201 - Cutler", label: "AEROSP 201 - Cutler" },
  { value: "AEROSP 205 - Washabaugh", label: "AEROSP 205 - Washabaugh" },
  { value: "AEROSP 215 - Sodano", label: "AEROSP 215 - Sodano" },
  { value: "AEROSP 225 - Powell", label: "AEROSP 225 - Powell" },
  { value: "AEROSP 305 - Smith", label: "AEROSP 305 - Smith" },
  { value: "AEROSP 315 - Friedmann", label: "AEROSP 315 - Friedmann" },
  { value: "AEROSP 325 - Fidkowski", label: "AEROSP 325 - Fidkowski" },
  { value: "AEROSP 335 - Jorns", label: "AEROSP 335 - Jorns" },
  { value: "AEROSP 341 - Gorodetsky", label: "AEROSP 341 - Gorodetsky" },
  { value: "AEROSP 343 - Girard", label: "AEROSP 343 - Girard" },
  { value: "AEROSP 405 - Smith", label: "AEROSP 405 - Smith" },
  { value: "AEROSP 423 - Fidkowski", label: "AEROSP 423 - Fidkowski" },
  { value: "AEROSP 470 - Bernstein", label: "AEROSP 470 - Bernstein" },
  { value: "AEROSP 483 - Cutler", label: "AEROSP 483 - Cutler" },
  { value: "AEROSP 495 - Gamba", label: "AEROSP 495 - Gamba" },
  { value: "AEROSP 495 - Halow", label: "AEROSP 495 - Halow" },
  { value: "AEROSP 495 - Washabaugh", label: "AEROSP 495 - Washabaugh" },
  { value: "AEROSP 495 - Smith", label: "AEROSP 495 - Smith" },
  {
    value: "AEROSP 510 - Sundararaghavan",
    label: "AEROSP 510 - Sundararaghavan",
  },
  { value: "AEROSP 525 - Raman", label: "AEROSP 525 - Raman" },
  { value: "AEROSP 532 - Gamba", label: "AEROSP 532 - Gamba" },
  { value: "AEROSP 533 - Driscoll", label: "AEROSP 533 - Driscoll" },
  { value: "AEROSP 543 - Cesnik", label: "AEROSP 543 - Cesnik" },
  { value: "AEROSP 548 - Kolmanovsky", label: "AEROSP 548 - Kolmanovsky" },
  { value: "AEROSP 550 - Ozay", label: "AEROSP 550 - Ozay" },
  { value: "AEROSP 551 - Meerkov", label: "AEROSP 551 - Meerkov" },
  { value: "AEROSP 552 - Jeannin", label: "AEROSP 552 - Jeannin" },
  { value: "AEROSP 583 - Renno", label: "AEROSP 583 - Renno" },
  { value: "AEROSP 588 - Martins", label: "AEROSP 588 - Martins" },
  { value: "AES 218 - Harris", label: "AES 218 - Harris" },
  { value: "AES 218 - Winkelseth", label: "AES 218 - Winkelseth" },
  { value: "AES 220 - Bodary", label: "AES 220 - Bodary" },
  { value: "AES 240 - Chen", label: "AES 240 - Chen" },
  { value: "AES 241 - Bodary", label: "AES 241 - Bodary" },
  { value: "AES 313 - Miller", label: "AES 313 - Miller" },
  { value: "AES 313 - George", label: "AES 313 - George" },
  { value: "AES 315 - Stack", label: "AES 315 - Stack" },
  { value: "AES 332 - Kern", label: "AES 332 - Kern" },
  { value: "AES 332", label: "AES 332" },
  { value: "AES 333 - Pollick", label: "AES 333 - Pollick" },
  { value: "AES 336 - Chen", label: "AES 336 - Chen" },
  { value: "AES 425 - Ulrich", label: "AES 425 - Ulrich" },
  { value: "AES 446 - Hasson", label: "AES 446 - Hasson" },
  { value: "AES 451 - Harris", label: "AES 451 - Harris" },
  { value: "AES 403", label: "AES 403" },
  { value: "ALA 101 - Hoffman", label: "ALA 101 - Hoffman" },
  { value: "ALA 103 - Woods", label: "ALA 103 - Woods" },
  { value: "ALA 109 - Eickmeyer", label: "ALA 109 - Eickmeyer" },
  { value: "ALA 115 - Bhaumik", label: "ALA 115 - Bhaumik" },
  { value: "ALA 115 - Mank", label: "ALA 115 - Mank" },
  { value: "ALA 118 - Oney", label: "ALA 118 - Oney" },
  { value: "ALA 170 - Hicks", label: "ALA 170 - Hicks" },
  { value: "ALA 170 - McKay, Curzan", label: "ALA 170 - McKay, Curzan" },
  { value: "ALA 211 - Peters", label: "ALA 211 - Peters" },
  { value: "ALA 212 - Wiseman", label: "ALA 212 - Wiseman" },
  { value: "ALA 223 - Fretz", label: "ALA 223 - Fretz" },
  { value: "ALA 240 - Jansen", label: "ALA 240 - Jansen" },
  { value: "ALA 240 - Christiaens", label: "ALA 240 - Christiaens" },
  { value: "ALA 240 - Pehlke | she/her", label: "ALA 240 - Pehlke | she/her" },
  { value: "ALA 256 - Ludwig", label: "ALA 256 - Ludwig" },
  { value: "ALA 260 - Brissey , Andre", label: "ALA 260 - Brissey , Andre" },
  { value: "ALA 264 - Finn", label: "ALA 264 - Finn" },
  { value: "ALA 270 - Oehrli", label: "ALA 270 - Oehrli" },
  { value: "ALA 270 - Hicks", label: "ALA 270 - Hicks" },
  { value: "ALA 270 - Marks", label: "ALA 270 - Marks" },
  { value: "ALA 270 - Ludwig", label: "ALA 270 - Ludwig" },
  { value: "ALA 270 - Modey", label: "ALA 270 - Modey" },
  { value: "ALA 270", label: "ALA 270" },
  { value: "ALA 290 - Moshin", label: "ALA 290 - Moshin" },
  { value: "ALA 301 - Gibelyou", label: "ALA 301 - Gibelyou" },
  { value: "ALA 315 - Gibelyou", label: "ALA 315 - Gibelyou" },
  { value: "ALA 350 - Gibelyou", label: "ALA 350 - Gibelyou" },
  { value: "ALA 361 - Mahalingam", label: "ALA 361 - Mahalingam" },
  { value: "ALA 370 - Finn", label: "ALA 370 - Finn" },
  { value: "ALA 470 - Joque", label: "ALA 470 - Joque" },
  { value: "ALA 325 - Silbert", label: "ALA 325 - Silbert" },
  { value: "ALA 108 - Turman", label: "ALA 108 - Turman" },
  { value: "ALA 122 - Hicks", label: "ALA 122 - Hicks" },
  { value: "ALA 125 - Silbert", label: "ALA 125 - Silbert" },
  { value: "ALA 171 - Gurin", label: "ALA 171 - Gurin" },
  { value: "ALA 175 - Haque", label: "ALA 175 - Haque" },
  { value: "ALA 220", label: "ALA 220" },
  { value: "ALA 225 - Barron", label: "ALA 225 - Barron" },
  { value: "ALA 228", label: "ALA 228" },
  { value: "ALA 320 - Hwang", label: "ALA 320 - Hwang" },
  { value: "ALA 320 - Schoem", label: "ALA 320 - Schoem" },
  { value: "ALA 321 - Hicks", label: "ALA 321 - Hicks" },
  { value: "ALA 321", label: "ALA 321" },
  { value: "ALA 421 - Gurin", label: "ALA 421 - Gurin" },
  { value: "ALA 429 - Kaplowitz", label: "ALA 429 - Kaplowitz" },
  { value: "ALA 472 - Gurin", label: "ALA 472 - Gurin" },
  { value: "ALA 322", label: "ALA 322" },
  { value: "AMCULT 201 - Larson", label: "AMCULT 201 - Larson" },
  { value: "AMCULT 202 - Bushner", label: "AMCULT 202 - Bushner" },
  { value: "AMCULT 204 - Lee", label: "AMCULT 204 - Lee" },
  { value: "AMCULT 204 - Roby", label: "AMCULT 204 - Roby" },
  { value: "AMCULT 204 - Akiboh", label: "AMCULT 204 - Akiboh" },
  { value: "AMCULT 204 - Witgen", label: "AMCULT 204 - Witgen" },
  { value: "AMCULT 204 - Fitzgerald", label: "AMCULT 204 - Fitzgerald" },
  { value: "AMCULT 212 - Calvo-Quiros", label: "AMCULT 212 - Calvo-Quiros" },
  { value: "AMCULT 214 - Stillman", label: "AMCULT 214 - Stillman" },
  { value: "AMCULT 235 - Cable", label: "AMCULT 235 - Cable" },
  { value: "AMCULT 240 - Dumes", label: "AMCULT 240 - Dumes" },
  { value: "AMCULT 243 - Calvo-Quiros", label: "AMCULT 243 - Calvo-Quiros" },
  { value: "AMCULT 263 - Berrey", label: "AMCULT 263 - Berrey" },
  { value: "AMCULT 301 - Phruksachart", label: "AMCULT 301 - Phruksachart" },
  { value: "AMCULT 311 - Walker", label: "AMCULT 311 - Walker" },
  { value: "AMCULT 311 - Anderson", label: "AMCULT 311 - Anderson" },
  {
    value: "AMCULT 311 - Karem Albrecht",
    label: "AMCULT 311 - Karem Albrecht",
  },
  { value: "AMCULT 311 - Lee", label: "AMCULT 311 - Lee" },
  { value: "AMCULT 311 - Beltran", label: "AMCULT 311 - Beltran" },
  { value: "AMCULT 311 - Fajardo", label: "AMCULT 311 - Fajardo" },
  { value: "AMCULT 311 - Mattawa", label: "AMCULT 311 - Mattawa" },
  { value: "AMCULT 325 - Najita", label: "AMCULT 325 - Najita" },
  { value: "AMCULT 330 - Stiffler", label: "AMCULT 330 - Stiffler" },
  { value: "AMCULT 353 - Lawsin", label: "AMCULT 353 - Lawsin" },
  { value: "AMCULT 358 - Ferrari", label: "AMCULT 358 - Ferrari" },
  { value: "AMCULT 358 - Granata", label: "AMCULT 358 - Granata" },
  { value: "AMCULT 358 - Bushner", label: "AMCULT 358 - Bushner" },
  { value: "AMCULT 358 - Griffiths", label: "AMCULT 358 - Griffiths" },
  { value: "AMCULT 363 - Lawsin", label: "AMCULT 363 - Lawsin" },
  { value: "AMCULT 375 - Jones", label: "AMCULT 375 - Jones" },
  { value: "AMCULT 223 - Pitawanakwat", label: "AMCULT 223 - Pitawanakwat" },
  {
    value: "AMCULT 224 - Galvan-Santibanez",
    label: "AMCULT 224 - Galvan-Santibanez",
  },
  {
    value: "AMCULT 311 - Gordon-Gurfinkel",
    label: "AMCULT 311 - Gordon-Gurfinkel",
  },
  { value: "AMCULT 311", label: "AMCULT 311" },
  { value: "AMCULT 323 - Pitawanakwat", label: "AMCULT 323 - Pitawanakwat" },
  { value: "AMCULT 103 - Khalil", label: "AMCULT 103 - Khalil" },
  { value: "AMCULT 103 - Najita", label: "AMCULT 103 - Najita" },
  { value: "AMCULT 103 - Yergeau", label: "AMCULT 103 - Yergeau" },
  { value: "AMCULT 103 - Aristarkhova", label: "AMCULT 103 - Aristarkhova" },
  { value: "AMCULT 234 - Brose", label: "AMCULT 234 - Brose" },
  {
    value: "AMCULT 248 - Karem Albrecht",
    label: "AMCULT 248 - Karem Albrecht",
  },
  { value: "AMCULT 293 - Mendoza", label: "AMCULT 293 - Mendoza" },
  { value: "AMCULT 300 - Croft", label: "AMCULT 300 - Croft" },
  { value: "AMCULT 398 - Cole", label: "AMCULT 398 - Cole" },
  { value: "AMCULT 405 - Phruksachart", label: "AMCULT 405 - Phruksachart" },
  { value: "AMCULT 405 - Ocampo", label: "AMCULT 405 - Ocampo" },
  { value: "AMCULT 405 - Jackson", label: "AMCULT 405 - Jackson" },
  { value: "AMCULT 405 - Saluk", label: "AMCULT 405 - Saluk" },
  { value: "AMCULT 411 - Hubbs", label: "AMCULT 411 - Hubbs" },
  { value: "AMCULT 438 - Goldman", label: "AMCULT 438 - Goldman" },
  { value: "AMCULT 498 - Kawar", label: "AMCULT 498 - Kawar" },
  { value: "AMCULT 498 - Shin", label: "AMCULT 498 - Shin" },
  { value: "AMCULT 498 - Stillman", label: "AMCULT 498 - Stillman" },
  { value: "AMCULT 504 - Pedraza", label: "AMCULT 504 - Pedraza" },
  { value: "AMCULT 321", label: "AMCULT 321" },
  {
    value: "ANATOMY 303 - Sullivan, Alsup, Fox",
    label: "ANATOMY 303 - Sullivan, Alsup, Fox",
  },
  {
    value: "ANATOMY 403 - Sullivan, Barnosky, Kingston, Orczykowski",
    label: "ANATOMY 403 - Sullivan, Barnosky, Kingston, Orczykowski",
  },
  { value: "ANATOMY 541", label: "ANATOMY 541" },
  { value: "ANATOMY 510", label: "ANATOMY 510" },
  { value: "ANTHRARC 381 - Richards", label: "ANTHRARC 381 - Richards" },
  { value: "ANTHRARC 384 - Beckman", label: "ANTHRARC 384 - Beckman" },
  {
    value: "ANTHRARC 386 - Ventresca Miller",
    label: "ANTHRARC 386 - Ventresca Miller",
  },
  { value: "ANTHRARC 492 - Wright", label: "ANTHRARC 492 - Wright" },
  {
    value: "ANTHRARC 582 - Ventresca Miller",
    label: "ANTHRARC 582 - Ventresca Miller",
  },
  { value: "ANTHRBIO 201 - Rosenbaum", label: "ANTHRBIO 201 - Rosenbaum" },
  { value: "ANTHRBIO 364 - Devlin", label: "ANTHRBIO 364 - Devlin" },
  { value: "ANTHRBIO 371 - MacLatchy", label: "ANTHRBIO 371 - MacLatchy" },
  { value: "ANTHRBIO 469 - Devlin", label: "ANTHRBIO 469 - Devlin" },
  { value: "ANTHRBIO 469 - Feldblum", label: "ANTHRBIO 469 - Feldblum" },
  { value: "ANTHRBIO 474 - Kingston", label: "ANTHRBIO 474 - Kingston" },
  { value: "ANTHRBIO 478 - Rosenbaum", label: "ANTHRBIO 478 - Rosenbaum" },
  {
    value: "ANTHRBIO 570 - Marshall, Kingston",
    label: "ANTHRBIO 570 - Marshall, Kingston",
  },
  { value: "ANTHRBIO 571 - Beehner", label: "ANTHRBIO 571 - Beehner" },
  { value: "ANTHRCUL 101 - Stuckey", label: "ANTHRCUL 101 - Stuckey" },
  { value: "ANTHRCUL 101 - Chivens", label: "ANTHRCUL 101 - Chivens" },
  { value: "ANTHRCUL 226 - Frye", label: "ANTHRCUL 226 - Frye" },
  { value: "ANTHRCUL 252 - Dua", label: "ANTHRCUL 252 - Dua" },
  { value: "ANTHRCUL 272 - Lempert", label: "ANTHRCUL 272 - Lempert" },
  { value: "ANTHRCUL 298 - Lynch", label: "ANTHRCUL 298 - Lynch" },
  { value: "ANTHRCUL 320 - Frye", label: "ANTHRCUL 320 - Frye" },
  { value: "ANTHRCUL 331 - Akin", label: "ANTHRCUL 331 - Akin" },
  { value: "ANTHRCUL 332 - Keane", label: "ANTHRCUL 332 - Keane" },
  {
    value: "ANTHRCUL 344 - Peters-Golden",
    label: "ANTHRCUL 344 - Peters-Golden",
  },
  {
    value: "ANTHRCUL 370 - Namboodiripad",
    label: "ANTHRCUL 370 - Namboodiripad",
  },
  { value: "ANTHRCUL 553 - Behar", label: "ANTHRCUL 553 - Behar" },
  { value: "ANTHRCUL 158 - Lemon", label: "ANTHRCUL 158 - Lemon" },
  {
    value: "ANTHRCUL 258 - Peters-Golden",
    label: "ANTHRCUL 258 - Peters-Golden",
  },
  { value: "ANTHRCUL 357 - Paredes", label: "ANTHRCUL 357 - Paredes" },
  { value: "ANTHRCUL 359 - Stuckey", label: "ANTHRCUL 359 - Stuckey" },
  { value: "ANTHRCUL 458 - Behar", label: "ANTHRCUL 458 - Behar" },
  { value: "ANTHRCUL 458 - Hsieh", label: "ANTHRCUL 458 - Hsieh" },
  { value: "ANTHRCUL 471 - Lempert", label: "ANTHRCUL 471 - Lempert" },
  { value: "ANTHRCUL 527 - Keane", label: "ANTHRCUL 527 - Keane" },
  { value: "ANTHRCUL 554", label: "ANTHRCUL 554" },
  { value: "ANTHRCUL 577 - Mannheim", label: "ANTHRCUL 577 - Mannheim" },
  { value: "APPPHYS 524 - Forrest", label: "APPPHYS 524 - Forrest" },
  { value: "APPPHYS 541 - Kira", label: "APPPHYS 541 - Kira" },
  { value: "APPPHYS 551 - Galvanauskas", label: "APPPHYS 551 - Galvanauskas" },
  { value: "APPPHYS 514 - Kurdak", label: "APPPHYS 514 - Kurdak" },
  { value: "APPPHYS 514 - Orr", label: "APPPHYS 514 - Orr" },
  { value: "ARABAM 235 - Cable", label: "ARABAM 235 - Cable" },
  {
    value: "ARABAM 311 - Karem Albrecht",
    label: "ARABAM 311 - Karem Albrecht",
  },
  { value: "ARABAM 311 - Mattawa", label: "ARABAM 311 - Mattawa" },
  { value: "ARABAM 330 - Stiffler", label: "ARABAM 330 - Stiffler" },
  {
    value: "ARABAM 248 - Karem Albrecht",
    label: "ARABAM 248 - Karem Albrecht",
  },
  { value: "ARABIC 508 - Alhawary", label: "ARABIC 508 - Alhawary" },
  { value: "ARABIC 513 - Alhawary", label: "ARABIC 513 - Alhawary" },
  { value: "ARABIC 102", label: "ARABIC 102" },
  { value: "ARABIC 102 - Beebani", label: "ARABIC 102 - Beebani" },
  { value: "ARABIC 202", label: "ARABIC 202" },
  { value: "ARABIC 202 - Mohamed", label: "ARABIC 202 - Mohamed" },
  { value: "ARABIC 202 - Alsayegh", label: "ARABIC 202 - Alsayegh" },
  {
    value: "ARABIC 202 - Hannouchi, Mohamed",
    label: "ARABIC 202 - Hannouchi, Mohamed",
  },
  { value: "ARABIC 202 - Reading", label: "ARABIC 202 - Reading" },
  { value: "ARABIC 402", label: "ARABIC 402" },
  { value: "ARABIC 402 - Hannouchi", label: "ARABIC 402 - Hannouchi" },
  { value: "ARABIC 504 - Alsayegh", label: "ARABIC 504 - Alsayegh" },
  { value: "ARCH 211 - Fure", label: "ARCH 211 - Fure" },
  { value: "ARCH 211 - Moran", label: "ARCH 211 - Moran" },
  { value: "ARCH 215 - Zimmerman", label: "ARCH 215 - Zimmerman" },
  { value: "ARCH 323 - Herscher", label: "ARCH 323 - Herscher" },
  { value: "ARCH 324 - von Buelow", label: "ARCH 324 - von Buelow" },
  { value: "ARCH 409 - Harris", label: "ARCH 409 - Harris" },
  { value: "ARCH 409 - Ahlquist", label: "ARCH 409 - Ahlquist" },
  { value: "ARCH 409 - Fishman", label: "ARCH 409 - Fishman" },
  { value: "ARCH 421 - Meier", label: "ARCH 421 - Meier" },
  { value: "ARCH 423 - Etienne", label: "ARCH 423 - Etienne" },
  { value: "ARCH 425 - Kim", label: "ARCH 425 - Kim" },
  { value: "ARCH 425 - Junghans", label: "ARCH 425 - Junghans" },
  { value: "ARCH 466 - Unverzagt", label: "ARCH 466 - Unverzagt" },
  { value: "ARCH 505 - Junghans", label: "ARCH 505 - Junghans" },
  { value: "ARCH 507 - Graebner", label: "ARCH 507 - Graebner" },
  { value: "ARCH 517", label: "ARCH 517" },
  { value: "ARCH 517 - McCullough", label: "ARCH 517 - McCullough" },
  { value: "ARCH 535 - Navvab", label: "ARCH 535 - Navvab" },
  { value: "ARCH 544 - von Buelow", label: "ARCH 544 - von Buelow" },
  { value: "ARCH 545 - Navvab", label: "ARCH 545 - Navvab" },
  {
    value: "ARCH 572 - Brisson, Roddier",
    label: "ARCH 572 - Brisson, Roddier",
  },
  {
    value: "ARCH 583 - Hwang, McMorrough",
    label: "ARCH 583 - Hwang, McMorrough",
  },
  { value: "ARCH 585 - Kim", label: "ARCH 585 - Kim" },
  { value: "ARCH 595 - Li", label: "ARCH 595 - Li" },
  { value: "ARCH 324", label: "ARCH 324" },
  { value: "ARCH 411 - Abrons", label: "ARCH 411 - Abrons" },
  { value: "ARCH 503 - McMorrough", label: "ARCH 503 - McMorrough" },
  { value: "ARCH 506 - Groat", label: "ARCH 506 - Groat" },
  { value: "ARCH 506 - Strickland", label: "ARCH 506 - Strickland" },
  { value: "ARCH 509 - Ahlquist", label: "ARCH 509 - Ahlquist" },
  { value: "ARCH 509 - Borum", label: "ARCH 509 - Borum" },
  { value: "ARCH 509 - Rule", label: "ARCH 509 - Rule" },
  { value: "ARCH 509 - Abrons", label: "ARCH 509 - Abrons" },
  { value: "ARCH 509 - Mitnick", label: "ARCH 509 - Mitnick" },
  { value: "ARCH 509 - Velikov", label: "ARCH 509 - Velikov" },
  { value: "ARCH 509 - Mcgee", label: "ARCH 509 - Mcgee" },
  { value: "ARCH 509 - Rozas-Krause", label: "ARCH 509 - Rozas-Krause" },
  { value: "ARCH 509 - Jazairy", label: "ARCH 509 - Jazairy" },
  { value: "ARCH 509 - Robinson", label: "ARCH 509 - Robinson" },
  { value: "ARCH 509 - Kulper", label: "ARCH 509 - Kulper" },
  { value: "ARCH 509 - Newell", label: "ARCH 509 - Newell" },
  { value: "ARCH 531 - McCullough", label: "ARCH 531 - McCullough" },
  {
    value: "ARCH 537 - Ng, Kennedy, Rule, Meier",
    label: "ARCH 537 - Ng, Kennedy, Rule, Meier",
  },
  { value: "ARCH 322", label: "ARCH 322" },
  { value: "ARCH 326", label: "ARCH 326" },
  { value: "ARCH 333", label: "ARCH 333" },
  { value: "ARCH 422", label: "ARCH 422" },
  { value: "ARCH 442", label: "ARCH 442" },
  { value: "ARCH 516", label: "ARCH 516" },
  { value: "ARCH 562", label: "ARCH 562" },
  { value: "ARCH 571", label: "ARCH 571" },
  { value: "ARMENIAN 102 - Pifer", label: "ARMENIAN 102 - Pifer" },
  { value: "ARMENIAN 202 - Pifer", label: "ARMENIAN 202 - Pifer" },
  { value: "ARTDES 151 - Funkenstein", label: "ARTDES 151 - Funkenstein" },
  { value: "ARTDES 160 - Burgard", label: "ARTDES 160 - Burgard" },
  { value: "ARTDES 129 - Shapiro", label: "ARTDES 129 - Shapiro" },
  { value: "ARTDES 190 - Steichmann", label: "ARTDES 190 - Steichmann" },
  { value: "ARTDES 191 - White", label: "ARTDES 191 - White" },
  { value: "ARTDES 192 - Burns", label: "ARTDES 192 - Burns" },
  { value: "ARTDES 193 - Yang", label: "ARTDES 193 - Yang" },
  { value: "ARTDES 399 - Hirsch", label: "ARTDES 399 - Hirsch" },
  { value: "ARTDES 398 - Tharp", label: "ARTDES 398 - Tharp" },
  { value: "ARTDES 270", label: "ARTDES 270" },
  { value: "ARTDES 275", label: "ARTDES 275" },
  { value: "ARTDES 300", label: "ARTDES 300" },
  { value: "ARTDES 307", label: "ARTDES 307" },
  { value: "ARTDES 310", label: "ARTDES 310" },
  { value: "ARTDES 312", label: "ARTDES 312" },
  { value: "ARTDES 345", label: "ARTDES 345" },
  { value: "ARTDES 349", label: "ARTDES 349" },
  { value: "ARTDES 355", label: "ARTDES 355" },
  { value: "ARTDES 357", label: "ARTDES 357" },
  { value: "ARTDES 371", label: "ARTDES 371" },
  { value: "ARTDES 373", label: "ARTDES 373" },
  { value: "ARTDES 391", label: "ARTDES 391" },
  { value: "ARTDES 400", label: "ARTDES 400" },
  { value: "ARTDES 416", label: "ARTDES 416" },
  { value: "ARTDES 423", label: "ARTDES 423" },
  { value: "ARTDES 427", label: "ARTDES 427" },
  { value: "ARTDES 430", label: "ARTDES 430" },
  { value: "ARTDES 434", label: "ARTDES 434" },
  { value: "ARTDES 489", label: "ARTDES 489" },
  { value: "ARTDES 499", label: "ARTDES 499" },
  { value: "ARTDES 516", label: "ARTDES 516" },
  { value: "ARTSADMN 406", label: "ARTSADMN 406" },
  { value: "ARTSADMN 426 - Cuyler", label: "ARTSADMN 426 - Cuyler" },
  { value: "ARTSADMN 428 - Fischer", label: "ARTSADMN 428 - Fischer" },
  { value: "ARTSADMN 431", label: "ARTSADMN 431" },
  { value: "ARTSADMN 431 - Landry", label: "ARTSADMN 431 - Landry" },
  { value: "ARTSADMN 433 - LeBoeuf", label: "ARTSADMN 433 - LeBoeuf" },
  { value: "ARTSADMN 450 - Dworkin", label: "ARTSADMN 450 - Dworkin" },
  { value: "ARTSADMN 472 - Kuuskoski", label: "ARTSADMN 472 - Kuuskoski" },
  { value: "ARTSADMN 506 - Dworkin", label: "ARTSADMN 506 - Dworkin" },
  { value: "ARTSADMN 506", label: "ARTSADMN 506" },
  { value: "ARTSADMN 526 - Cuyler", label: "ARTSADMN 526 - Cuyler" },
  { value: "ARTSADMN 528 - Fischer", label: "ARTSADMN 528 - Fischer" },
  { value: "ARTSADMN 531", label: "ARTSADMN 531" },
  { value: "ARTSADMN 531 - Landry", label: "ARTSADMN 531 - Landry" },
  { value: "ARTSADMN 533 - LeBoeuf", label: "ARTSADMN 533 - LeBoeuf" },
  { value: "ARTSADMN 550 - Dworkin", label: "ARTSADMN 550 - Dworkin" },
  { value: "ARTSADMN 572 - Kuuskoski", label: "ARTSADMN 572 - Kuuskoski" },
  { value: "ARTSADMN 385 - Cuyler", label: "ARTSADMN 385 - Cuyler" },
  { value: "ASIAN 205 - Cassel", label: "ASIAN 205 - Cassel" },
  { value: "ASIAN 225 - Brick", label: "ASIAN 225 - Brick" },
  { value: "ASIAN 230 - Lopez Jr", label: "ASIAN 230 - Lopez Jr" },
  {
    value: "ASIAN 231 - Ujeed, Lopez Jr",
    label: "ASIAN 231 - Ujeed, Lopez Jr",
  },
  { value: "ASIAN 248 - de la Cruz", label: "ASIAN 248 - de la Cruz" },
  { value: "ASIAN 258 - Brown", label: "ASIAN 258 - Brown" },
  { value: "ASIAN 259 - Sinha", label: "ASIAN 259 - Sinha" },
  { value: "ASIAN 273 - Oh", label: "ASIAN 273 - Oh" },
  { value: "ASIAN 280 - Lopez Jr", label: "ASIAN 280 - Lopez Jr" },
  { value: "ASIAN 324 - Mir", label: "ASIAN 324 - Mir" },
  { value: "ASIAN 328 - White", label: "ASIAN 328 - White" },
  { value: "ASIAN 362 - Rolston", label: "ASIAN 362 - Rolston" },
  { value: "ASIAN 364 - Rolston", label: "ASIAN 364 - Rolston" },
  { value: "ASIAN 368 - Baxter", label: "ASIAN 368 - Baxter" },
  { value: "ASIAN 369 - Rebull", label: "ASIAN 369 - Rebull" },
  { value: "ASIAN 371 - Ahn", label: "ASIAN 371 - Ahn" },
  { value: "ASIAN 377 - Ryu", label: "ASIAN 377 - Ryu" },
  { value: "ASIAN 380 - Vowell", label: "ASIAN 380 - Vowell" },
  { value: "ASIAN 380 - Lahiri", label: "ASIAN 380 - Lahiri" },
  { value: "ASIAN 428 - Gallagher", label: "ASIAN 428 - Gallagher" },
  { value: "ASIAN 232 - Brose", label: "ASIAN 232 - Brose" },
  { value: "ASIAN 252 - Auerback", label: "ASIAN 252 - Auerback" },
  { value: "ASIAN 271 - Oh", label: "ASIAN 271 - Oh" },
  { value: "ASIAN 306 - Mandair", label: "ASIAN 306 - Mandair" },
  { value: "ASIAN 310 - Alexy", label: "ASIAN 310 - Alexy" },
  { value: "ASIAN 327 - White", label: "ASIAN 327 - White" },
  { value: "ASIAN 334 - Mandair", label: "ASIAN 334 - Mandair" },
  { value: "ASIAN 338 - Ujeed", label: "ASIAN 338 - Ujeed" },
  { value: "ASIAN 381 - Hill", label: "ASIAN 381 - Hill" },
  { value: "ASIAN 435 - Merrill", label: "ASIAN 435 - Merrill" },
  { value: "ASIAN 480 - Jackson", label: "ASIAN 480 - Jackson" },
  { value: "ASIAN 480 - Kile", label: "ASIAN 480 - Kile" },
  { value: "ASIAN 480 - Phung", label: "ASIAN 480 - Phung" },
  { value: "ASIAN 483 - Auerback", label: "ASIAN 483 - Auerback" },
  { value: "ASIAN 500 - Lopez Jr", label: "ASIAN 500 - Lopez Jr" },
  { value: "ASIAN 503 - Alexy", label: "ASIAN 503 - Alexy" },
  { value: "ASIAN 551 - Kile", label: "ASIAN 551 - Kile" },
  { value: "ASIAN 577 - Chanchani", label: "ASIAN 577 - Chanchani" },
  { value: "ASIAN 580 - Brick", label: "ASIAN 580 - Brick" },
  { value: "ASIANLAN 126 - Schad", label: "ASIANLAN 126 - Schad" },
  { value: "ASIANLAN 136 - Jung", label: "ASIANLAN 136 - Jung" },
  { value: "ASIANLAN 226 - Sakakibara", label: "ASIANLAN 226 - Sakakibara" },
  { value: "ASIANLAN 236 - Kim", label: "ASIANLAN 236 - Kim" },
  { value: "ASIANLAN 102 - Grande", label: "ASIANLAN 102 - Grande" },
  {
    value: "ASIANLAN 102 - Li-Stevenson",
    label: "ASIANLAN 102 - Li-Stevenson",
  },
  { value: "ASIANLAN 104 - Gu", label: "ASIANLAN 104 - Gu" },
  { value: "ASIANLAN 105 - Grande", label: "ASIANLAN 105 - Grande" },
  { value: "ASIANLAN 112 - Gonzaga", label: "ASIANLAN 112 - Gonzaga" },
  { value: "ASIANLAN 116 - Gill", label: "ASIANLAN 116 - Gill" },
  { value: "ASIANLAN 116 - Ali", label: "ASIANLAN 116 - Ali" },
  { value: "ASIANLAN 116 - Khan", label: "ASIANLAN 116 - Khan" },
  { value: "ASIANLAN 122", label: "ASIANLAN 122" },
  { value: "ASIANLAN 124 - Schad", label: "ASIANLAN 124 - Schad" },
  { value: "ASIANLAN 126 - Yasuda", label: "ASIANLAN 126 - Yasuda" },
  { value: "ASIANLAN 126 - Hoffmeyer", label: "ASIANLAN 126 - Hoffmeyer" },
  { value: "ASIANLAN 126 - Sogabe", label: "ASIANLAN 126 - Sogabe" },
  {
    value: "ASIANLAN 126 - Yasuda, Schad",
    label: "ASIANLAN 126 - Yasuda, Schad",
  },
  { value: "ASIANLAN 136 - Oh", label: "ASIANLAN 136 - Oh" },
  { value: "ASIANLAN 136 - Kim", label: "ASIANLAN 136 - Kim" },
  { value: "ASIANLAN 146 - Gill", label: "ASIANLAN 146 - Gill" },
  { value: "ASIANLAN 156 - Mohan", label: "ASIANLAN 156 - Mohan" },
  { value: "ASIANLAN 162 - Bunmee", label: "ASIANLAN 162 - Bunmee" },
  { value: "ASIANLAN 172 - Ali", label: "ASIANLAN 172 - Ali" },
  { value: "ASIANLAN 176 - Nguyen", label: "ASIANLAN 176 - Nguyen" },
  { value: "ASIANLAN 186 - Hoque", label: "ASIANLAN 186 - Hoque" },
  { value: "ASIANLAN 202 - Zhong", label: "ASIANLAN 202 - Zhong" },
  { value: "ASIANLAN 202 - Levin", label: "ASIANLAN 202 - Levin" },
  { value: "ASIANLAN 202 - Yu", label: "ASIANLAN 202 - Yu" },
  { value: "ASIANLAN 204 - Gu", label: "ASIANLAN 204 - Gu" },
  { value: "ASIANLAN 204 - Liu", label: "ASIANLAN 204 - Liu" },
  { value: "ASIANLAN 212 - Gonzaga", label: "ASIANLAN 212 - Gonzaga" },
  { value: "ASIANLAN 216 - Ali", label: "ASIANLAN 216 - Ali" },
  { value: "ASIANLAN 222", label: "ASIANLAN 222" },
  {
    value: "ASIANLAN 226 - Sakakibara, Kondo",
    label: "ASIANLAN 226 - Sakakibara, Kondo",
  },
  { value: "ASIANLAN 226 - Kondo", label: "ASIANLAN 226 - Kondo" },
  { value: "ASIANLAN 226 - Hoffmeyer", label: "ASIANLAN 226 - Hoffmeyer" },
  { value: "ASIANLAN 226", label: "ASIANLAN 226" },
  { value: "ASIANLAN 226 - Oka", label: "ASIANLAN 226 - Oka" },
  { value: "ASIANLAN 229 - Okuno", label: "ASIANLAN 229 - Okuno" },
  { value: "ASIANLAN 236 - Jung", label: "ASIANLAN 236 - Jung" },
  { value: "ASIANLAN 238 - Kim, Oh", label: "ASIANLAN 238 - Kim, Oh" },
  { value: "ASIANLAN 238 - Jung, Oh", label: "ASIANLAN 238 - Jung, Oh" },
  { value: "ASIANLAN 246 - Gill", label: "ASIANLAN 246 - Gill" },
  { value: "ASIANLAN 256 - Mohan", label: "ASIANLAN 256 - Mohan" },
  { value: "ASIANLAN 262 - Bunmee", label: "ASIANLAN 262 - Bunmee" },
  { value: "ASIANLAN 272 - Khan", label: "ASIANLAN 272 - Khan" },
  { value: "ASIANLAN 276 - Nguyen", label: "ASIANLAN 276 - Nguyen" },
  { value: "ASIANLAN 286 - Hoque", label: "ASIANLAN 286 - Hoque" },
  { value: "ASIANLAN 302 - Liu", label: "ASIANLAN 302 - Liu" },
  { value: "ASIANLAN 304 - Levin", label: "ASIANLAN 304 - Levin" },
  { value: "ASIANLAN 306 - Liu", label: "ASIANLAN 306 - Liu" },
  { value: "ASIANLAN 326 - Sogabe", label: "ASIANLAN 326 - Sogabe" },
  { value: "ASIANLAN 326", label: "ASIANLAN 326" },
  { value: "ASIANLAN 326 - Mochizuki", label: "ASIANLAN 326 - Mochizuki" },
  { value: "ASIANLAN 336 - Oh, Kim", label: "ASIANLAN 336 - Oh, Kim" },
  { value: "ASIANLAN 402 - Li", label: "ASIANLAN 402 - Li" },
  { value: "ASIANLAN 406 - Yu", label: "ASIANLAN 406 - Yu" },
  { value: "ASIANLAN 408 - Li", label: "ASIANLAN 408 - Li" },
  { value: "ASIANLAN 409 - Li", label: "ASIANLAN 409 - Li" },
  { value: "ASIANLAN 410 - Liu", label: "ASIANLAN 410 - Liu" },
  { value: "ASIANLAN 412", label: "ASIANLAN 412" },
  { value: "ASIANLAN 412 - Gonzaga,", label: "ASIANLAN 412 - Gonzaga," },
  { value: "ASIANLAN 418 - Ali", label: "ASIANLAN 418 - Ali" },
  { value: "ASIANLAN 430 - Kondo", label: "ASIANLAN 430 - Kondo" },
  { value: "ASIANLAN 441 - Mochizuki", label: "ASIANLAN 441 - Mochizuki" },
  { value: "ASIANLAN 456 - Mohan", label: "ASIANLAN 456 - Mohan" },
  { value: "ASIANLAN 462 - Bunmee", label: "ASIANLAN 462 - Bunmee" },
  { value: "ASIANLAN 472 - Khan", label: "ASIANLAN 472 - Khan" },
  { value: "ASIANLAN 476 - Nguyen", label: "ASIANLAN 476 - Nguyen" },
  { value: "ASIANLAN 480", label: "ASIANLAN 480" },
  { value: "ASIANLAN 486 - Hoque", label: "ASIANLAN 486 - Hoque" },
  { value: "ASIANLAN 451 - Brick", label: "ASIANLAN 451 - Brick" },
  { value: "ASIANLAN 479 - Kim, Oh", label: "ASIANLAN 479 - Kim, Oh" },
  { value: "ASIANPAM 204 - Lee", label: "ASIANPAM 204 - Lee" },
  { value: "ASIANPAM 204 - Akiboh", label: "ASIANPAM 204 - Akiboh" },
  { value: "ASIANPAM 214 - Stillman", label: "ASIANPAM 214 - Stillman" },
  {
    value: "ASIANPAM 301 - Hwang, Wang, Aramaki",
    label: "ASIANPAM 301 - Hwang, Wang, Aramaki",
  },
  {
    value: "ASIANPAM 301 - Phruksachart",
    label: "ASIANPAM 301 - Phruksachart",
  },
  { value: "ASIANPAM 311 - Lee", label: "ASIANPAM 311 - Lee" },
  { value: "ASIANPAM 311 - Fajardo", label: "ASIANPAM 311 - Fajardo" },
  { value: "ASIANPAM 325 - Najita", label: "ASIANPAM 325 - Najita" },
  { value: "ASIANPAM 353 - Lawsin", label: "ASIANPAM 353 - Lawsin" },
  { value: "ASIANPAM 363 - Lawsin", label: "ASIANPAM 363 - Lawsin" },
  { value: "ASIANPAM 103 - Najita", label: "ASIANPAM 103 - Najita" },
  { value: "ASIANPAM 498 - Shin", label: "ASIANPAM 498 - Shin" },
  { value: "ASTRO 101 - Bell, Lopresto", label: "ASTRO 101 - Bell, Lopresto" },
  {
    value: "ASTRO 102 - Corrales, Richstone",
    label: "ASTRO 102 - Corrales, Richstone",
  },
  {
    value: "ASTRO 102 - Richstone, Corrales",
    label: "ASTRO 102 - Richstone, Corrales",
  },
  { value: "ASTRO 105 - Oey", label: "ASTRO 105 - Oey" },
  { value: "ASTRO 106 - Miller", label: "ASTRO 106 - Miller" },
  { value: "ASTRO 107 - Sharon", label: "ASTRO 107 - Sharon" },
  { value: "ASTRO 107 - Ruszkowski", label: "ASTRO 107 - Ruszkowski" },
  { value: "ASTRO 115 - Monnier", label: "ASTRO 115 - Monnier" },
  { value: "ASTRO 127 - Lopresto", label: "ASTRO 127 - Lopresto" },
  { value: "ASTRO 142 - Miller", label: "ASTRO 142 - Miller" },
  { value: "ASTRO 183 - Landi", label: "ASTRO 183 - Landi" },
  { value: "ASTRO 201 - Gultekin", label: "ASTRO 201 - Gultekin" },
  { value: "ASTRO 206 - Valluri", label: "ASTRO 206 - Valluri" },
  { value: "ASTRO 261 - Vitug", label: "ASTRO 261 - Vitug" },
  { value: "ASTRO 401 - Rauscher", label: "ASTRO 401 - Rauscher" },
  { value: "ASTRO 404 - Bregman", label: "ASTRO 404 - Bregman" },
  { value: "ASTRO 534 - Gnedin", label: "ASTRO 534 - Gnedin" },
  { value: "ASTRO 535 - Hartmann", label: "ASTRO 535 - Hartmann" },
  { value: "ASTRO 220 - Reynolds", label: "ASTRO 220 - Reynolds" },
  { value: "ASTRO 429 - Meyer", label: "ASTRO 429 - Meyer" },
  { value: "AT 205 - Lepley", label: "AT 205 - Lepley" },
  { value: "AT 215 - Czajka", label: "AT 215 - Czajka" },
  { value: "AT 218 - Harris", label: "AT 218 - Harris" },
  { value: "AT 218 - Winkelseth", label: "AT 218 - Winkelseth" },
  { value: "AT 405 - Lepley", label: "AT 405 - Lepley" },
  { value: "AT 420 - Czajka", label: "AT 420 - Czajka" },
  { value: "AT 403", label: "AT 403" },
  { value: "AUTO 533 - Kwabi", label: "AUTO 533 - Kwabi" },
  { value: "AUTO 563 - Bai", label: "AUTO 563 - Bai" },
  { value: "AUTO 566 - Peng", label: "AUTO 566 - Peng" },
  { value: "AUTO 599 - Hyde", label: "AUTO 599 - Hyde" },
  { value: "BA 100 - Orhun", label: "BA 100 - Orhun" },
  { value: "BA 100 - Wooten", label: "BA 100 - Wooten" },
  { value: "BA 100 - Bishara", label: "BA 100 - Bishara" },
  { value: "BA 225 - Shark", label: "BA 225 - Shark" },
  { value: "BA 310 - Branch", label: "BA 310 - Branch" },
  { value: "BA 325 - Shark", label: "BA 325 - Shark" },
  { value: "BA 425 - Tasoluk", label: "BA 425 - Tasoluk" },
  { value: "BA 453 - Mueller", label: "BA 453 - Mueller" },
  { value: "BA 453", label: "BA 453" },
  { value: "BA 499 - Grzeskowiak", label: "BA 499 - Grzeskowiak" },
  { value: "BA 523 - Butz", label: "BA 523 - Butz" },
  { value: "BA 525 - Shark", label: "BA 525 - Shark" },
  { value: "BA 534", label: "BA 534" },
  { value: "BA 534 - Spreitzer , Erwin", label: "BA 534 - Spreitzer , Erwin" },
  { value: "BA 591", label: "BA 591" },
  { value: "BA 101 - Wooten", label: "BA 101 - Wooten" },
  { value: "BA 101 - Todd", label: "BA 101 - Todd" },
  {
    value: "BA 480 - Tasoluk, Lafontaine",
    label: "BA 480 - Tasoluk, Lafontaine",
  },
  { value: "BA 455", label: "BA 455" },
  { value: "BA 457", label: "BA 457" },
  { value: "BCOM 250 - Crawford", label: "BCOM 250 - Crawford" },
  { value: "BCOM 250 - Schantz", label: "BCOM 250 - Schantz" },
  { value: "BCOM 250 - Kotzian", label: "BCOM 250 - Kotzian" },
  { value: "BCOM 250 - Morrow", label: "BCOM 250 - Morrow" },
  { value: "BCOM 250 - Hinesly", label: "BCOM 250 - Hinesly" },
  { value: "BCOM 250 - Galifianakis", label: "BCOM 250 - Galifianakis" },
  { value: "BCOM 250 - Wisner", label: "BCOM 250 - Wisner" },
  { value: "BCOM 250 - Broucek", label: "BCOM 250 - Broucek" },
  { value: "BCOM 250 - James", label: "BCOM 250 - James" },
  { value: "BCOM 329 - Hinesly", label: "BCOM 329 - Hinesly" },
  { value: "BCOM 369 - Hinesly", label: "BCOM 369 - Hinesly" },
  { value: "BCOM 369 - Kotzian", label: "BCOM 369 - Kotzian" },
  { value: "BCOM 522 - Crawford", label: "BCOM 522 - Crawford" },
  { value: "BCOM 524 - Zimmerman", label: "BCOM 524 - Zimmerman" },
  { value: "BCOM 561 - Kotzian", label: "BCOM 561 - Kotzian" },
  { value: "BCOM 594 - Young, Pawlik", label: "BCOM 594 - Young, Pawlik" },
  { value: "BCOM 595 - Young, Pawlik", label: "BCOM 595 - Young, Pawlik" },
  { value: "BCS 132 - Rosic", label: "BCS 132 - Rosic" },
  { value: "BCS 232 - Rosic", label: "BCS 232 - Rosic" },
  { value: "BCS 532 - Rosic", label: "BCS 532 - Rosic" },
  { value: "BCS 534 - Rosic", label: "BCS 534 - Rosic" },
  { value: "BE 300 - Rhodes", label: "BE 300 - Rhodes" },
  { value: "BE 300 - Peralta", label: "BE 300 - Peralta" },
  { value: "BE 410 - Thapar", label: "BE 410 - Thapar" },
  { value: "BE 435 - Leider", label: "BE 435 - Leider" },
  { value: "BE 533 - Shwayder", label: "BE 533 - Shwayder" },
  { value: "BE 562 - Shwayder", label: "BE 562 - Shwayder" },
  { value: "BE 580 - Shwayder", label: "BE 580 - Shwayder" },
  { value: "BE 591 - Sivadasan", label: "BE 591 - Sivadasan" },
  { value: "BIOINF 520", label: "BIOINF 520" },
  { value: "BIOINF 520 - Beard", label: "BIOINF 520 - Beard" },
  {
    value: "BIOINF 524 - Freddolino, Mitrea, Guest",
    label: "BIOINF 524 - Freddolino, Mitrea, Guest",
  },
  { value: "BIOINF 529 - Mills, Boyle", label: "BIOINF 529 - Mills, Boyle" },
  { value: "BIOINF 545 - Parker, Tsoi", label: "BIOINF 545 - Parker, Tsoi" },
  { value: "BIOINF 547 - Rajapakse", label: "BIOINF 547 - Rajapakse" },
  { value: "BIOINF 580", label: "BIOINF 580" },
  {
    value: "BIOLCHEM 415 - Ninfa, Saper, Lamanna",
    label: "BIOLCHEM 415 - Ninfa, Saper, Lamanna",
  },
  { value: "BIOLCHEM 451 - Koutmos", label: "BIOLCHEM 451 - Koutmos" },
  { value: "BIOLCHEM 452 - Trievel", label: "BIOLCHEM 452 - Trievel" },
  { value: "BIOLCHEM 503 - Nichols", label: "BIOLCHEM 503 - Nichols" },
  {
    value: "BIOLCHEM 515 - Ninfa, Saper, Lamanna",
    label: "BIOLCHEM 515 - Ninfa, Saper, Lamanna",
  },
  { value: "BIOLCHEM 528 - Marsh", label: "BIOLCHEM 528 - Marsh" },
  {
    value: "BIOLCHEM 597 - Kwok, Saper, Cheng, Fleischhacker",
    label: "BIOLCHEM 597 - Kwok, Saper, Cheng, Fleischhacker",
  },
  {
    value: "BIOLCHEM 502 - Wang, Cierpicki, Nagorny, Grembecka",
    label: "BIOLCHEM 502 - Wang, Cierpicki, Nagorny, Grembecka",
  },
  { value: "BIOLCHEM 416", label: "BIOLCHEM 416" },
  { value: "BIOLCHEM 516", label: "BIOLCHEM 516" },
  { value: "BIOLOGY 102 - Qiu", label: "BIOLOGY 102 - Qiu" },
  { value: "BIOLOGY 109 - Lehman", label: "BIOLOGY 109 - Lehman" },
  { value: "BIOLOGY 112 - Carpenter", label: "BIOLOGY 112 - Carpenter" },
  { value: "BIOLOGY 116 - Knowles", label: "BIOLOGY 116 - Knowles" },
  { value: "BIOLOGY 121 - Klionsky", label: "BIOLOGY 121 - Klionsky" },
  { value: "BIOLOGY 121 - Ameziane", label: "BIOLOGY 121 - Ameziane" },
  { value: "BIOLOGY 130 - Tibbetts", label: "BIOLOGY 130 - Tibbetts" },
  { value: "BIOLOGY 171 - Kurdziel", label: "BIOLOGY 171 - Kurdziel" },
  { value: "BIOLOGY 171 - Giffen", label: "BIOLOGY 171 - Giffen" },
  { value: "BIO 172", label: "BIO 172" },
  { value: "BIOLOGY 173 - Ammerlaan", label: "BIOLOGY 173 - Ammerlaan" },
  { value: "BIOLOGY 197 - Carpenter", label: "BIOLOGY 197 - Carpenter" },
  {
    value: "BIOLOGY 205 - Cadigan, Buttitta",
    label: "BIOLOGY 205 - Cadigan, Buttitta",
  },
  {
    value: "BIOLOGY 207 - Huffnagle, Vecchiarelli",
    label: "BIOLOGY 207 - Huffnagle, Vecchiarelli",
  },
  { value: "BIOLOGY 222 - Hume, Kwon", label: "BIOLOGY 222 - Hume, Kwon" },
  {
    value: "BIOLOGY 225 - Xu, Casey, Haenfler",
    label: "BIOLOGY 225 - Xu, Casey, Haenfler",
  },
  {
    value: "BIOLOGY 230 - Umana, MacAlister",
    label: "BIOLOGY 230 - Umana, MacAlister",
  },
  { value: "BIOLOGY 256 - Eidietis", label: "BIOLOGY 256 - Eidietis" },
  { value: "BIOLOGY 281 - Sanders", label: "BIOLOGY 281 - Sanders" },
  { value: "BIOLOGY 288 - Duda Jr", label: "BIOLOGY 288 - Duda Jr" },
  {
    value: "BIOLOGY 305 - Wierzbicki, Green",
    label: "BIOLOGY 305 - Wierzbicki, Green",
  },
  { value: "BIOLOGY 526 - Bailey", label: "BIOLOGY 526 - Bailey" },
  { value: "BIOLOGY 172", label: "BIOLOGY 172" },
  { value: "BIOLOGY 305", label: "BIOLOGY 305" },
  { value: "BIOLOGY 117 - Carpenter", label: "BIOLOGY 117 - Carpenter" },
  { value: "BIOLOGY 120 - Kondrashov", label: "BIOLOGY 120 - Kondrashov" },
  { value: "BIOMEDE 211 - Belmont", label: "BIOMEDE 211 - Belmont" },
  { value: "BIOMEDE 221 - Wrobel", label: "BIOMEDE 221 - Wrobel" },
  { value: "BIOMEDE 231 - Louttit", label: "BIOMEDE 231 - Louttit" },
  {
    value: "BIOMEDE 241 - Chesney, Arnold",
    label: "BIOMEDE 241 - Chesney, Arnold",
  },
  { value: "BIOMEDE 321 - Giessen", label: "BIOMEDE 321 - Giessen" },
  { value: "BIOMEDE 332 - Deng", label: "BIOMEDE 332 - Deng" },
  {
    value: "BIOMEDE 350 - Wang, Louttit",
    label: "BIOMEDE 350 - Wang, Louttit",
  },
  { value: "BIOMEDE 418 - Nagrath", label: "BIOMEDE 418 - Nagrath" },
  { value: "BIOMEDE 442 - Xu", label: "BIOMEDE 442 - Xu" },
  {
    value: "BIOMEDE 450 - Schmedlen, Casper, Gosbee, Jensen",
    label: "BIOMEDE 450 - Schmedlen, Casper, Gosbee, Jensen",
  },
  {
    value: "BIOMEDE 450 - Wrobel, Jensen, Casper",
    label: "BIOMEDE 450 - Wrobel, Jensen, Casper",
  },
  {
    value: "BIOMEDE 452 - Schmedlen, Sheffield, Gosbee",
    label: "BIOMEDE 452 - Schmedlen, Sheffield, Gosbee",
  },
  { value: "BIOMEDE 476 - Grotberg", label: "BIOMEDE 476 - Grotberg" },
  { value: "BIOMEDE 479 - Nordsletten", label: "BIOMEDE 479 - Nordsletten" },
  {
    value: "BIOMEDE 499 - Chandrasekaran",
    label: "BIOMEDE 499 - Chandrasekaran",
  },
  {
    value: "BIOMEDE 500 - Baker, Arnold",
    label: "BIOMEDE 500 - Baker, Arnold",
  },
  { value: "BIOMEDE 503 - Bruns", label: "BIOMEDE 503 - Bruns" },
  { value: "BIOMEDE 534 - Martin", label: "BIOMEDE 534 - Martin" },
  { value: "BIOMEDE 561 - Fan", label: "BIOMEDE 561 - Fan" },
  { value: "BIOMEDE 599 - Stegemann", label: "BIOMEDE 599 - Stegemann" },
  { value: "BIOMEDE 599 - Weiland", label: "BIOMEDE 599 - Weiland" },
  { value: "BIOMEDE 599 - Aguilar", label: "BIOMEDE 599 - Aguilar" },
  { value: "BIOMEDE 599 - Putnam", label: "BIOMEDE 599 - Putnam" },
  { value: "BIOMEDE 458", label: "BIOMEDE 458" },
  { value: "BIOMEDE 517", label: "BIOMEDE 517" },
  { value: "BIOPHYS 290 - Zochowski", label: "BIOPHYS 290 - Zochowski" },
  { value: "BIOPHYS 401 - Frank", label: "BIOPHYS 401 - Frank" },
  { value: "BIOPHYS 417 - Yang", label: "BIOPHYS 417 - Yang" },
  { value: "BIOPHYS 420 - Guerra", label: "BIOPHYS 420 - Guerra" },
  { value: "BIOPHYS 430 - Ivanova", label: "BIOPHYS 430 - Ivanova" },
  { value: "BIOPHYS 116 - Swanson", label: "BIOPHYS 116 - Swanson" },
  {
    value: "BIOPHYS 502 - Wang, Cierpicki, Nagorny, Grembecka",
    label: "BIOPHYS 502 - Wang, Cierpicki, Nagorny, Grembecka",
  },
  { value: "BIOPHYS 521 - Horowitz", label: "BIOPHYS 521 - Horowitz" },
  { value: "BIOPHYS 450", label: "BIOPHYS 450" },
  { value: "BIOPHYS 550", label: "BIOPHYS 550" },
  { value: "BIOSTAT 449", label: "BIOSTAT 449" },
  {
    value: "BIOSTAT 512 - Welch, Plegue",
    label: "BIOSTAT 512 - Welch, Plegue",
  },
  { value: "BIOSTAT 590 - Murray", label: "BIOSTAT 590 - Murray" },
  { value: "BIOSTAT 599 - Tsodikov", label: "BIOSTAT 599 - Tsodikov" },
  { value: "BL 306 - Rogala", label: "BL 306 - Rogala" },
  { value: "BL 404 - Schipani", label: "BL 404 - Schipani" },
  { value: "BL 407 - Kress", label: "BL 407 - Kress" },
  { value: "BL 411", label: "BL 411" },
  { value: "BL 411 - Schipani", label: "BL 411 - Schipani" },
  { value: "BL 413 - Rogala", label: "BL 413 - Rogala" },
  { value: "BL 482 - Schloff", label: "BL 482 - Schloff" },
  { value: "BL 504 - Schipani", label: "BL 504 - Schipani" },
  { value: "BL 513 - Rogala", label: "BL 513 - Rogala" },
  { value: "BL 516 - Schipani", label: "BL 516 - Schipani" },
  { value: "BL 517 - Schipani", label: "BL 517 - Schipani" },
  { value: "BL 517", label: "BL 517" },
  { value: "BL 555 - Hess", label: "BL 555 - Hess" },
  { value: "BL 557 - Rogala", label: "BL 557 - Rogala" },
  { value: "BL 557 - Hargrove", label: "BL 557 - Hargrove" },
  { value: "BL 582 - Schloff", label: "BL 582 - Schloff" },
  { value: "BL 488 - Bishara", label: "BL 488 - Bishara" },
  { value: "BUSABRD 411", label: "BUSABRD 411" },
  { value: "BUSABRD 411 - Schipani", label: "BUSABRD 411 - Schipani" },
  { value: "BUSABRD 425 - Tasoluk", label: "BUSABRD 425 - Tasoluk" },
  { value: "BUSABRD 520 - Spreitzer", label: "BUSABRD 520 - Spreitzer" },
  { value: "CANCBIO 554 - Canman", label: "CANCBIO 554 - Canman" },
  { value: "CATALAN 405 - Coll Ramirez", label: "CATALAN 405 - Coll Ramirez" },
  { value: "CCS 580 - Gallagher", label: "CCS 580 - Gallagher" },
  { value: "CDB 550 - Hortsch", label: "CDB 550 - Hortsch" },
  { value: "CDB 581 - Allen", label: "CDB 581 - Allen" },
  { value: "CDB 516", label: "CDB 516" },
  { value: "CEE 211 - Bernardini", label: "CEE 211 - Bernardini" },
  { value: "CEE 212 - Filipov", label: "CEE 212 - Filipov" },
  { value: "CEE 265 - MacVean", label: "CEE 265 - MacVean" },
  { value: "CEE 307 - Semrau", label: "CEE 307 - Semrau" },
  { value: "CEE 345 - Hryciw, Sulewski", label: "CEE 345 - Hryciw, Sulewski" },
  { value: "CEE 375", label: "CEE 375" },
  { value: "CEE 402 - Daigger", label: "CEE 402 - Daigger" },
  { value: "CEE 465 - Love", label: "CEE 465 - Love" },
  { value: "CEE 481 - Hayes", label: "CEE 481 - Hayes" },
  { value: "CEE 501 - Adriaens", label: "CEE 501 - Adriaens" },
  { value: "CEE 501 - Orosz", label: "CEE 501 - Orosz" },
  { value: "CEE 513 - McCormick", label: "CEE 513 - McCormick" },
  { value: "CEE 515 - Wight", label: "CEE 515 - Wight" },
  { value: "CEE 520 - Ivanov", label: "CEE 520 - Ivanov" },
  { value: "CEE 522 - MacVean", label: "CEE 522 - MacVean" },
  { value: "CEE 526 - Bricker", label: "CEE 526 - Bricker" },
  { value: "CEE 530 - Menassa", label: "CEE 530 - Menassa" },
  { value: "CEE 543 - Garcia", label: "CEE 543 - Garcia" },
  { value: "CEE 554 - Masoud", label: "CEE 554 - Masoud" },
  { value: "CEE 559 - Yin", label: "CEE 559 - Yin" },
  { value: "CEE 563 - Clack", label: "CEE 563 - Clack" },
  { value: "CEE 564 - Lastoskie", label: "CEE 564 - Lastoskie" },
  { value: "CEE 571 - Ozay", label: "CEE 571 - Ozay" },
  { value: "CEE 580 - Olson", label: "CEE 580 - Olson" },
  {
    value: "CEE 586 - Keoleian, Fetner, Calzavara",
    label: "CEE 586 - Keoleian, Fetner, Calzavara",
  },
  { value: "CEE 592", label: "CEE 592" },
  { value: "CEE 531 - Kamat", label: "CEE 531 - Kamat" },
  { value: "CEE 532 - Lee", label: "CEE 532 - Lee" },
  { value: "CEE 534 - Kamat", label: "CEE 534 - Kamat" },
  { value: "CEE 546 - Michalowski", label: "CEE 546 - Michalowski" },
  { value: "CEE 200 - Ioannou", label: "CEE 200 - Ioannou" },
  { value: "CEE 501 - Masoud", label: "CEE 501 - Masoud" },
  { value: "CEE 303", label: "CEE 303" },
  { value: "CEE 325", label: "CEE 325" },
  { value: "CEE 331", label: "CEE 331" },
  { value: "CEE 351", label: "CEE 351" },
  { value: "CEE 366", label: "CEE 366" },
  { value: "CEE 415", label: "CEE 415" },
  { value: "CEE 450", label: "CEE 450" },
  { value: "CEE 581", label: "CEE 581" },
  { value: "CELLO 450", label: "CELLO 450" },
  { value: "CELLO 550", label: "CELLO 550" },
  { value: "CHAMBER 410", label: "CHAMBER 410" },
  { value: "CHAMBER 470", label: "CHAMBER 470" },
  { value: "CHAMBER 510", label: "CHAMBER 510" },
  { value: "CHAMBER 570", label: "CHAMBER 570" },
  { value: "CHE 330 - Gong", label: "CHE 330 - Gong" },
  { value: "CHE 341 - Burns", label: "CHE 341 - Burns" },
  { value: "CHE 360 - Hirshfield", label: "CHE 360 - Hirshfield" },
  { value: "CHE 431 - Tadd", label: "CHE 431 - Tadd" },
  { value: "CHE 485 - Lin", label: "CHE 485 - Lin" },
  { value: "CHE 487", label: "CHE 487" },
  { value: "CHE 487 - Tadd", label: "CHE 487 - Tadd" },
  { value: "CHE 489 - Hirshfield", label: "CHE 489 - Hirshfield" },
  { value: "CHE 496 - Nagrath", label: "CHE 496 - Nagrath" },
  { value: "CHE 512 - Kim", label: "CHE 512 - Kim" },
  { value: "CHE 519 - Wang", label: "CHE 519 - Wang" },
  { value: "CHE 528 - Antoniewicz", label: "CHE 528 - Antoniewicz" },
  { value: "CHE 542 - Lenert", label: "CHE 542 - Lenert" },
  { value: "CHE 559 - Laine", label: "CHE 559 - Laine" },
  { value: "CHEM 105 - Flanner", label: "CHEM 105 - Flanner" },
  { value: "CHEM 108 - Spica", label: "CHEM 108 - Spica" },
  { value: "CHEM 125", label: "CHEM 125" },
  { value: "CHEM 130", label: "CHEM 130" },
  { value: "CHEM 210 - Tuttle", label: "CHEM 210 - Tuttle" },
  { value: "CHEM 210 - Wang", label: "CHEM 210 - Wang" },
  { value: "CHEM 211 - Nolta", label: "CHEM 211 - Nolta" },
  { value: "CHEM 215 - Albright", label: "CHEM 215 - Albright" },
  { value: "CHEM 215 - Wolfe", label: "CHEM 215 - Wolfe" },
  { value: "CHEM 216 - Kiernicki", label: "CHEM 216 - Kiernicki" },
  { value: "CHEM 216 - Shultz", label: "CHEM 216 - Shultz" },
  { value: "CHEM 230 - Penner-Hahn", label: "CHEM 230 - Penner-Hahn" },
  { value: "CHEM 230 - Gottfried", label: "CHEM 230 - Gottfried" },
  { value: "CHEM 241 - Pratt", label: "CHEM 241 - Pratt" },
  { value: "CHEM 245 - Chen", label: "CHEM 245 - Chen" },
  { value: "CHEM 246 - Bailey", label: "CHEM 246 - Bailey" },
  { value: "CHEM 260 - Zgid", label: "CHEM 260 - Zgid" },
  { value: "CHEM 260 - Maldonado", label: "CHEM 260 - Maldonado" },
  { value: "CHEM 261 - Zgid", label: "CHEM 261 - Zgid" },
  { value: "CHEM 261 - Maldonado", label: "CHEM 261 - Maldonado" },
  { value: "CHEM 262 - Geva", label: "CHEM 262 - Geva" },
  { value: "CHEM 302 - Bartlett", label: "CHEM 302 - Bartlett" },
  { value: "CHEM 303 - Bridwell-Rabb", label: "CHEM 303 - Bridwell-Rabb" },
  { value: "CHEM 351 - Nolta", label: "CHEM 351 - Nolta" },
  { value: "CHEM 352 - Walter", label: "CHEM 352 - Walter" },
  { value: "CHEM 353 - Walter", label: "CHEM 353 - Walter" },
  { value: "CHEM 417 - Yang", label: "CHEM 417 - Yang" },
  { value: "CHEM 420 - Nagorny", label: "CHEM 420 - Nagorny" },
  { value: "CHEM 447 - Ruotolo", label: "CHEM 447 - Ruotolo" },
  { value: "CHEM 451 - Koutmos", label: "CHEM 451 - Koutmos" },
  { value: "CHEM 452 - Trievel", label: "CHEM 452 - Trievel" },
  { value: "CHEM 463 - Biteen", label: "CHEM 463 - Biteen" },
  { value: "CHEM 483 - Kopelman", label: "CHEM 483 - Kopelman" },
  { value: "CHEM 508 - Pecoraro", label: "CHEM 508 - Pecoraro" },
  { value: "CHEM 515 - Szymczak", label: "CHEM 515 - Szymczak" },
  { value: "CHEM 526 - Bailey", label: "CHEM 526 - Bailey" },
  { value: "CHEM 528 - Marsh", label: "CHEM 528 - Marsh" },
  { value: "CHEM 538 - Matzger", label: "CHEM 538 - Matzger" },
  { value: "CHEM 541 - Montgomery", label: "CHEM 541 - Montgomery" },
  { value: "CHEM 542 - Matzger", label: "CHEM 542 - Matzger" },
  { value: "CHEM 575 - Biteen", label: "CHEM 575 - Biteen" },
  { value: "CHEM 108", label: "CHEM 108" },
  { value: "CHEM 242 - Hakansson", label: "CHEM 242 - Hakansson" },
  { value: "CHEM 541", label: "CHEM 541" },
  { value: "CHEM 214 - Tuttle", label: "CHEM 214 - Tuttle" },
  {
    value: "CHEM 502 - Wang, Cierpicki, Nagorny, Grembecka",
    label: "CHEM 502 - Wang, Cierpicki, Nagorny, Grembecka",
  },
  { value: "CHEM 521 - Horowitz", label: "CHEM 521 - Horowitz" },
  { value: "CHEM 597", label: "CHEM 597" },
  { value: "CHEMBIO 512 - Iliff", label: "CHEMBIO 512 - Iliff" },
  {
    value: "CHEMBIO 502 - Wang, Nagorny, Cierpicki, Grembecka",
    label: "CHEMBIO 502 - Wang, Nagorny, Cierpicki, Grembecka",
  },
  { value: "CHEMBIO 599", label: "CHEMBIO 599" },
  { value: "CLARCH 221 - Nevett", label: "CLARCH 221 - Nevett" },
  { value: "CLARCH 327 - Eliav", label: "CLARCH 327 - Eliav" },
  { value: "CLARCH 382 - Motta", label: "CLARCH 382 - Motta" },
  { value: "CLARCH 389 - Stone", label: "CLARCH 389 - Stone" },
  { value: "CLARCH 550 - Stone", label: "CLARCH 550 - Stone" },
  { value: "CLARCH 375 - Margomenou", label: "CLARCH 375 - Margomenou" },
  { value: "CLARCH 470 - Stone", label: "CLARCH 470 - Stone" },
  { value: "CLARCH 480 - Motta", label: "CLARCH 480 - Motta" },
  { value: "CLARINET 111", label: "CLARINET 111" },
  { value: "CLCIV 125 - Ratte", label: "CLCIV 125 - Ratte" },
  { value: "CLCIV 125 - Foster", label: "CLCIV 125 - Foster" },
  { value: "CLCIV 202 - Caston", label: "CLCIV 202 - Caston" },
  { value: "CLCIV 217 - Fortson", label: "CLCIV 217 - Fortson" },
  { value: "CLCIV 250 - Janko", label: "CLCIV 250 - Janko" },
  { value: "CLCIV 288", label: "CLCIV 288" },
  { value: "CLCIV 327 - Eliav", label: "CLCIV 327 - Eliav" },
  { value: "CLCIV 350 - Leontis", label: "CLCIV 350 - Leontis" },
  { value: "CLCIV 350 - Soto Marin", label: "CLCIV 350 - Soto Marin" },
  { value: "CLCIV 350 - Dufallo", label: "CLCIV 350 - Dufallo" },
  { value: "CLCIV 350 - Forsdyke", label: "CLCIV 350 - Forsdyke" },
  { value: "CLCIV 350 - Schironi", label: "CLCIV 350 - Schironi" },
  { value: "CLCIV 375 - Potter", label: "CLCIV 375 - Potter" },
  { value: "CLCIV 382 - Motta", label: "CLCIV 382 - Motta" },
  { value: "CLCIV 385 - Ratte", label: "CLCIV 385 - Ratte" },
  { value: "CLCIV 389 - Ahbel-Rappe", label: "CLCIV 389 - Ahbel-Rappe" },
  { value: "CLCIV 121 - Wood", label: "CLCIV 121 - Wood" },
  { value: "CLCIV 367 - Caston", label: "CLCIV 367 - Caston" },
  { value: "CLCIV 120 - Soto Marin", label: "CLCIV 120 - Soto Marin" },
  { value: "CLCIV 120 - Soter", label: "CLCIV 120 - Soter" },
  { value: "CLCIV 253 - Das", label: "CLCIV 253 - Das" },
  { value: "CLCIV 470 - Das", label: "CLCIV 470 - Das" },
  { value: "CLIMATE 102 - Samson", label: "CLIMATE 102 - Samson" },
  { value: "CLIMATE 105 - Flanner", label: "CLIMATE 105 - Flanner" },
  { value: "CLIMATE 172 - Arnaboldi", label: "CLIMATE 172 - Arnaboldi" },
  { value: "CLIMATE 321 - Li", label: "CLIMATE 321 - Li" },
  { value: "CLIMATE 350 - Huang", label: "CLIMATE 350 - Huang" },
  { value: "CLIMATE 405 - Ruf", label: "CLIMATE 405 - Ruf" },
  { value: "CLIMATE 414 - Marsik", label: "CLIMATE 414 - Marsik" },
  { value: "CLIMATE 423 - Liemohn", label: "CLIMATE 423 - Liemohn" },
  { value: "CLIMATE 431 - Budhu", label: "CLIMATE 431 - Budhu" },
  { value: "CLIMATE 474 - Bassis", label: "CLIMATE 474 - Bassis" },
  { value: "CLIMATE 479 - Kort", label: "CLIMATE 479 - Kort" },
  { value: "CLIMATE 480 - Rood", label: "CLIMATE 480 - Rood" },
  { value: "CLIMATE 565 - Atreya", label: "CLIMATE 565 - Atreya" },
  { value: "CLIMATE 587 - Ulaby", label: "CLIMATE 587 - Ulaby" },
  { value: "CLIMATE 592 - Rood", label: "CLIMATE 592 - Rood" },
  { value: "CLIMATE 501 - Slavin", label: "CLIMATE 501 - Slavin" },
  { value: "CLIMATE 501 - Moldwin", label: "CLIMATE 501 - Moldwin" },
  { value: "CLIMATE 280", label: "CLIMATE 280" },
  { value: "CLIMATE 381", label: "CLIMATE 381" },
  { value: "CMPLXSYS 250 - Raimi", label: "CMPLXSYS 250 - Raimi" },
  { value: "CMPLXSYS 270 - Johnson", label: "CMPLXSYS 270 - Johnson" },
  { value: "CMPLXSYS 425 - Zaman", label: "CMPLXSYS 425 - Zaman" },
  { value: "CMPLXSYS 510 - King", label: "CMPLXSYS 510 - King" },
  { value: "CMPLXSYS 530 - Eisenberg", label: "CMPLXSYS 530 - Eisenberg" },
  { value: "COGSCI 200 - Sripada", label: "COGSCI 200 - Sripada" },
  { value: "COGSCI 209 - Baptista", label: "COGSCI 209 - Baptista" },
  { value: "COGSCI 301 - Bollard", label: "COGSCI 301 - Bollard" },
  { value: "COMM 101 - Douglas", label: "COMM 101 - Douglas" },
  { value: "COMM 102 - Campbell", label: "COMM 102 - Campbell" },
  { value: "COMM 221 - Pasek", label: "COMM 221 - Pasek" },
  { value: "COMM 251 - Draper", label: "COMM 251 - Draper" },
  { value: "COMM 281 - Dal Cin", label: "COMM 281 - Dal Cin" },
  { value: "COMM 290 - Moshin", label: "COMM 290 - Moshin" },
  { value: "COMM 306 - Moshin", label: "COMM 306 - Moshin" },
  { value: "COMM 307 - Hasell", label: "COMM 307 - Hasell" },
  { value: "COMM 307 - Lu", label: "COMM 307 - Lu" },
  { value: "COMM 307 - Glenn", label: "COMM 307 - Glenn" },
  { value: "COMM 317 - Angell", label: "COMM 317 - Angell" },
  { value: "COMM 362 - Sandvig", label: "COMM 362 - Sandvig" },
  { value: "COMM 370 - Drogos", label: "COMM 370 - Drogos" },
  { value: "COMM 380 - Hart", label: "COMM 380 - Hart" },
  { value: "COMM 404 - Douglas", label: "COMM 404 - Douglas" },
  { value: "COMM 404 - Pearson", label: "COMM 404 - Pearson" },
  { value: "COMM 408 - Sparr", label: "COMM 408 - Sparr" },
  { value: "COMM 417 - Hart", label: "COMM 417 - Hart" },
  { value: "COMM 421 - Sparr", label: "COMM 421 - Sparr" },
  { value: "COMM 159 - Collings", label: "COMM 159 - Collings" },
  { value: "COMM 432 - Collings", label: "COMM 432 - Collings" },
  { value: "COMM 439 - Avila Sanchez", label: "COMM 439 - Avila Sanchez" },
  {
    value: "COMM 452 - Van Den Bulck, Pearson",
    label: "COMM 452 - Van Den Bulck, Pearson",
  },
  { value: "COMM 461 - Ankerson", label: "COMM 461 - Ankerson" },
  { value: "COMM 468 - Weeks", label: "COMM 468 - Weeks" },
  { value: "COMM 490 - Draper", label: "COMM 490 - Draper" },
  { value: "COMM 490 - Griffin", label: "COMM 490 - Griffin" },
  { value: "COMM 490 - Williams", label: "COMM 490 - Williams" },
  { value: "COMM 490 - Moshin", label: "COMM 490 - Moshin" },
  {
    value: "COMM 492 - Van Den Bulck, Pearson",
    label: "COMM 492 - Van Den Bulck, Pearson",
  },
  { value: "COMP 222 - Etezady", label: "COMP 222 - Etezady" },
  { value: "COMP 422 - Sheng", label: "COMP 422 - Sheng" },
  { value: "COMP 550 - Etezady", label: "COMP 550 - Etezady" },
  { value: "COMP 233 - Chambers", label: "COMP 233 - Chambers" },
  { value: "COMP 433 - Chambers", label: "COMP 433 - Chambers" },
  { value: "COMP 416 - Santos", label: "COMP 416 - Santos" },
  { value: "COMP 450 - Kuster", label: "COMP 450 - Kuster" },
  { value: "COMP 526 - Santos", label: "COMP 526 - Santos" },
  { value: "COMPLIT 322 - Stroebel", label: "COMPLIT 322 - Stroebel" },
  { value: "COMPLIT 122 - Gaudyn", label: "COMPLIT 122 - Gaudyn" },
  { value: "COMPLIT 122 - Kubic", label: "COMPLIT 122 - Kubic" },
  { value: "COMPLIT 122 - Ogden", label: "COMPLIT 122 - Ogden" },
  {
    value: "COMPLIT 122 - Duarte Caetano",
    label: "COMPLIT 122 - Duarte Caetano",
  },
  { value: "COMPLIT 122 - Stanev", label: "COMPLIT 122 - Stanev" },
  { value: "COMPLIT 122 - Cooper", label: "COMPLIT 122 - Cooper" },
  { value: "COMPLIT 122 - Cvjeticanin", label: "COMPLIT 122 - Cvjeticanin" },
  { value: "COMPLIT 122 - Martin", label: "COMPLIT 122 - Martin" },
  { value: "COMPLIT 222 - Aleksic", label: "COMPLIT 222 - Aleksic" },
  { value: "COMPLIT 300 - Randall", label: "COMPLIT 300 - Randall" },
  { value: "COMPLIT 140 - Tsoffar", label: "COMPLIT 140 - Tsoffar" },
  { value: "COMPLIT 140 - Macfarlane", label: "COMPLIT 140 - Macfarlane" },
  { value: "COMPLIT 241 - Vorissis", label: "COMPLIT 241 - Vorissis" },
  { value: "COMPLIT 260 - Ekotto", label: "COMPLIT 260 - Ekotto" },
  { value: "COMPLIT 382 - Herwitz", label: "COMPLIT 382 - Herwitz" },
  { value: "COMPLIT 382 - Leontis", label: "COMPLIT 382 - Leontis" },
  { value: "COMPLIT 422 - Sarlati", label: "COMPLIT 422 - Sarlati" },
  { value: "COMPLIT 434 - Cross", label: "COMPLIT 434 - Cross" },
  { value: "COMPLIT 438 - Herwitz", label: "COMPLIT 438 - Herwitz" },
  { value: "COMPLIT 490 - Shammas", label: "COMPLIT 490 - Shammas" },
  { value: "COMPLIT 490 - Dickinson", label: "COMPLIT 490 - Dickinson" },
  { value: "CONDUCT 315 - Frey", label: "CONDUCT 315 - Frey" },
  { value: "CONDUCT 316 - Snyder", label: "CONDUCT 316 - Snyder" },
  { value: "CONDUCT 316", label: "CONDUCT 316" },
  { value: "CONDUCT 316 - Kemper", label: "CONDUCT 316 - Kemper" },
  { value: "CONDUCT 501 - Kiesler", label: "CONDUCT 501 - Kiesler" },
  { value: "CONDUCT 502 - Kiesler", label: "CONDUCT 502 - Kiesler" },
  { value: "CONDUCT 504 - Haithcock", label: "CONDUCT 504 - Haithcock" },
  { value: "CONDUCT 505", label: "CONDUCT 505" },
  { value: "CONDUCT 506 - Rogers Jr", label: "CONDUCT 506 - Rogers Jr" },
  { value: "CONDUCT 510 - Petty", label: "CONDUCT 510 - Petty" },
  { value: "CONDUCT 416", label: "CONDUCT 416" },
  { value: "CONDUCT 455", label: "CONDUCT 455" },
  { value: "CSP 101 - Wickham", label: "CSP 101 - Wickham" },
  { value: "CSP 105 - Naylor", label: "CSP 105 - Naylor" },
  { value: "CSP 100 - Winston", label: "CSP 100 - Winston" },
  { value: "DANCE 342 - Genne", label: "DANCE 342 - Genne" },
  { value: "DANCE 347 - Kane", label: "DANCE 347 - Kane" },
  { value: "DANCE 348 - Wilson", label: "DANCE 348 - Wilson" },
  { value: "DANCE 372 - Cole", label: "DANCE 372 - Cole" },
  { value: "DANCE 442 - Brissey", label: "DANCE 442 - Brissey" },
  { value: "DANCE 482 - Hopper", label: "DANCE 482 - Hopper" },
  { value: "DANCE 482 - Beck", label: "DANCE 482 - Beck" },
  { value: "DANCE 496 - Neslund", label: "DANCE 496 - Neslund" },
  { value: "DANCE 496 - Beck", label: "DANCE 496 - Beck" },
  { value: "DANCE 496 - Miao", label: "DANCE 496 - Miao" },
  {
    value: "DANCE 496 - Chavasse she-her-they",
    label: "DANCE 496 - Chavasse she-her-they",
  },
  { value: "DANCE 505 - Kane", label: "DANCE 505 - Kane" },
  { value: "DANCE 542 - Brissey", label: "DANCE 542 - Brissey" },
  { value: "DANCE 572 - Chavasse", label: "DANCE 572 - Chavasse" },
  { value: "DANCE 595 - Kane", label: "DANCE 595 - Kane" },
  { value: "DANCE 595 - Chavasse", label: "DANCE 595 - Chavasse" },
  { value: "DANCE 595 - Brissey", label: "DANCE 595 - Brissey" },
  { value: "DANCE 302", label: "DANCE 302" },
  { value: "DANCE 304", label: "DANCE 304" },
  { value: "DANCE 312", label: "DANCE 312" },
  { value: "DANCE 314", label: "DANCE 314" },
  { value: "DANCE 322", label: "DANCE 322" },
  { value: "DANCE 328", label: "DANCE 328" },
  { value: "DANCE 391", label: "DANCE 391" },
  { value: "DANCE 402", label: "DANCE 402" },
  { value: "DANCE 404", label: "DANCE 404" },
  { value: "DANCE 414", label: "DANCE 414" },
  { value: "DANCE 422", label: "DANCE 422" },
  { value: "DANCE 428", label: "DANCE 428" },
  { value: "DANCE 494", label: "DANCE 494" },
  { value: "DANCE 498", label: "DANCE 498" },
  { value: "DANCE 511", label: "DANCE 511" },
  { value: "DANCE 513", label: "DANCE 513" },
  { value: "DANCE 521", label: "DANCE 521" },
  { value: "DANCE 532", label: "DANCE 532" },
  { value: "DANCE 538", label: "DANCE 538" },
  { value: "DATASCI 101", label: "DATASCI 101" },
  { value: "DATASCI 406", label: "DATASCI 406" },
  { value: "DATASCI 415", label: "DATASCI 415" },
  { value: "DATASCI 485", label: "DATASCI 485" },
  { value: "DATASCI 504 - Mesner", label: "DATASCI 504 - Mesner" },
  { value: "DATASCI 507", label: "DATASCI 507" },
  { value: "DATASCI 509 - Thelen", label: "DATASCI 509 - Thelen" },
  { value: "DATASCI 531 - Ionides", label: "DATASCI 531 - Ionides" },
  { value: "DATASCI 551 - Nguyen", label: "DATASCI 551 - Nguyen" },
  { value: "DATASCI 306", label: "DATASCI 306" },
  { value: "DATASCI 413", label: "DATASCI 413" },
  { value: "DBLBASS 450", label: "DBLBASS 450" },
  { value: "DBLBASS 550", label: "DBLBASS 550" },
  { value: "DESCI 502 - Luntz", label: "DESCI 502 - Luntz" },
  { value: "DIGITAL 202 - Bushner", label: "DIGITAL 202 - Bushner" },
  { value: "DIGITAL 258 - Cornfeld", label: "DIGITAL 258 - Cornfeld" },
  { value: "DIGITAL 285 - McGinn", label: "DIGITAL 285 - McGinn" },
  { value: "DIGITAL 333 - Fishman", label: "DIGITAL 333 - Fishman" },
  { value: "DIGITAL 358 - Ferrari", label: "DIGITAL 358 - Ferrari" },
  { value: "DIGITAL 358 - Granata", label: "DIGITAL 358 - Granata" },
  { value: "DIGITAL 358 - Bushner", label: "DIGITAL 358 - Bushner" },
  { value: "DIGITAL 358 - Griffiths", label: "DIGITAL 358 - Griffiths" },
  { value: "DIGITAL 367 - Murphy", label: "DIGITAL 367 - Murphy" },
  { value: "DIGITAL 368 - Mihailova", label: "DIGITAL 368 - Mihailova" },
  { value: "DIGITAL 394 - Granata", label: "DIGITAL 394 - Granata" },
  { value: "DIGITAL 410 - Pasquetto", label: "DIGITAL 410 - Pasquetto" },
  { value: "DIGITAL 158 - Yergeau", label: "DIGITAL 158 - Yergeau" },
  { value: "DIGITAL 158 - Aristarkhova", label: "DIGITAL 158 - Aristarkhova" },
  { value: "DIGITAL 200 - Hetzel", label: "DIGITAL 200 - Hetzel" },
  { value: "DIGITAL 200 - Cassel", label: "DIGITAL 200 - Cassel" },
  { value: "DIGITAL 200 - Babcock", label: "DIGITAL 200 - Babcock" },
  { value: "DIGITAL 201 - Karczynski", label: "DIGITAL 201 - Karczynski" },
  { value: "DIGITAL 201 - Beal", label: "DIGITAL 201 - Beal" },
  { value: "DIGITAL 201 - Tell", label: "DIGITAL 201 - Tell" },
  { value: "DIGITAL 411 - Abrons", label: "DIGITAL 411 - Abrons" },
  { value: "DIGITAL 376", label: "DIGITAL 376" },
  { value: "DUTCH 112 - Vicca", label: "DUTCH 112 - Vicca" },
  { value: "DUTCH 232 - Toebosch", label: "DUTCH 232 - Toebosch" },
  { value: "DUTCH 512 - Vicca", label: "DUTCH 512 - Vicca" },
  { value: "DUTCH 532 - Toebosch", label: "DUTCH 532 - Toebosch" },
  { value: "DUTCH 351 - Toebosch", label: "DUTCH 351 - Toebosch" },
  { value: "EARLYMUS 551 - Gascho", label: "EARLYMUS 551 - Gascho" },
  { value: "EARTH 100 - Arnaboldi", label: "EARTH 100 - Arnaboldi" },
  { value: "EARTH 104 - Robbins", label: "EARTH 104 - Robbins" },
  { value: "EARTH 106 - Arnaboldi", label: "EARTH 106 - Arnaboldi" },
  { value: "EARTH 107 - Ruff", label: "EARTH 107 - Ruff" },
  { value: "EARTH 108 - Yarce", label: "EARTH 108 - Yarce" },
  {
    value: "EARTH 109 - Cruz Da Silva Castro",
    label: "EARTH 109 - Cruz Da Silva Castro",
  },
  { value: "EARTH 110 - Arnaboldi", label: "EARTH 110 - Arnaboldi" },
  { value: "EARTH 110 - Alt", label: "EARTH 110 - Alt" },
  { value: "EARTH 111 - Li", label: "EARTH 111 - Li" },
  { value: "EARTH 113 - Gleason", label: "EARTH 113 - Gleason" },
  { value: "EARTH 114 - Gleason", label: "EARTH 114 - Gleason" },
  { value: "EARTH 119 - Lohmann", label: "EARTH 119 - Lohmann" },
  { value: "EARTH 119 - Yarce", label: "EARTH 119 - Yarce" },
  { value: "EARTH 120 - Gleason", label: "EARTH 120 - Gleason" },
  { value: "EARTH 130 - Spica", label: "EARTH 130 - Spica" },
  { value: "EARTH 131 - Robbins", label: "EARTH 131 - Robbins" },
  { value: "EARTH 172 - Arnaboldi", label: "EARTH 172 - Arnaboldi" },
  { value: "EARTH 205 - Ruff", label: "EARTH 205 - Ruff" },
  { value: "EARTH 219 - Demers", label: "EARTH 219 - Demers" },
  { value: "EARTH 222 - Arbic", label: "EARTH 222 - Arbic" },
  { value: "EARTH 230 - Hetland", label: "EARTH 230 - Hetland" },
  { value: "EARTH 262 - Benedict", label: "EARTH 262 - Benedict" },
  { value: "EARTH 310 - Colacino", label: "EARTH 310 - Colacino" },
  { value: "EARTH 314 - Ritsema", label: "EARTH 314 - Ritsema" },
  { value: "EARTH 321 - Li", label: "EARTH 321 - Li" },
  { value: "EARTH 325 - Johnson", label: "EARTH 325 - Johnson" },
  { value: "EARTH 331 - Passey", label: "EARTH 331 - Passey" },
  { value: "EARTH 350 - Huang", label: "EARTH 350 - Huang" },
  { value: "EARTH 351 - van der Pluijm", label: "EARTH 351 - van der Pluijm" },
  { value: "EARTH 380 - Simon", label: "EARTH 380 - Simon" },
  { value: "EARTH 408 - Niemi", label: "EARTH 408 - Niemi" },
  { value: "EARTH 412 - Lange, Holder", label: "EARTH 412 - Lange, Holder" },
  { value: "EARTH 414 - Marsik", label: "EARTH 414 - Marsik" },
  { value: "EARTH 427 - Becker", label: "EARTH 427 - Becker" },
  { value: "EARTH 432 - Smith", label: "EARTH 432 - Smith" },
  { value: "EARTH 436 - Lohmann", label: "EARTH 436 - Lohmann" },
  { value: "EARTH 442 - Sheldon", label: "EARTH 442 - Sheldon" },
  { value: "EARTH 449 - Alt", label: "EARTH 449 - Alt" },
  { value: "EARTH 451 - van der Pluijm", label: "EARTH 451 - van der Pluijm" },
  { value: "EARTH 474 - Bassis", label: "EARTH 474 - Bassis" },
  { value: "EARTH 495 - Niemi", label: "EARTH 495 - Niemi" },
  { value: "EARTH 517 - Clark", label: "EARTH 517 - Clark" },
  { value: "EARTH 520 - Cole", label: "EARTH 520 - Cole" },
  { value: "EARTH 596 - Passey", label: "EARTH 596 - Passey" },
  { value: "EARTH 130", label: "EARTH 130" },
  { value: "EARTH 142 - Zhang", label: "EARTH 142 - Zhang" },
  { value: "EARTH 151 - Robbins", label: "EARTH 151 - Robbins" },
  { value: "EARTH 151 - Hendy", label: "EARTH 151 - Hendy" },
  { value: "EARTH 156 - Arnaboldi", label: "EARTH 156 - Arnaboldi" },
  { value: "EARTH 496 - Fisher", label: "EARTH 496 - Fisher" },
  { value: "EARTH 532 - Sheldon", label: "EARTH 532 - Sheldon" },
  { value: "EARTH 534 - Ritsema", label: "EARTH 534 - Ritsema" },
  { value: "EARTH 535 - Holder", label: "EARTH 535 - Holder" },
  { value: "EARTH 536 - Friedman", label: "EARTH 536 - Friedman" },
  { value: "EARTH 323", label: "EARTH 323" },
  { value: "EARTH 523", label: "EARTH 523" },
  { value: "EARTH 581", label: "EARTH 581" },
  { value: "EAS 451 - Harris", label: "EAS 451 - Harris" },
  { value: "EAS 475 - Hall", label: "EAS 475 - Hall" },
  { value: "EAS 476 - Zak, Allgeier", label: "EAS 476 - Zak, Allgeier" },
  { value: "EAS 480 - Rood", label: "EAS 480 - Rood" },
  { value: "EAS 503 - Jones", label: "EAS 503 - Jones" },
  { value: "EAS 507 - Alofs, Miljanic", label: "EAS 507 - Alofs, Miljanic" },
  { value: "EAS 510 - Bartlett", label: "EAS 510 - Bartlett" },
  { value: "EAS 517 - Weeks, Dias", label: "EAS 517 - Weeks, Dias" },
  { value: "EAS 518 - Foufopoulos", label: "EAS 518 - Foufopoulos" },
  { value: "EAS 538 - Ibanez", label: "EAS 538 - Ibanez" },
  { value: "EAS 541 - Bergen", label: "EAS 541 - Bergen" },
  { value: "EAS 545 - Currie", label: "EAS 545 - Currie" },
  {
    value: "EAS 557 - Keoleian, Fetner, Calzavara",
    label: "EAS 557 - Keoleian, Fetner, Calzavara",
  },
  { value: "EAS 558 - Hughes, Lazaroff", label: "EAS 558 - Hughes, Lazaroff" },
  { value: "EAS 559 - Fischer, Russo", label: "EAS 559 - Fischer, Russo" },
  { value: "EAS 561 - De Young", label: "EAS 561 - De Young" },
  { value: "EAS 563", label: "EAS 563" },
  { value: "EAS 565 - Princen", label: "EAS 565 - Princen" },
  { value: "EAS 573 - Xu", label: "EAS 573 - Xu" },
  { value: "EAS 575 - Stolper, Ren", label: "EAS 575 - Stolper, Ren" },
  { value: "EAS 588 - Wang", label: "EAS 588 - Wang" },
  { value: "EAS 592 - Norton", label: "EAS 592 - Norton" },
  { value: "EAS 550 - Xu", label: "EAS 550 - Xu" },
  { value: "EAS 595", label: "EAS 595" },
  { value: "EAS 501 - Jones", label: "EAS 501 - Jones" },
  { value: "EAS 501 - Whyte, De Jong", label: "EAS 501 - Whyte, De Jong" },
  { value: "EAS 501 - Gronewold", label: "EAS 501 - Gronewold" },
  { value: "EAS 501 - Du Russel", label: "EAS 501 - Du Russel" },
  { value: "EAS 501", label: "EAS 501" },
  { value: "EAS 501 - Vaishnav", label: "EAS 501 - Vaishnav" },
  { value: "EAS 501 - Nassauer", label: "EAS 501 - Nassauer" },
  {
    value: "EAS 501 - Adlerstein-Gonzalez",
    label: "EAS 501 - Adlerstein-Gonzalez",
  },
  { value: "EAS 501 - Jagger, Lazaroff", label: "EAS 501 - Jagger, Lazaroff" },
  { value: "EAS 409", label: "EAS 409" },
  { value: "EAS 531", label: "EAS 531" },
  { value: "EAS 590", label: "EAS 590" },
  { value: "ECON 101 - Zamzow", label: "ECON 101 - Zamzow" },
  { value: "ECON 101 - Dudley", label: "ECON 101 - Dudley" },
  { value: "ECON 101 - Caldwell Jr", label: "ECON 101 - Caldwell Jr" },
  { value: "ECON 102 - Cho", label: "ECON 102 - Cho" },
  { value: "ECON 102 - Dudek", label: "ECON 102 - Dudek" },
  { value: "ECON 251 - Sedo", label: "ECON 251 - Sedo" },
  { value: "ECON 251 - Asenova", label: "ECON 251 - Asenova" },
  { value: "ECON 258 - Hasanbasri", label: "ECON 258 - Hasanbasri" },
  { value: "ECON 259 - Wan", label: "ECON 259 - Wan" },
  { value: "ECON 310 - Dudek", label: "ECON 310 - Dudek" },
  { value: "ECON 325 - Stevenson", label: "ECON 325 - Stevenson" },
  { value: "ECON 395 - Hasanbasri", label: "ECON 395 - Hasanbasri" },
  { value: "ECON 396 - Dudek", label: "ECON 396 - Dudek" },
  { value: "ECON 398 - Peralta", label: "ECON 398 - Peralta" },
  { value: "ECON 401 - Proulx", label: "ECON 401 - Proulx" },
  { value: "ECON 402 - House", label: "ECON 402 - House" },
  { value: "ECON 409 - Liu", label: "ECON 409 - Liu" },
  { value: "ECON 412 - Blanco", label: "ECON 412 - Blanco" },
  { value: "ECON 421 - McCall", label: "ECON 421 - McCall" },
  { value: "ECON 429 - Bound", label: "ECON 429 - Bound" },
  { value: "ECON 432 - Brown", label: "ECON 432 - Brown" },
  { value: "ECON 435 - Ottonello", label: "ECON 435 - Ottonello" },
  { value: "ECON 441 - Bartelme", label: "ECON 441 - Bartelme" },
  { value: "ECON 451 - Hasanbasri", label: "ECON 451 - Hasanbasri" },
  { value: "ECON 452 - Hagemann", label: "ECON 452 - Hagemann" },
  { value: "ECON 466 - Rotella", label: "ECON 466 - Rotella" },
  { value: "ECON 490 - Craig", label: "ECON 490 - Craig" },
  { value: "ECON 490 - Ke", label: "ECON 490 - Ke" },
  { value: "ECON 490 - Mueller-Smith", label: "ECON 490 - Mueller-Smith" },
  { value: "ECON 491 - Rhode", label: "ECON 491 - Rhode" },
  { value: "ECON 492 - Adams", label: "ECON 492 - Adams" },
  { value: "ECON 502 - Laitner", label: "ECON 502 - Laitner" },
  { value: "ECON 504 - Asenova", label: "ECON 504 - Asenova" },
  { value: "ECON 101", label: "ECON 101" },
  { value: "ECON 102", label: "ECON 102" },
  { value: "ECON 401", label: "ECON 401" },
  { value: "ECON 108 - Proulx", label: "ECON 108 - Proulx" },
  { value: "ECON 208 - Dudley", label: "ECON 208 - Dudley" },
  { value: "ECON 495 - Slemrod", label: "ECON 495 - Slemrod" },
  { value: "ECON 495 - Reynoso", label: "ECON 495 - Reynoso" },
  { value: "ECON 497 - Dominguez", label: "ECON 497 - Dominguez" },
  {
    value: "EDCURINS 455 - Garcia-Amaya",
    label: "EDCURINS 455 - Garcia-Amaya",
  },
  { value: "EDUC 212 - Spath", label: "EDUC 212 - Spath" },
  { value: "EDUC 250 - Miller", label: "EDUC 250 - Miller" },
  { value: "EDUC 333 - Fishman", label: "EDUC 333 - Fishman" },
  { value: "EDUC 392", label: "EDUC 392" },
  { value: "EDUC 414 - Robertson", label: "EDUC 414 - Robertson" },
  { value: "EDUC 415 - Ball", label: "EDUC 415 - Ball" },
  { value: "EDUC 443", label: "EDUC 443" },
  { value: "EDUC 443 - Fortini", label: "EDUC 443 - Fortini" },
  { value: "EDUC 444 - Keren-Kolb", label: "EDUC 444 - Keren-Kolb" },
  { value: "EDUC 445 - Mies", label: "EDUC 445 - Mies" },
  { value: "EDUC 446 - Kolb", label: "EDUC 446 - Kolb" },
  { value: "EDUC 446", label: "EDUC 446" },
  { value: "EDUC 528 - Davis", label: "EDUC 528 - Davis" },
  {
    value: "EDUC 547 - Beilein, Merritt",
    label: "EDUC 547 - Beilein, Merritt",
  },
  { value: "EDUC 547 - Gold", label: "EDUC 547 - Gold" },
  { value: "EDUC 547 - Miller", label: "EDUC 547 - Miller" },
  {
    value: "EDUC 583 - Keren-Kolb, Upton, Pasque",
    label: "EDUC 583 - Keren-Kolb, Upton, Pasque",
  },
  { value: "EDUC 118 - Goldin", label: "EDUC 118 - Goldin" },
  { value: "EDUC 119 - Santone", label: "EDUC 119 - Santone" },
  { value: "EDUC 220 - Betts", label: "EDUC 220 - Betts" },
  { value: "EDUC 275 - Kovacs", label: "EDUC 275 - Kovacs" },
  { value: "EDUC 391 - Eggleston", label: "EDUC 391 - Eggleston" },
  { value: "EDUC 391 - Kilday", label: "EDUC 391 - Kilday" },
  { value: "EDUC 391 - Ebright", label: "EDUC 391 - Ebright" },
  { value: "EDUC 403 - Atkins", label: "EDUC 403 - Atkins" },
  { value: "EDUC 431 - Monte-Sano", label: "EDUC 431 - Monte-Sano" },
  { value: "EDUC 303 - Bruder", label: "EDUC 303 - Bruder" },
  { value: "EDUC 303 - Kennison", label: "EDUC 303 - Kennison" },
  { value: "EDUC 303 - Blunk", label: "EDUC 303 - Blunk" },
  { value: "EDUC 303 - Stull", label: "EDUC 303 - Stull" },
  { value: "EDUC 303 - Fortini", label: "EDUC 303 - Fortini" },
  { value: "EDUC 304 - Kingsland", label: "EDUC 304 - Kingsland" },
  { value: "EDUC 304", label: "EDUC 304" },
  { value: "EDUC 304 - Margolis", label: "EDUC 304 - Margolis" },
  { value: "EDUC 304 - Davis", label: "EDUC 304 - Davis" },
  { value: "EDUC 304 - Learman", label: "EDUC 304 - Learman" },
  { value: "EDUC 304 - Carduner", label: "EDUC 304 - Carduner" },
  { value: "EDUC 304 - Crowley", label: "EDUC 304 - Crowley" },
  { value: "EDUC 304 - Hoops", label: "EDUC 304 - Hoops" },
  { value: "EDUC 304 - McDermott", label: "EDUC 304 - McDermott" },
  { value: "EDUC 365 - Zemper", label: "EDUC 365 - Zemper" },
  { value: "EDUC 390 - Romano Gillette", label: "EDUC 390 - Romano Gillette" },
  { value: "EDUC 404 - Epperson", label: "EDUC 404 - Epperson" },
  { value: "EDUC 471 - Davis III", label: "EDUC 471 - Davis III" },
  { value: "EDUC 480 - Moje", label: "EDUC 480 - Moje" },
  { value: "EDUC 490 - Atkins", label: "EDUC 490 - Atkins" },
  { value: "EDUC 490", label: "EDUC 490" },
  { value: "EDUC 490 - Rivas-Drake", label: "EDUC 490 - Rivas-Drake" },
  { value: "EDUC 490 - Epperson", label: "EDUC 490 - Epperson" },
  {
    value: "EDUC 504 - Pasque, Stanzler",
    label: "EDUC 504 - Pasque, Stanzler",
  },
  {
    value: "EDUC 504 - Stanzler, Pasque",
    label: "EDUC 504 - Stanzler, Pasque",
  },
  { value: "EDUC 510 - Santone", label: "EDUC 510 - Santone" },
  { value: "EDUC 510 - Robertson", label: "EDUC 510 - Robertson" },
  { value: "EDUC 526 - Schleppegrell", label: "EDUC 526 - Schleppegrell" },
  { value: "EDUC 540 - Sherman", label: "EDUC 540 - Sherman" },
  { value: "EDUC 542 - Seng", label: "EDUC 542 - Seng" },
  { value: "EDUC 551 - Oberman", label: "EDUC 551 - Oberman" },
  { value: "EDUC 555 - Helber", label: "EDUC 555 - Helber" },
  { value: "EDUC 570 - Peurach", label: "EDUC 570 - Peurach" },
  { value: "EDUC 571 - Coolican", label: "EDUC 571 - Coolican" },
  { value: "EDUC 571 - Hearn", label: "EDUC 571 - Hearn" },
  { value: "EDUC 580 - Kuppers", label: "EDUC 580 - Kuppers" },
  { value: "EDUC 591 - Herrenkohl", label: "EDUC 591 - Herrenkohl" },
  { value: "EDUC 301", label: "EDUC 301" },
  { value: "EDUC 302", label: "EDUC 302" },
  { value: "EDUC 307", label: "EDUC 307" },
  { value: "EDUC 330", label: "EDUC 330" },
  { value: "EDUC 462", label: "EDUC 462" },
  { value: "EDUC 463", label: "EDUC 463" },
  { value: "EEB 390 - Winger", label: "EEB 390 - Winger" },
  { value: "EEB 391 - Rabosky", label: "EEB 391 - Rabosky" },
  { value: "EEB 401 - Badgley", label: "EEB 401 - Badgley" },
  { value: "EEB 413 - Kondrashov", label: "EEB 413 - Kondrashov" },
  { value: "EEB 451 - Harris", label: "EEB 451 - Harris" },
  { value: "EEB 476 - Zak, Allgeier", label: "EEB 476 - Zak, Allgeier" },
  { value: "EEB 494 - Kurdziel", label: "EEB 494 - Kurdziel" },
  { value: "EEB 516 - Rabosky", label: "EEB 516 - Rabosky" },
  {
    value: "EEB 410 - Rabosky, Kondrashov",
    label: "EEB 410 - Rabosky, Kondrashov",
  },
  { value: "EEB 499 - Jakob", label: "EEB 499 - Jakob" },
  { value: "EEB 523 - DuBay", label: "EEB 523 - DuBay" },
  { value: "EEB 372", label: "EEB 372" },
  { value: "EEB 450", label: "EEB 450" },
  { value: "EEB 487", label: "EEB 487" },
  { value: "EEB 491", label: "EEB 491" },
  { value: "EECS 183", label: "EECS 183" },
  { value: "EECS 200 - Pierce", label: "EECS 200 - Pierce" },
  { value: "EECS 201 - Nguyen", label: "EECS 201 - Nguyen" },
  { value: "EECS 215 - Afshari", label: "EECS 215 - Afshari" },
  { value: "EECS 215 - Zhang", label: "EECS 215 - Zhang" },
  {
    value: "EECS 216 - Lafortune, Wakefield",
    label: "EECS 216 - Lafortune, Wakefield",
  },
  { value: "EECS 230 - Norris", label: "EECS 230 - Norris" },
  { value: "EECS 250 - Dyer Jr", label: "EECS 250 - Dyer Jr" },
  { value: "EECS 270 - Shin", label: "EECS 270 - Shin" },
  { value: "EECS 280", label: "EECS 280" },
  { value: "EECS 281", label: "EECS 281" },
  { value: "EECS 301 - Stark", label: "EECS 301 - Stark" },
  { value: "EECS 311 - Najafi", label: "EECS 311 - Najafi" },
  { value: "EECS 312 - Kim", label: "EECS 312 - Kim" },
  { value: "EECS 314 - Ganago", label: "EECS 314 - Ganago" },
  { value: "EECS 320 - Peterson", label: "EECS 320 - Peterson" },
  { value: "EECS 330 - Willingale", label: "EECS 330 - Willingale" },
  { value: "EECS 334 - Willingale, Guo", label: "EECS 334 - Willingale, Guo" },
  { value: "EECS 351 - Arbabjolfaei", label: "EECS 351 - Arbabjolfaei" },
  { value: "EECS 370 - W21", label: "EECS 370 - W21" },
  { value: "EECS 373 - Sample", label: "EECS 373 - Sample" },
  {
    value: "EECS 388 - Genkin, Honeyman",
    label: "EECS 388 - Genkin, Honeyman",
  },
  { value: "EECS 398 - Kamil", label: "EECS 398 - Kamil" },
  { value: "EECS 402 - Morgan", label: "EECS 402 - Morgan" },
  { value: "EECS 403", label: "EECS 403" },
  { value: "EECS 410 - Islam", label: "EECS 410 - Islam" },
  { value: "EECS 419 - Hofmann", label: "EECS 419 - Hofmann" },
  { value: "EECS 425 - Yoon", label: "EECS 425 - Yoon" },
  { value: "EECS 427 - Zhang", label: "EECS 427 - Zhang" },
  { value: "EECS 430 - Budhu", label: "EECS 430 - Budhu" },
  { value: "EECS 438 - Galvanauskas", label: "EECS 438 - Galvanauskas" },
  { value: "EECS 440 - Hamilton", label: "EECS 440 - Hamilton" },
  { value: "EECS 441 - Soloway", label: "EECS 441 - Soloway" },
  { value: "EECS 441 - Jamin", label: "EECS 441 - Jamin" },
  { value: "EECS 442 - Fouhey", label: "EECS 442 - Fouhey" },
  { value: "EECS 442 - Johnson", label: "EECS 442 - Johnson" },
  { value: "EECS 445", label: "EECS 445" },
  { value: "EECS 452 - Revzen", label: "EECS 452 - Revzen" },
  { value: "EECS 460 - Meerkov", label: "EECS 460 - Meerkov" },
  { value: "EECS 461 - Cook", label: "EECS 461 - Cook" },
  { value: "EECS 467 - Jenkins", label: "EECS 467 - Jenkins" },
  { value: "EECS 470 - Beaumont", label: "EECS 470 - Beaumont" },
  { value: "EECS 475 - Cheraghchi", label: "EECS 475 - Cheraghchi" },
  { value: "EECS 476 - Vahedian", label: "EECS 476 - Vahedian" },
  { value: "EECS 477 - Saranurak", label: "EECS 477 - Saranurak" },
  { value: "EECS 481 - Leach", label: "EECS 481 - Leach" },
  {
    value: "EECS 482 - Kasikci, Kapritsos, Chen",
    label: "EECS 482 - Kasikci, Kapritsos, Chen",
  },
  { value: "EECS 483", label: "EECS 483" },
  { value: "EECS 483 - Tang", label: "EECS 483 - Tang" },
  { value: "EECS 484 - Almomani", label: "EECS 484 - Almomani" },
  { value: "EECS 485", label: "EECS 485" },
  { value: "EECS 486 - Mihalcea", label: "EECS 486 - Mihalcea" },
  { value: "EECS 490 - Omar", label: "EECS 490 - Omar" },
  { value: "EECS 491 - Madhyastha", label: "EECS 491 - Madhyastha" },
  { value: "EECS 492 - Chai", label: "EECS 492 - Chai" },
  { value: "EECS 493", label: "EECS 493" },
  { value: "EECS 494 - Yarger", label: "EECS 494 - Yarger" },
  { value: "EECS 495 - Chesney", label: "EECS 495 - Chesney" },
  { value: "EECS 496 - Chesney", label: "EECS 496 - Chesney" },
  { value: "EECS 497 - Ringenberg", label: "EECS 497 - Ringenberg" },
  { value: "EECS 498 - Kuipers", label: "EECS 498 - Kuipers" },
  {
    value: "EECS 498 - Kasikci, Chen, Kapritsos",
    label: "EECS 498 - Kasikci, Chen, Kapritsos",
  },
  { value: "EECS 498 - Wang", label: "EECS 498 - Wang" },
  { value: "EECS 498 - Mower Provost", label: "EECS 498 - Mower Provost" },
  { value: "EECS 498 - Jagadish, Gryak", label: "EECS 498 - Jagadish, Gryak" },
  { value: "EECS 498 - Fessler", label: "EECS 498 - Fessler" },
  { value: "EECS 559 - Qu, Zhu", label: "EECS 559 - Qu, Zhu" },
  { value: "EECS 501 - Pradhan", label: "EECS 501 - Pradhan" },
  { value: "EECS 502 - Subramanian", label: "EECS 502 - Subramanian" },
  { value: "EECS 503 - Tsang", label: "EECS 503 - Tsang" },
  { value: "EECS 507 - Dick", label: "EECS 507 - Dick" },
  { value: "EECS 517 - Kushner", label: "EECS 517 - Kushner" },
  { value: "EECS 518 - Zou", label: "EECS 518 - Zou" },
  { value: "EECS 522 - Wentzloff", label: "EECS 522 - Wentzloff" },
  { value: "EECS 524 - Forrest", label: "EECS 524 - Forrest" },
  { value: "EECS 525 - Mortazawi", label: "EECS 525 - Mortazawi" },
  { value: "EECS 526 - Lee", label: "EECS 526 - Lee" },
  { value: "EECS 528 - Kanicki", label: "EECS 528 - Kanicki" },
  { value: "EECS 531 - Grbic", label: "EECS 531 - Grbic" },
  { value: "EECS 532 - Ulaby", label: "EECS 532 - Ulaby" },
  { value: "EECS 533 - Sarabandi", label: "EECS 533 - Sarabandi" },
  { value: "EECS 534 - Hiskens", label: "EECS 534 - Hiskens" },
  { value: "EECS 539 - Galvanauskas", label: "EECS 539 - Galvanauskas" },
  { value: "EECS 541 - Kira", label: "EECS 541 - Kira" },
  { value: "EECS 545 - Lee", label: "EECS 545 - Lee" },
  { value: "EECS 556 - Fessler", label: "EECS 556 - Fessler" },
  { value: "EECS 557 - Ying", label: "EECS 557 - Ying" },
  { value: "EECS 559 - Qu", label: "EECS 559 - Qu" },
  { value: "EECS 560 - Ozay", label: "EECS 560 - Ozay" },
  { value: "EECS 561 - Vasudevan", label: "EECS 561 - Vasudevan" },
  { value: "EECS 562 - Meerkov", label: "EECS 562 - Meerkov" },
  { value: "EECS 565 - Seiler", label: "EECS 565 - Seiler" },
  { value: "EECS 567 - Gregg", label: "EECS 567 - Gregg" },
  { value: "EECS 568 - Ghaffari Jadidi", label: "EECS 568 - Ghaffari Jadidi" },
  { value: "EECS 570", label: "EECS 570" },
  { value: "EECS 570 - Narayanasamy", label: "EECS 570 - Narayanasamy" },
  { value: "EECS 579 - Hayes", label: "EECS 579 - Hayes" },
  { value: "EECS 590 - Weimer", label: "EECS 590 - Weimer" },
  { value: "EECS 592", label: "EECS 592" },
  { value: "EECS 592 - Durfee", label: "EECS 592 - Durfee" },
  { value: "EECS 598 - Kuipers", label: "EECS 598 - Kuipers" },
  { value: "EECS 598 - Bodwin", label: "EECS 598 - Bodwin" },
  { value: "EECS 598 - Ahmadi", label: "EECS 598 - Ahmadi" },
  { value: "EECS 598 - Pradhan", label: "EECS 598 - Pradhan" },
  { value: "EECS 598 - Berenson", label: "EECS 598 - Berenson" },
  { value: "EECS 598 - Prakash", label: "EECS 598 - Prakash" },
  { value: "EECS 598 - Scott", label: "EECS 598 - Scott" },
  { value: "EECS 598 - Chowdhury", label: "EECS 598 - Chowdhury" },
  { value: "EECS 598", label: "EECS 598" },
  { value: "EECS 598 - Mower Provost", label: "EECS 598 - Mower Provost" },
  { value: "EECS 598 - Wang", label: "EECS 598 - Wang" },
  { value: "EECS 598 - Owens", label: "EECS 598 - Owens" },
  { value: "EECS 598 - Jagadish, Gryak", label: "EECS 598 - Jagadish, Gryak" },
  { value: "EECS 409 - Jagadish", label: "EECS 409 - Jagadish" },
  { value: "EECS 300", label: "EECS 300" },
  { value: "EECS 429", label: "EECS 429" },
  { value: "EECS 458", label: "EECS 458" },
  { value: "EECS 519", label: "EECS 519" },
  { value: "EECS 596", label: "EECS 596" },
  { value: "EECS 559 - Qu, Zhu", label: "EECS 559 - Qu, Zhu" },
  { value: "EECS 570 ", label: "EECS 570 " },
  { value: "EECS 586 - Lee", label: "EECS 586 - Lee" },
  {
    value: "EECS 592 - Durfee, Rajendran",
    label: "EECS 592 - Durfee, Rajendran",
  },
  { value: "EECS 598 ", label: "EECS 598 " },
  {
    value: "EECS 598 - Mower Provost, Liu, Lattimer",
    label: "EECS 598 - Mower Provost, Liu, Lattimer",
  },
  { value: "EECS 600 - Balzano", label: "EECS 600 - Balzano" },
  { value: "EECS 605 - Nadakuditi", label: "EECS 605 - Nadakuditi" },
  { value: "EECS 620 - Mi", label: "EECS 620 - Mi" },
  { value: "EECS 627 - Blaauw", label: "EECS 627 - Blaauw" },
  { value: "EECS 634 - Winful", label: "EECS 634 - Winful" },
  { value: "EECS 638 - Kira", label: "EECS 638 - Kira" },
  { value: "EECS 692 - Laird", label: "EECS 692 - Laird" },
  {
    value: "EER 598 - Guzdial , Huang-Saad",
    label: "EER 598 - Guzdial , Huang-Saad",
  },
  { value: "EHS 570 - Xi", label: "EHS 570 - Xi" },
  { value: "EHS 576 - Xi", label: "EHS 576 - Xi" },
  { value: "EHS 588 - Hall", label: "EHS 588 - Hall" },
  { value: "EHS 596 - Charles", label: "EHS 596 - Charles" },
  { value: "EHS 597 - Dvonch", label: "EHS 597 - Dvonch" },
  { value: "EHS 504 - Rozek", label: "EHS 504 - Rozek" },
  { value: "ELI 351 - Guo", label: "ELI 351 - Guo" },
  { value: "ELI 502 - Danek", label: "ELI 502 - Danek" },
  { value: "ELI 510 - Bogart", label: "ELI 510 - Bogart" },
  { value: "ELI 512 - Coleman", label: "ELI 512 - Coleman" },
  { value: "ELI 521 - Coleman", label: "ELI 521 - Coleman" },
  { value: "ELI 521 - McDonald", label: "ELI 521 - McDonald" },
  { value: "ELI 522 - Coleman", label: "ELI 522 - Coleman" },
  { value: "ELI 531 - Eddy", label: "ELI 531 - Eddy" },
  { value: "ELI 531 - Coleman", label: "ELI 531 - Coleman" },
  { value: "ELI 532 - Dowling", label: "ELI 532 - Dowling" },
  { value: "ELI 534 - Dowling", label: "ELI 534 - Dowling" },
  { value: "ELI 536 - Bogart", label: "ELI 536 - Bogart" },
  { value: "ELI 538 - Kato", label: "ELI 538 - Kato" },
  { value: "ELI 539 - Imber", label: "ELI 539 - Imber" },
  { value: "ELI 544 - Des Jardins", label: "ELI 544 - Des Jardins" },
  { value: "ELI 560 - Des Jardins", label: "ELI 560 - Des Jardins" },
  { value: "ELI 580 - Romano Gillette", label: "ELI 580 - Romano Gillette" },
  { value: "ELI 592 - Danek", label: "ELI 592 - Danek" },
  { value: "ELI 131 - Romano Gillette", label: "ELI 131 - Romano Gillette" },
  { value: "ELI 210 - Bogart", label: "ELI 210 - Bogart" },
  { value: "ELI 236 - Bogart", label: "ELI 236 - Bogart" },
  { value: "ELI 390 - Romano Gillette", label: "ELI 390 - Romano Gillette" },
  { value: "ELI 390 - Dyer", label: "ELI 390 - Dyer" },
  { value: "ELI 584 - Imber", label: "ELI 584 - Imber" },
  {
    value: "ENGLISH 203 - Portnoy she/her, Van Zanen",
    label: "ENGLISH 203 - Portnoy she/her, Van Zanen",
  },
  { value: "ENGLISH 215 - Zemgulys", label: "ENGLISH 215 - Zemgulys" },
  { value: "ENGLISH 235 - Ensor", label: "ENGLISH 235 - Ensor" },
  {
    value: "ENGLISH 258 - Levinson, Fox, Robertson",
    label: "ENGLISH 258 - Levinson, Fox, Robertson",
  },
  { value: "ENGLISH 267 - Trevor", label: "ENGLISH 267 - Trevor" },
  { value: "ENGLISH 274 - Santamarina", label: "ENGLISH 274 - Santamarina" },
  { value: "ENGLISH 290 - Chamberlin", label: "ENGLISH 290 - Chamberlin" },
  { value: "ENGLISH 290 - Fitzgerald", label: "ENGLISH 290 - Fitzgerald" },
  { value: "ENGLISH 290 - Pinsker", label: "ENGLISH 290 - Pinsker" },
  { value: "ENGLISH 292 - Brandolino", label: "ENGLISH 292 - Brandolino" },
  { value: "ENGLISH 293 - Brater", label: "ENGLISH 293 - Brater" },
  {
    value: "ENGLISH 313 - Makman, Sprouse",
    label: "ENGLISH 313 - Makman, Sprouse",
  },
  { value: "ENGLISH 313 - Lyons", label: "ENGLISH 313 - Lyons" },
  { value: "ENGLISH 313 - Martin", label: "ENGLISH 313 - Martin" },
  { value: "ENGLISH 313 - Mecklenburg", label: "ENGLISH 313 - Mecklenburg" },
  { value: "ENGLISH 313 - Schey", label: "ENGLISH 313 - Schey" },
  {
    value: "ENGLISH 314 - Kolenda-Mason",
    label: "ENGLISH 314 - Kolenda-Mason",
  },
  { value: "ENGLISH 317 - Brandolino", label: "ENGLISH 317 - Brandolino" },
  {
    value: "ENGLISH 317 - Poulson-Bryant",
    label: "ENGLISH 317 - Poulson-Bryant",
  },
  { value: "ENGLISH 317 - Mitchell", label: "ENGLISH 317 - Mitchell" },
  { value: "ENGLISH 317 - Bardenstein", label: "ENGLISH 317 - Bardenstein" },
  { value: "ENGLISH 318 - Kuppers", label: "ENGLISH 318 - Kuppers" },
  { value: "ENGLISH 319 - Nichols", label: "ENGLISH 319 - Nichols" },
  { value: "ENGLISH 319 - Anderson", label: "ENGLISH 319 - Anderson" },
  { value: "ENGLISH 332 - Nichols", label: "ENGLISH 332 - Nichols" },
  { value: "ENGLISH 341 - Makman", label: "ENGLISH 341 - Makman" },
  { value: "ENGLISH 351 - Hawes", label: "ENGLISH 351 - Hawes" },
  { value: "ENGLISH 352 - Hartley", label: "ENGLISH 352 - Hartley" },
  { value: "ENGLISH 362 - Cawkwell", label: "ENGLISH 362 - Cawkwell" },
  { value: "ENGLISH 367 - Cohen", label: "ENGLISH 367 - Cohen" },
  { value: "ENGLISH 375 - Sarlati", label: "ENGLISH 375 - Sarlati" },
  { value: "ENGLISH 379 - Levy-Hussen", label: "ENGLISH 379 - Levy-Hussen" },
  { value: "ENGLISH 383 - Levinson", label: "ENGLISH 383 - Levinson" },
  { value: "ENGLISH 388 - Najita", label: "ENGLISH 388 - Najita" },
  { value: "ENGLISH 403 - Hawes", label: "ENGLISH 403 - Hawes" },
  { value: "ENGLISH 411 - Lahiri", label: "ENGLISH 411 - Lahiri" },
  {
    value: "ENGLISH 463 - Whittier-Ferguson",
    label: "ENGLISH 463 - Whittier-Ferguson",
  },
  { value: "ENGLISH 124 - Van Cleve", label: "ENGLISH 124 - Van Cleve" },
  { value: "ENGLISH 124 - Dorman", label: "ENGLISH 124 - Dorman" },
  { value: "ENGLISH 124 - Godfrey", label: "ENGLISH 124 - Godfrey" },
  { value: "ENGLISH 124 - Rudewicz", label: "ENGLISH 124 - Rudewicz" },
  { value: "ENGLISH 124 - Frasure", label: "ENGLISH 124 - Frasure" },
  { value: "ENGLISH 124 - Strout", label: "ENGLISH 124 - Strout" },
  { value: "ENGLISH 124 - Hill", label: "ENGLISH 124 - Hill" },
  { value: "ENGLISH 124 - Braun", label: "ENGLISH 124 - Braun" },
  { value: "ENGLISH 124 - Kaczmar", label: "ENGLISH 124 - Kaczmar" },
  { value: "ENGLISH 124 - Behrend", label: "ENGLISH 124 - Behrend" },
  { value: "ENGLISH 124 - Wilson", label: "ENGLISH 124 - Wilson" },
  { value: "ENGLISH 124 - Jabbari", label: "ENGLISH 124 - Jabbari" },
  { value: "ENGLISH 124 - Kerwin", label: "ENGLISH 124 - Kerwin" },
  { value: "ENGLISH 124 - Bento", label: "ENGLISH 124 - Bento" },
  { value: "ENGLISH 124 - Sarpy", label: "ENGLISH 124 - Sarpy" },
  { value: "ENGLISH 124 - Philbrick", label: "ENGLISH 124 - Philbrick" },
  { value: "ENGLISH 124 - Cobblah", label: "ENGLISH 124 - Cobblah" },
  { value: "ENGLISH 124 - Cullinane", label: "ENGLISH 124 - Cullinane" },
  { value: "ENGLISH 124 - Moore", label: "ENGLISH 124 - Moore" },
  { value: "ENGLISH 124 - Gillum", label: "ENGLISH 124 - Gillum" },
  { value: "ENGLISH 124 - Cimon", label: "ENGLISH 124 - Cimon" },
  { value: "ENGLISH 124 - McCormick", label: "ENGLISH 124 - McCormick" },
  { value: "ENGLISH 124 - Grant", label: "ENGLISH 124 - Grant" },
  { value: "ENGLISH 124 - Pelletier", label: "ENGLISH 124 - Pelletier" },
  { value: "ENGLISH 125 - Ratering", label: "ENGLISH 125 - Ratering" },
  { value: "ENGLISH 125 - Thum-OBrien", label: "ENGLISH 125 - Thum-OBrien" },
  { value: "ENGLISH 125 - McCracken", label: "ENGLISH 125 - McCracken" },
  { value: "ENGLISH 125 - Wall", label: "ENGLISH 125 - Wall" },
  { value: "ENGLISH 125 - Christman", label: "ENGLISH 125 - Christman" },
  { value: "ENGLISH 125 - Mota", label: "ENGLISH 125 - Mota" },
  { value: "ENGLISH 125 - Thomas", label: "ENGLISH 125 - Thomas" },
  { value: "ENGLISH 125 - Wiora", label: "ENGLISH 125 - Wiora" },
  { value: "ENGLISH 125 - Adams", label: "ENGLISH 125 - Adams" },
  { value: "ENGLISH 125 - Lynch", label: "ENGLISH 125 - Lynch" },
  { value: "ENGLISH 125 - Buckley", label: "ENGLISH 125 - Buckley" },
  {
    value: "ENGLISH 125 - Appleton Pine",
    label: "ENGLISH 125 - Appleton Pine",
  },
  { value: "ENGLISH 125 - Ordonez", label: "ENGLISH 125 - Ordonez" },
  { value: "ENGLISH 125 - Bruno", label: "ENGLISH 125 - Bruno" },
  { value: "ENGLISH 125 - Freeman", label: "ENGLISH 125 - Freeman" },
  { value: "ENGLISH 125 - Crowder", label: "ENGLISH 125 - Crowder" },
  { value: "ENGLISH 125 - Kupetz", label: "ENGLISH 125 - Kupetz" },
  { value: "ENGLISH 125 - Falomo", label: "ENGLISH 125 - Falomo" },
  { value: "ENGLISH 125 - Uluc", label: "ENGLISH 125 - Uluc" },
  { value: "ENGLISH 125 - Kim-Butler", label: "ENGLISH 125 - Kim-Butler" },
  { value: "ENGLISH 125 - Grimm", label: "ENGLISH 125 - Grimm" },
  { value: "ENGLISH 125 - Glover", label: "ENGLISH 125 - Glover" },
  { value: "ENGLISH 125 - Bisnath", label: "ENGLISH 125 - Bisnath" },
  { value: "ENGLISH 125 - Notah", label: "ENGLISH 125 - Notah" },
  { value: "ENGLISH 125 - Berryman", label: "ENGLISH 125 - Berryman" },
  { value: "ENGLISH 125 - Bransfield", label: "ENGLISH 125 - Bransfield" },
  { value: "ENGLISH 125 - Harlow", label: "ENGLISH 125 - Harlow" },
  { value: "ENGLISH 125 - Araghi", label: "ENGLISH 125 - Araghi" },
  {
    value: "ENGLISH 125 - Wong Gonzales",
    label: "ENGLISH 125 - Wong Gonzales",
  },
  { value: "ENGLISH 125 - Song", label: "ENGLISH 125 - Song" },
  { value: "ENGLISH 125 - Allen", label: "ENGLISH 125 - Allen" },
  { value: "ENGLISH 125 - Ramsey", label: "ENGLISH 125 - Ramsey" },
  { value: "ENGLISH 125 - Pandey", label: "ENGLISH 125 - Pandey" },
  { value: "ENGLISH 125 - Puckett", label: "ENGLISH 125 - Puckett" },
  { value: "ENGLISH 125 - Na", label: "ENGLISH 125 - Na" },
  { value: "ENGLISH 125 - Woodworth", label: "ENGLISH 125 - Woodworth" },
  { value: "ENGLISH 125 - Amna", label: "ENGLISH 125 - Amna" },
  { value: "ENGLISH 125 - Munson", label: "ENGLISH 125 - Munson" },
  { value: "ENGLISH 125 - An", label: "ENGLISH 125 - An" },
  { value: "ENGLISH 125 - Reese", label: "ENGLISH 125 - Reese" },
  { value: "ENGLISH 125 - Brown", label: "ENGLISH 125 - Brown" },
  { value: "ENGLISH 125 - Godfrey", label: "ENGLISH 125 - Godfrey" },
  { value: "ENGLISH 125 - Wamser", label: "ENGLISH 125 - Wamser" },
  { value: "ENGLISH 125 - McCarty", label: "ENGLISH 125 - McCarty" },
  { value: "ENGLISH 125 - Hartwell", label: "ENGLISH 125 - Hartwell" },
  { value: "ENGLISH 125 - Burch", label: "ENGLISH 125 - Burch" },
  { value: "ENGLISH 125 - Renner", label: "ENGLISH 125 - Renner" },
  { value: "ENGLISH 125 - Moody", label: "ENGLISH 125 - Moody" },
  { value: "ENGLISH 125 - Duan", label: "ENGLISH 125 - Duan" },
  { value: "ENGLISH 125 - Carlson", label: "ENGLISH 125 - Carlson" },
  { value: "ENGLISH 125 - Fairfield", label: "ENGLISH 125 - Fairfield" },
  { value: "ENGLISH 125 - Smith", label: "ENGLISH 125 - Smith" },
  { value: "ENGLISH 125 - Ventura", label: "ENGLISH 125 - Ventura" },
  { value: "ENGLISH 125 - Boger", label: "ENGLISH 125 - Boger" },
  { value: "ENGLISH 125 - Spooner", label: "ENGLISH 125 - Spooner" },
  { value: "ENGLISH 125 - Nelles", label: "ENGLISH 125 - Nelles" },
  { value: "ENGLISH 125 - Beauchamp", label: "ENGLISH 125 - Beauchamp" },
  { value: "ENGLISH 125 - Ward", label: "ENGLISH 125 - Ward" },
  { value: "ENGLISH 125 - Moss", label: "ENGLISH 125 - Moss" },
  { value: "ENGLISH 125 - McLemore", label: "ENGLISH 125 - McLemore" },
  { value: "ENGLISH 125 - Fernelius", label: "ENGLISH 125 - Fernelius" },
  { value: "ENGLISH 125 - Owada", label: "ENGLISH 125 - Owada" },
  { value: "ENGLISH 125 - Witherite", label: "ENGLISH 125 - Witherite" },
  { value: "ENGLISH 125 - Geiger", label: "ENGLISH 125 - Geiger" },
  { value: "ENGLISH 125 - Ferszt", label: "ENGLISH 125 - Ferszt" },
  { value: "ENGLISH 125 - McLaughlin", label: "ENGLISH 125 - McLaughlin" },
  { value: "ENGLISH 125 - Reel", label: "ENGLISH 125 - Reel" },
  { value: "ENGLISH 125", label: "ENGLISH 125" },
  { value: "ENGLISH 126 - McLaughlin", label: "ENGLISH 126 - McLaughlin" },
  { value: "ENGLISH 126 - Romaine", label: "ENGLISH 126 - Romaine" },
  { value: "ENGLISH 214 - Ensor", label: "ENGLISH 214 - Ensor" },
  { value: "ENGLISH 221 - Pinto", label: "ENGLISH 221 - Pinto" },
  { value: "ENGLISH 221", label: "ENGLISH 221" },
  { value: "ENGLISH 221 - Walker", label: "ENGLISH 221 - Walker" },
  { value: "ENGLISH 223 - Majeski", label: "ENGLISH 223 - Majeski" },
  { value: "ENGLISH 223 - Morrow", label: "ENGLISH 223 - Morrow" },
  { value: "ENGLISH 223 - Burch", label: "ENGLISH 223 - Burch" },
  { value: "ENGLISH 223 - Swanson", label: "ENGLISH 223 - Swanson" },
  { value: "ENGLISH 223 - Argy", label: "ENGLISH 223 - Argy" },
  { value: "ENGLISH 223 - Afshar", label: "ENGLISH 223 - Afshar" },
  { value: "ENGLISH 223 - Greer", label: "ENGLISH 223 - Greer" },
  { value: "ENGLISH 223 - McDaniel", label: "ENGLISH 223 - McDaniel" },
  { value: "ENGLISH 223 - Valdez", label: "ENGLISH 223 - Valdez" },
  { value: "ENGLISH 223 - Schutz", label: "ENGLISH 223 - Schutz" },
  { value: "ENGLISH 223 - John", label: "ENGLISH 223 - John" },
  { value: "ENGLISH 223 - Christman", label: "ENGLISH 223 - Christman" },
  { value: "ENGLISH 223 - Bode", label: "ENGLISH 223 - Bode" },
  { value: "ENGLISH 223 - Dobson", label: "ENGLISH 223 - Dobson" },
  { value: "ENGLISH 223 - Buckley", label: "ENGLISH 223 - Buckley" },
  { value: "ENGLISH 223 - Dobjensky", label: "ENGLISH 223 - Dobjensky" },
  { value: "ENGLISH 225 - Schutz", label: "ENGLISH 225 - Schutz" },
  { value: "ENGLISH 225", label: "ENGLISH 225" },
  {
    value: "ENGLISH 225 - Wheeler she/her",
    label: "ENGLISH 225 - Wheeler she/her",
  },
  { value: "ENGLISH 225 - Taylor III", label: "ENGLISH 225 - Taylor III" },
  { value: "ENGLISH 229", label: "ENGLISH 229" },
  { value: "ENGLISH 229 - Zanders", label: "ENGLISH 229 - Zanders" },
  { value: "ENGLISH 230 - Tolle", label: "ENGLISH 230 - Tolle" },
  {
    value: "ENGLISH 240 - Whittier-Ferguson, Rossing",
    label: "ENGLISH 240 - Whittier-Ferguson, Rossing",
  },
  { value: "ENGLISH 245 - Westlake", label: "ENGLISH 245 - Westlake" },
  { value: "ENGLISH 298 - Lyons", label: "ENGLISH 298 - Lyons" },
  { value: "ENGLISH 298 - Traisnel", label: "ENGLISH 298 - Traisnel" },
  { value: "ENGLISH 298 - Nair", label: "ENGLISH 298 - Nair" },
  { value: "ENGLISH 298 - Orr", label: "ENGLISH 298 - Orr" },
  { value: "ENGLISH 315 - Murphy", label: "ENGLISH 315 - Murphy" },
  { value: "ENGLISH 324 - Mattawa", label: "ENGLISH 324 - Mattawa" },
  {
    value: "ENGLISH 344 - Halpert, Askari",
    label: "ENGLISH 344 - Halpert, Askari",
  },
  { value: "ENGLISH 349 - Westlake", label: "ENGLISH 349 - Westlake" },
  { value: "ENGLISH 364 - Byers", label: "ENGLISH 364 - Byers" },
  { value: "ENGLISH 374 - Levy-Hussen", label: "ENGLISH 374 - Levy-Hussen" },
  { value: "ENGLISH 378 - Mattawa", label: "ENGLISH 378 - Mattawa" },
  { value: "ENGLISH 390 - Hartley", label: "ENGLISH 390 - Hartley" },
  { value: "ENGLISH 405 - Moody", label: "ENGLISH 405 - Moody" },
  { value: "ENGLISH 408 - Toon", label: "ENGLISH 408 - Toon" },
  { value: "ENGLISH 410 - Toon", label: "ENGLISH 410 - Toon" },
  { value: "ENGLISH 444 - Hughes", label: "ENGLISH 444 - Hughes" },
  { value: "ENGLISH 450 - Trevor", label: "ENGLISH 450 - Trevor" },
  { value: "ENGLISH 452 - Hack", label: "ENGLISH 452 - Hack" },
  { value: "ENGLISH 461 - Schey", label: "ENGLISH 461 - Schey" },
  { value: "ENGLISH 490 - Bakara", label: "ENGLISH 490 - Bakara" },
  { value: "ENGLISH 503 - Toon", label: "ENGLISH 503 - Toon" },
  { value: "ENGLISH 140 - Nichols", label: "ENGLISH 140 - Nichols" },
  {
    value: "ENGLISH 140 - Portnoy she/her",
    label: "ENGLISH 140 - Portnoy she/her",
  },
  { value: "ENGLISH 140 - Yergeau", label: "ENGLISH 140 - Yergeau" },
  { value: "ENGLISH 227 - Garcia", label: "ENGLISH 227 - Garcia" },
  { value: "ENGLISH 307 - Sweeney", label: "ENGLISH 307 - Sweeney" },
  { value: "ENGLISH 322 - Hinken", label: "ENGLISH 322 - Hinken" },
  { value: "ENGLISH 322 - Delp", label: "ENGLISH 322 - Delp" },
  { value: "ENGLISH 323 - ODowd", label: "ENGLISH 323 - ODowd" },
  { value: "ENGLISH 323 - Byers", label: "ENGLISH 323 - Byers" },
  { value: "ENGLISH 325 - Pinto", label: "ENGLISH 325 - Pinto" },
  { value: "ENGLISH 325 - Swanson", label: "ENGLISH 325 - Swanson" },
  { value: "ENGLISH 325 - Hinken", label: "ENGLISH 325 - Hinken" },
  { value: "ENGLISH 325 - Khleif", label: "ENGLISH 325 - Khleif" },
  { value: "ENGLISH 325 - Beer", label: "ENGLISH 325 - Beer" },
  { value: "ENGLISH 325 - Delp", label: "ENGLISH 325 - Delp" },
  { value: "ENGLISH 325 - Berkley", label: "ENGLISH 325 - Berkley" },
  { value: "ENGLISH 325 - Gold", label: "ENGLISH 325 - Gold" },
  { value: "ENGLISH 325 - Karczynski", label: "ENGLISH 325 - Karczynski" },
  { value: "ENGLISH 325 - Cicciarelli", label: "ENGLISH 325 - Cicciarelli" },
  { value: "ENGLISH 327 - Packard", label: "ENGLISH 327 - Packard" },
  { value: "ENGLISH 340 - White", label: "ENGLISH 340 - White" },
  { value: "ENGLISH 340 - Knuth", label: "ENGLISH 340 - Knuth" },
  { value: "ENGLISH 397 - Pinch", label: "ENGLISH 397 - Pinch" },
  {
    value: "ENGLISH 407 - Kelow-Bennett",
    label: "ENGLISH 407 - Kelow-Bennett",
  },
  { value: "ENGLISH 423 - ODowd", label: "ENGLISH 423 - ODowd" },
  { value: "ENGLISH 423 - Davies", label: "ENGLISH 423 - Davies" },
  { value: "ENGLISH 424 - Harp", label: "ENGLISH 424 - Harp" },
  { value: "ENGLISH 425 - Chamberlin", label: "ENGLISH 425 - Chamberlin" },
  { value: "ENGLISH 460 - Habash", label: "ENGLISH 460 - Habash" },
  { value: "ENGLISH 496 - Pinch", label: "ENGLISH 496 - Pinch" },
  { value: "ENGLISH 508 - Gold", label: "ENGLISH 508 - Gold" },
  { value: "ENGLISH 528 - Kuppers", label: "ENGLISH 528 - Kuppers" },
  {
    value: "ENGLISH 540 - Kelow-Bennett",
    label: "ENGLISH 540 - Kelow-Bennett",
  },
  { value: "ENGLISH 572 - Habash", label: "ENGLISH 572 - Habash" },
  { value: "ENGLISH 575 - Hu", label: "ENGLISH 575 - Hu" },
  { value: "ENGLISH 578 - Faizullah", label: "ENGLISH 578 - Faizullah" },
  { value: "ENGR 100 - Cotel, Sulewski", label: "ENGR 100 - Cotel, Sulewski" },
  { value: "ENGR 100 - Alfano, Lewis", label: "ENGR 100 - Alfano, Lewis" },
  { value: "ENGR 100 - Goldsmith, Zahn", label: "ENGR 100 - Goldsmith, Zahn" },
  {
    value: "ENGR 100 - Albayrak Guralp, Snyder",
    label: "ENGR 100 - Albayrak Guralp, Snyder",
  },
  { value: "ENGR 100 - Ku, Zahn", label: "ENGR 100 - Ku, Zahn" },
  { value: "ENGR 100 - Zhong, Bowden", label: "ENGR 100 - Zhong, Bowden" },
  {
    value: "ENGR 100 - Shih, Himsel-Burcon",
    label: "ENGR 100 - Shih, Himsel-Burcon",
  },
  { value: "ENGR 100 - Bozer, Grimble", label: "ENGR 100 - Bozer, Grimble" },
  {
    value: "ENGR 100 - Mondisa, Vigiletti",
    label: "ENGR 100 - Mondisa, Vigiletti",
  },
  { value: "ENGR 100 - De Roo, Hogg Jr", label: "ENGR 100 - De Roo, Hogg Jr" },
  {
    value: "ENGR 100 - Van Nieuwstadt, Fowler",
    label: "ENGR 100 - Van Nieuwstadt, Fowler",
  },
  { value: "ENGR 101 - Burdick", label: "ENGR 101 - Burdick" },
  { value: "ENGR 101 - Alford", label: "ENGR 101 - Alford" },
  { value: "ENGR 230 - Dailey, Mateju", label: "ENGR 230 - Dailey, Mateju" },
  {
    value: "ENGR 230 - Armstrong-Ceron, Mateju",
    label: "ENGR 230 - Armstrong-Ceron, Mateju",
  },
  { value: "ENGR 260 - Cotel", label: "ENGR 260 - Cotel" },
  { value: "ENGR 301 - Cotel", label: "ENGR 301 - Cotel" },
  { value: "ENGR 301", label: "ENGR 301" },
  {
    value: "ENGR 330 - Edington, Mateju",
    label: "ENGR 330 - Edington, Mateju",
  },
  {
    value: "ENGR 330 - Armstrong-Ceron, Mateju",
    label: "ENGR 330 - Armstrong-Ceron, Mateju",
  },
  { value: "ENGR 371 - Strauss", label: "ENGR 371 - Strauss" },
  { value: "ENGR 410 - Islam", label: "ENGR 410 - Islam" },
  {
    value: "ENGR 430 - Wilson, McCormick, Mateju",
    label: "ENGR 430 - Wilson, McCormick, Mateju",
  },
  { value: "ENGR 430", label: "ENGR 430" },
  {
    value: "ENGR 430 - McCormick, Wilson, Mateju",
    label: "ENGR 430 - McCormick, Wilson, Mateju",
  },
  { value: "ENGR 490 - Millunchick", label: "ENGR 490 - Millunchick" },
  { value: "ENGR 520 - Adriaens", label: "ENGR 520 - Adriaens" },
  { value: "ENGR 591", label: "ENGR 591" },
  { value: "ENGR 599 - Bell IV", label: "ENGR 599 - Bell IV" },
  { value: "ENGR 599 - Yarger", label: "ENGR 599 - Yarger" },
  { value: "ENGR 599 - Strauss", label: "ENGR 599 - Strauss" },
  { value: "ENGR 599 - Wakefield", label: "ENGR 599 - Wakefield" },
  { value: "ENGR 599", label: "ENGR 599" },
  { value: "ENGR 599 - Van Nieuwstadt", label: "ENGR 599 - Van Nieuwstadt" },
  { value: "ENGR 599 - Hohner", label: "ENGR 599 - Hohner" },
  { value: "ENGR 599 - Marino", label: "ENGR 599 - Marino" },
  { value: "ENGR 599 - Siegel", label: "ENGR 599 - Siegel" },
  { value: "ENGR 599 - Gronewold", label: "ENGR 599 - Gronewold" },
  { value: "ENGR 599 - Kruger", label: "ENGR 599 - Kruger" },
  { value: "ENGR 599 - Revzen", label: "ENGR 599 - Revzen" },
  { value: "ENGR 599 - Moore", label: "ENGR 599 - Moore" },
  { value: "ENGR 599 - Hortop", label: "ENGR 599 - Hortop" },
  { value: "ENGR 599 - Renno", label: "ENGR 599 - Renno" },
  { value: "ENGR 599 - Gaskell", label: "ENGR 599 - Gaskell" },
  { value: "ENGR 599 - Hess", label: "ENGR 599 - Hess" },
  { value: "ENGR 599 - Shedden", label: "ENGR 599 - Shedden" },
  { value: "ENGR 599 - Kutty", label: "ENGR 599 - Kutty" },
  { value: "ENGR 599 - Arthur", label: "ENGR 599 - Arthur" },
  { value: "ENGR 599 - Nebeling", label: "ENGR 599 - Nebeling" },
  { value: "ENGR 599 - Najarian", label: "ENGR 599 - Najarian" },
  { value: "ENGR 599 - Middleton", label: "ENGR 599 - Middleton" },
  { value: "ENGR 599 - Hulbert", label: "ENGR 599 - Hulbert" },
  { value: "ENGR 599 - Sienko", label: "ENGR 599 - Sienko" },
  { value: "ENGR 599 - Liang", label: "ENGR 599 - Liang" },
  { value: "ENGR 599 - Barton", label: "ENGR 599 - Barton" },
  { value: "ENGR 599 - Hu", label: "ENGR 599 - Hu" },
  { value: "ENGR 599 - Guo", label: "ENGR 599 - Guo" },
  { value: "ENGR 599 - Green", label: "ENGR 599 - Green" },
  { value: "ENGR 599 - Gilchrist", label: "ENGR 599 - Gilchrist" },
  { value: "ENGR 599 - Flannagan", label: "ENGR 599 - Flannagan" },
  { value: "ENGR 599 - Kasper", label: "ENGR 599 - Kasper" },
  { value: "ENGR 599 - Ojeda", label: "ENGR 599 - Ojeda" },
  { value: "ENGR 599 - Dinov", label: "ENGR 599 - Dinov" },
  { value: "ENGR 599 - Ringenberg", label: "ENGR 599 - Ringenberg" },
  { value: "ENGR 599 - Skerlos", label: "ENGR 599 - Skerlos" },
  { value: "ENS 462", label: "ENS 462" },
  { value: "ENS 480 - King III", label: "ENS 480 - King III" },
  { value: "ENS 480 - Albert", label: "ENS 480 - Albert" },
  { value: "ENS 480 - Dimoff", label: "ENS 480 - Dimoff" },
  { value: "ENS 480 - Eldan", label: "ENS 480 - Eldan" },
  { value: "ENS 480 - Holland", label: "ENS 480 - Holland" },
  { value: "ENS 480 - Antonio", label: "ENS 480 - Antonio" },
  { value: "ENS 480 - Perkins", label: "ENS 480 - Perkins" },
  { value: "ENS 481 - Collier", label: "ENS 481 - Collier" },
  { value: "ENS 540 - Katz", label: "ENS 540 - Katz" },
  { value: "ENS 581 - Collier", label: "ENS 581 - Collier" },
  { value: "ENS 307", label: "ENS 307" },
  { value: "ENS 325", label: "ENS 325" },
  { value: "ENS 326", label: "ENS 326" },
  { value: "ENS 335", label: "ENS 335" },
  { value: "ENS 344", label: "ENS 344" },
  { value: "ENS 345", label: "ENS 345" },
  { value: "ENS 346", label: "ENS 346" },
  { value: "ENS 347", label: "ENS 347" },
  { value: "ENS 348", label: "ENS 348" },
  { value: "ENS 349", label: "ENS 349" },
  { value: "ENS 350", label: "ENS 350" },
  { value: "ENS 351", label: "ENS 351" },
  { value: "ENS 352", label: "ENS 352" },
  { value: "ENS 353", label: "ENS 353" },
  { value: "ENS 354", label: "ENS 354" },
  { value: "ENS 356", label: "ENS 356" },
  { value: "ENS 400", label: "ENS 400" },
  { value: "ENS 407", label: "ENS 407" },
  { value: "ENS 451", label: "ENS 451" },
  { value: "ENS 460", label: "ENS 460" },
  { value: "ENS 461", label: "ENS 461" },
  { value: "ENS 463", label: "ENS 463" },
  { value: "ENS 465", label: "ENS 465" },
  { value: "ENS 466", label: "ENS 466" },
  { value: "ENS 469", label: "ENS 469" },
  { value: "ENS 475", label: "ENS 475" },
  { value: "ENS 525", label: "ENS 525" },
  { value: "ENS 526", label: "ENS 526" },
  { value: "ENS 535", label: "ENS 535" },
  { value: "ENS 545", label: "ENS 545" },
  { value: "ENS 547", label: "ENS 547" },
  { value: "ENS 549", label: "ENS 549" },
  { value: "ENS 550", label: "ENS 550" },
  { value: "ENS 553", label: "ENS 553" },
  { value: "ENS 554", label: "ENS 554" },
  { value: "ENS 556", label: "ENS 556" },
  { value: "ENS 560", label: "ENS 560" },
  { value: "ENS 566", label: "ENS 566" },
  { value: "ENS 569", label: "ENS 569" },
  { value: "ENS 580", label: "ENS 580" },
  { value: "ENSCEN 105 - Flanner", label: "ENSCEN 105 - Flanner" },
  { value: "ENSCEN 211 - Jovanovic", label: "ENSCEN 211 - Jovanovic" },
  { value: "ENSCEN 463 - Samson", label: "ENSCEN 463 - Samson" },
  { value: "ENSCEN 479 - Kort", label: "ENSCEN 479 - Kort" },
  { value: "ENSCEN 533 - Driscoll", label: "ENSCEN 533 - Driscoll" },
  { value: "ENTR 390 - Sinclair", label: "ENTR 390 - Sinclair" },
  { value: "ENTR 390 - Narayan", label: "ENTR 390 - Narayan" },
  { value: "ENTR 390 - Seidl", label: "ENTR 390 - Seidl" },
  {
    value: "ENTR 390 - Wingfield, Moore",
    label: "ENTR 390 - Wingfield, Moore",
  },
  { value: "ENTR 390 - Bailey", label: "ENTR 390 - Bailey" },
  { value: "ENTR 408 - Fidler", label: "ENTR 408 - Fidler" },
  { value: "ENTR 410 - Hayden, McFall", label: "ENTR 410 - Hayden, McFall" },
  { value: "ENTR 411 - Hennessy", label: "ENTR 411 - Hennessy" },
  { value: "ENTR 411 - White", label: "ENTR 411 - White" },
  { value: "ENTR 411 - McNaughton", label: "ENTR 411 - McNaughton" },
  { value: "ENTR 412 - Hennessy", label: "ENTR 412 - Hennessy" },
  { value: "ENTR 413 - Dacko", label: "ENTR 413 - Dacko" },
  { value: "ENTR 490 - Leppek", label: "ENTR 490 - Leppek" },
  { value: "ENTR 490 - Cucinelli, Hsia", label: "ENTR 490 - Cucinelli, Hsia" },
  { value: "ENTR 490 - Dacko", label: "ENTR 490 - Dacko" },
  { value: "ENTR 490 - Sick, Fay", label: "ENTR 490 - Sick, Fay" },
  { value: "ENTR 490 - Huber", label: "ENTR 490 - Huber" },
  { value: "ENTR 500 - Crumm", label: "ENTR 500 - Crumm" },
  { value: "ENTR 510 - Crumm", label: "ENTR 510 - Crumm" },
  { value: "ENTR 550 - Fretz", label: "ENTR 550 - Fretz" },
  { value: "ENTR 560 - Cell", label: "ENTR 560 - Cell" },
  {
    value: "ENTR 599 - Wingfield, Moore",
    label: "ENTR 599 - Wingfield, Moore",
  },
  { value: "ENTR 599 - Cucinelli, Hsia", label: "ENTR 599 - Cucinelli, Hsia" },
  { value: "ENTR 599 - Sick, Fay", label: "ENTR 599 - Sick, Fay" },
  { value: "ENTR 599 - Narayan", label: "ENTR 599 - Narayan" },
  { value: "ENTR 599 - Bacyinski", label: "ENTR 599 - Bacyinski" },
  { value: "ENTR 599 - Fortino", label: "ENTR 599 - Fortino" },
  { value: "ENTR 599 - Hayden, McFall", label: "ENTR 599 - Hayden, McFall" },
  { value: "ENTR 599 - Dacko", label: "ENTR 599 - Dacko" },
  { value: "ENTR 599 - Gordon", label: "ENTR 599 - Gordon" },
  { value: "ENTR 599 - Huber", label: "ENTR 599 - Huber" },
  { value: "ENTR 599 - Bailey", label: "ENTR 599 - Bailey" },
  { value: "ENTR 599 - Fidler", label: "ENTR 599 - Fidler" },
  { value: "ENTR 407 - Bacyinski", label: "ENTR 407 - Bacyinski" },
  { value: "ENVIRON 102 - Samson", label: "ENVIRON 102 - Samson" },
  { value: "ENVIRON 105 - Flanner", label: "ENVIRON 105 - Flanner" },
  { value: "ENVIRON 111 - Arnaboldi", label: "ENVIRON 111 - Arnaboldi" },
  { value: "ENVIRON 112 - Levin", label: "ENVIRON 112 - Levin" },
  { value: "ENVIRON 119 - Lohmann", label: "ENVIRON 119 - Lohmann" },
  { value: "ENVIRON 120 - Gleason", label: "ENVIRON 120 - Gleason" },
  { value: "ENVIRON 131 - Robbins", label: "ENVIRON 131 - Robbins" },
  { value: "ENVIRON 201 - Benedict", label: "ENVIRON 201 - Benedict" },
  { value: "ENVIRON 208 - Soderstrom", label: "ENVIRON 208 - Soderstrom" },
  { value: "ENVIRON 229 - Demers", label: "ENVIRON 229 - Demers" },
  { value: "ENVIRON 230 - Hetland", label: "ENVIRON 230 - Hetland" },
  { value: "ENVIRON 232 - Arbic", label: "ENVIRON 232 - Arbic" },
  { value: "ENVIRON 235 - Moore", label: "ENVIRON 235 - Moore" },
  { value: "ENVIRON 250 - Raimi", label: "ENVIRON 250 - Raimi" },
  { value: "ENVIRON 258 - Brown", label: "ENVIRON 258 - Brown" },
  { value: "ENVIRON 262 - Benedict", label: "ENVIRON 262 - Benedict" },
  { value: "ENVIRON 281 - Sanders", label: "ENVIRON 281 - Sanders" },
  { value: "ENVIRON 302 - Mills", label: "ENVIRON 302 - Mills" },
  { value: "ENVIRON 310 - Colacino", label: "ENVIRON 310 - Colacino" },
  { value: "ENVIRON 321 - Arnott", label: "ENVIRON 321 - Arnott" },
  { value: "ENVIRON 325 - Johnson", label: "ENVIRON 325 - Johnson" },
  { value: "ENVIRON 332 - Passey", label: "ENVIRON 332 - Passey" },
  { value: "ENVIRON 350 - DAnieri", label: "ENVIRON 350 - DAnieri" },
  { value: "ENVIRON 361 - De Young", label: "ENVIRON 361 - De Young" },
  { value: "ENVIRON 370 - Etienne", label: "ENVIRON 370 - Etienne" },
  { value: "ENVIRON 376 - Bouma", label: "ENVIRON 376 - Bouma" },
  { value: "ENVIRON 377 - Balachander", label: "ENVIRON 377 - Balachander" },
  { value: "ENVIRON 380 - Simon", label: "ENVIRON 380 - Simon" },
  { value: "ENVIRON 390 - Murphy", label: "ENVIRON 390 - Murphy" },
  { value: "ENVIRON 391 - Soderstrom", label: "ENVIRON 391 - Soderstrom" },
  { value: "ENVIRON 403 - Niemi", label: "ENVIRON 403 - Niemi" },
  { value: "ENVIRON 407 - Semrau", label: "ENVIRON 407 - Semrau" },
  { value: "ENVIRON 442 - Sheldon", label: "ENVIRON 442 - Sheldon" },
  { value: "ENVIRON 453 - Benedict", label: "ENVIRON 453 - Benedict" },
  { value: "ENVIRON 462 - Princen", label: "ENVIRON 462 - Princen" },
  { value: "ENVIRON 462 - DAnieri", label: "ENVIRON 462 - DAnieri" },
  {
    value: "ENVIRON 464 - Adlerstein-Gonzalez",
    label: "ENVIRON 464 - Adlerstein-Gonzalez",
  },
  { value: "ENVIRON 464 - Phung", label: "ENVIRON 464 - Phung" },
  { value: "ENVIRON 465 - Craig", label: "ENVIRON 465 - Craig" },
  { value: "ENVIRON 465 - Jones", label: "ENVIRON 465 - Jones" },
  { value: "ENVIRON 475 - Hall", label: "ENVIRON 475 - Hall" },
  {
    value: "ENVIRON 476 - Zak, Allgeier",
    label: "ENVIRON 476 - Zak, Allgeier",
  },
  {
    value: "ENVIRON 479 - Cruz Da Silva Castro",
    label: "ENVIRON 479 - Cruz Da Silva Castro",
  },
  { value: "ENVIRON 417 - Hart", label: "ENVIRON 417 - Hart" },
  { value: "ENVIRON 139 - Duvall", label: "ENVIRON 139 - Duvall" },
  { value: "ENVIRON 155 - Klein", label: "ENVIRON 155 - Klein" },
  {
    value: "ENVIRON 320 - Halpert, Askari",
    label: "ENVIRON 320 - Halpert, Askari",
  },
  { value: "ENVIRON 337 - Goodenough", label: "ENVIRON 337 - Goodenough" },
  { value: "ENVIRON 399 - Duvall", label: "ENVIRON 399 - Duvall" },
  { value: "ENVIRON 464 - Rife", label: "ENVIRON 464 - Rife" },
  { value: "ENVIRON 326", label: "ENVIRON 326" },
  { value: "ENVIRON 372", label: "ENVIRON 372" },
  { value: "ENVIRON 409", label: "ENVIRON 409" },
  { value: "ENVIRON 438 - Foufopoulos", label: "ENVIRON 438 - Foufopoulos" },
  { value: "ENVIRON 441 - Bergen", label: "ENVIRON 441 - Bergen" },
  { value: "ENVIRON 411", label: "ENVIRON 411" },
  { value: "EPID 460 - Rickard", label: "EPID 460 - Rickard" },
  { value: "EPID 513 - Yang", label: "EPID 513 - Yang" },
  { value: "EPID 521 - Larson", label: "EPID 521 - Larson" },
  { value: "EPID 525 - Robinson-Dunn", label: "EPID 525 - Robinson-Dunn" },
  { value: "EPID 531 - August", label: "EPID 531 - August" },
  { value: "EPID 560 - Rickard", label: "EPID 560 - Rickard" },
  { value: "EPID 582 - Foxman", label: "EPID 582 - Foxman" },
  { value: "EPID 594 - Zelner", label: "EPID 594 - Zelner" },
  { value: "EPID 595 - Berrocal", label: "EPID 595 - Berrocal" },
  { value: "EPID 599 - Tsodikov", label: "EPID 599 - Tsodikov" },
  { value: "ES 310 - Sinclair", label: "ES 310 - Sinclair" },
  { value: "ES 212 - Price", label: "ES 212 - Price" },
  { value: "ES 212 - Johnson", label: "ES 212 - Johnson" },
  { value: "ES 250 - Menon", label: "ES 250 - Menon" },
  { value: "ES 395 - Middleton", label: "ES 395 - Middleton" },
  { value: "ES 401 - Gordon", label: "ES 401 - Gordon" },
  { value: "ES 402 - Gordon", label: "ES 402 - Gordon" },
  { value: "ES 414 - Botkin", label: "ES 414 - Botkin" },
  { value: "ES 425 - Sriram", label: "ES 425 - Sriram" },
  { value: "ES 504 - Schipani", label: "ES 504 - Schipani" },
  { value: "ES 515 - Menon", label: "ES 515 - Menon" },
  { value: "ES 569 - Gupta", label: "ES 569 - Gupta" },
  { value: "ES 403", label: "ES 403" },
  { value: "ESENG 599 - Lastoskie", label: "ESENG 599 - Lastoskie" },
  { value: "ESENG 599 - Craig", label: "ESENG 599 - Craig" },
  { value: "FIN 302 - Lei", label: "FIN 302 - Lei" },
  { value: "FIN 300 - Sandulescu", label: "FIN 300 - Sandulescu" },
  { value: "FIN 300 - Rashidi Ranjbar", label: "FIN 300 - Rashidi Ranjbar" },
  { value: "FIN 300 - Miller", label: "FIN 300 - Miller" },
  { value: "FIN 300 - Hall", label: "FIN 300 - Hall" },
  { value: "FIN 300 - Nezafat", label: "FIN 300 - Nezafat" },
  { value: "FIN 317", label: "FIN 317" },
  { value: "FIN 320 - Allen", label: "FIN 320 - Allen" },
  { value: "FIN 321", label: "FIN 321" },
  { value: "FIN 336 - Nezafat", label: "FIN 336 - Nezafat" },
  { value: "FIN 338 - Dittmar", label: "FIN 338 - Dittmar" },
  { value: "FIN 351 - Cascade", label: "FIN 351 - Cascade" },
  { value: "FIN 402 - Sheinberg", label: "FIN 402 - Sheinberg" },
  { value: "FIN 403 - Sheinberg", label: "FIN 403 - Sheinberg" },
  { value: "FIN 408 - Nezafat", label: "FIN 408 - Nezafat" },
  { value: "FIN 408", label: "FIN 408" },
  { value: "FIN 409 - Lei", label: "FIN 409 - Lei" },
  { value: "FIN 409", label: "FIN 409" },
  { value: "FIN 425", label: "FIN 425" },
  { value: "FIN 428 - Wu", label: "FIN 428 - Wu" },
  { value: "FIN 430 - Yimfor", label: "FIN 430 - Yimfor" },
  { value: "FIN 440 - Nezafat", label: "FIN 440 - Nezafat" },
  { value: "FIN 466 - Soo", label: "FIN 466 - Soo" },
  { value: "FIN 475 - Lei", label: "FIN 475 - Lei" },
  { value: "FIN 480 - Hall", label: "FIN 480 - Hall" },
  { value: "FIN 566 - Soo", label: "FIN 566 - Soo" },
  { value: "FIN 567 - Soo", label: "FIN 567 - Soo" },
  { value: "FIN 575 - Lei", label: "FIN 575 - Lei" },
  { value: "FIN 583 - Bigelow", label: "FIN 583 - Bigelow" },
  { value: "FPIANO 100 - Bengtson", label: "FPIANO 100 - Bengtson" },
  { value: "FRENCH 345 - Cole", label: "FRENCH 345 - Cole" },
  { value: "FRENCH 346 - Lay", label: "FRENCH 346 - Lay" },
  { value: "FRENCH 372 - Boalick", label: "FRENCH 372 - Boalick" },
  { value: "FRENCH 444 - Arnall", label: "FRENCH 444 - Arnall" },
  { value: "FRENCH 101", label: "FRENCH 101" },
  { value: "FRENCH 102", label: "FRENCH 102" },
  { value: "FRENCH 103", label: "FRENCH 103" },
  { value: "FRENCH 113 - Gabaron", label: "FRENCH 113 - Gabaron" },
  { value: "FRENCH 230", label: "FRENCH 230" },
  { value: "FRENCH 231", label: "FRENCH 231" },
  { value: "FRENCH 232", label: "FRENCH 232" },
  { value: "FRENCH 232 - Gabaron", label: "FRENCH 232 - Gabaron" },
  { value: "FRENCH 232 - Foster", label: "FRENCH 232 - Foster" },
  { value: "FRENCH 235 - Meyer", label: "FRENCH 235 - Meyer" },
  { value: "FRENCH 235 - Foster", label: "FRENCH 235 - Foster" },
  { value: "FRENCH 270 - Hannoosh", label: "FRENCH 270 - Hannoosh" },
  {
    value: "FRENCH 270 - Joseph-Gabriel",
    label: "FRENCH 270 - Joseph-Gabriel",
  },
  { value: "FRENCH 272 - Hoffmann", label: "FRENCH 272 - Hoffmann" },
  { value: "FRENCH 272 - Kurtz", label: "FRENCH 272 - Kurtz" },
  { value: "FRENCH 274 - Gabaron", label: "FRENCH 274 - Gabaron" },
  { value: "FRENCH 336 - Hendrickson", label: "FRENCH 336 - Hendrickson" },
  { value: "FRENCH 350 - Caron", label: "FRENCH 350 - Caron" },
  {
    value: "FRENCH 365 - Joseph-Gabriel",
    label: "FRENCH 365 - Joseph-Gabriel",
  },
  { value: "FRENCH 366 - Gutt", label: "FRENCH 366 - Gutt" },
  { value: "FRENCH 379 - Caron", label: "FRENCH 379 - Caron" },
  { value: "FRENCH 360 - Hoffmann", label: "FRENCH 360 - Hoffmann" },
  { value: "FRENHORN 111", label: "FRENHORN 111" },
  { value: "FTVM 150 - Flinn", label: "FTVM 150 - Flinn" },
  { value: "FTVM 203 - Fanucchi", label: "FTVM 203 - Fanucchi" },
  { value: "FTVM 210 - Tice", label: "FTVM 210 - Tice" },
  { value: "FTVM 210 - Prasad", label: "FTVM 210 - Prasad" },
  { value: "FTVM 232 - Poulson-Bryant", label: "FTVM 232 - Poulson-Bryant" },
  { value: "FTVM 272 - Noble-Olson", label: "FTVM 272 - Noble-Olson" },
  { value: "FTVM 290 - McNamara", label: "FTVM 290 - McNamara" },
  { value: "FTVM 290 - Marek", label: "FTVM 290 - Marek" },
  { value: "FTVM 290 - Sarris", label: "FTVM 290 - Sarris" },
  { value: "FTVM 290 - Fanucchi", label: "FTVM 290 - Fanucchi" },
  { value: "FTVM 300 - Rayher", label: "FTVM 300 - Rayher" },
  { value: "FTVM 303 - Rayher", label: "FTVM 303 - Rayher" },
  { value: "FTVM 303 - Marek", label: "FTVM 303 - Marek" },
  { value: "FTVM 306 - McNamara", label: "FTVM 306 - McNamara" },
  { value: "FTVM 310 - Prasad", label: "FTVM 310 - Prasad" },
  { value: "FTVM 310 - Thornton", label: "FTVM 310 - Thornton" },
  { value: "FTVM 311 - Thornton", label: "FTVM 311 - Thornton" },
  { value: "FTVM 317 - Sarris", label: "FTVM 317 - Sarris" },
  { value: "FTVM 352 - Solomon", label: "FTVM 352 - Solomon" },
  { value: "FTVM 366 - Nornes", label: "FTVM 366 - Nornes" },
  { value: "FTVM 366 - Phruksachart", label: "FTVM 366 - Phruksachart" },
  { value: "FTVM 366 - Rebull", label: "FTVM 366 - Rebull" },
  { value: "FTVM 367 - Murphy", label: "FTVM 367 - Murphy" },
  { value: "FTVM 368 - Mihailova", label: "FTVM 368 - Mihailova" },
  { value: "FTVM 394 - Granata", label: "FTVM 394 - Granata" },
  { value: "FTVM 404 - Hollison", label: "FTVM 404 - Hollison" },
  { value: "FTVM 404 - Fanucchi", label: "FTVM 404 - Fanucchi" },
  { value: "FTVM 406 - McNamara", label: "FTVM 406 - McNamara" },
  { value: "FTVM 410 - Shere", label: "FTVM 410 - Shere" },
  { value: "FTVM 411 - Thornton", label: "FTVM 411 - Thornton" },
  { value: "FTVM 427 - Burnstein", label: "FTVM 427 - Burnstein" },
  { value: "FTVM 441 - Rai", label: "FTVM 441 - Rai" },
  { value: "FTVM 441 - Phruksachart", label: "FTVM 441 - Phruksachart" },
  { value: "FTVM 455 - Rai", label: "FTVM 455 - Rai" },
  { value: "FTVM 455 - Herwitz", label: "FTVM 455 - Herwitz" },
  { value: "FTVM 460 - Solomon", label: "FTVM 460 - Solomon" },
  { value: "FTVM 190 - Sarris", label: "FTVM 190 - Sarris" },
  { value: "FTVM 190 - Tsoffar", label: "FTVM 190 - Tsoffar" },
  {
    value: "FTVM 423 - Rayher, Burnstein",
    label: "FTVM 423 - Rayher, Burnstein",
  },
  { value: "FTVM 495 - Hollison", label: "FTVM 495 - Hollison" },
  { value: "FTVM 314", label: "FTVM 314" },
  { value: "FTVM 316", label: "FTVM 316" },
  { value: "FTVM 353", label: "FTVM 353" },
  { value: "FTVM 375", label: "FTVM 375" },
  { value: "FTVM 376", label: "FTVM 376" },
  { value: "GEOG 111 - Arnaboldi", label: "GEOG 111 - Arnaboldi" },
  { value: "GEOG 145 - Tsai", label: "GEOG 145 - Tsai" },
  {
    value: "GERMAN 100 - Cribari-Assali",
    label: "GERMAN 100 - Cribari-Assali",
  },
  { value: "GERMAN 230 - Goertz", label: "GERMAN 230 - Goertz" },
  {
    value: "GERMAN 322 - Spector, Thurman",
    label: "GERMAN 322 - Spector, Thurman",
  },
  { value: "GERMAN 379 - Markovits", label: "GERMAN 379 - Markovits" },
  { value: "GERMAN 386 - Amrine", label: "GERMAN 386 - Amrine" },
  { value: "GERMAN 101", label: "GERMAN 101" },
  { value: "GERMAN 101 - Rastalsky", label: "GERMAN 101 - Rastalsky" },
  { value: "GERMAN 102", label: "GERMAN 102" },
  { value: "GERMAN 112 - Dabak", label: "GERMAN 112 - Dabak" },
  { value: "GERMAN 128 - Luchina", label: "GERMAN 128 - Luchina" },
  { value: "GERMAN 228", label: "GERMAN 228" },
  { value: "GERMAN 228 - Luchina", label: "GERMAN 228 - Luchina" },
  { value: "GERMAN 232 - Dabak", label: "GERMAN 232 - Dabak" },
  { value: "GERMAN 232 - Swennes", label: "GERMAN 232 - Swennes" },
  { value: "GERMAN 232 - Marquardt", label: "GERMAN 232 - Marquardt" },
  {
    value: "GERMAN 232 - Uritescu-Lombard",
    label: "GERMAN 232 - Uritescu-Lombard",
  },
  { value: "GERMAN 232 - Ewing", label: "GERMAN 232 - Ewing" },
  { value: "GERMAN 232 - Grzeskowiak", label: "GERMAN 232 - Grzeskowiak" },
  { value: "GERMAN 232 - Gell", label: "GERMAN 232 - Gell" },
  { value: "GERMAN 234 - Dabak", label: "GERMAN 234 - Dabak" },
  { value: "GERMAN 300 - Gell", label: "GERMAN 300 - Gell" },
  { value: "GERMAN 300 - Swennes", label: "GERMAN 300 - Swennes" },
  { value: "GERMAN 306 - Swennes", label: "GERMAN 306 - Swennes" },
  { value: "GERMAN 306 - Gell", label: "GERMAN 306 - Gell" },
  { value: "GERMAN 326 - Rodena-Krasan", label: "GERMAN 326 - Rodena-Krasan" },
  { value: "GERMAN 326 - Grzeskowiak", label: "GERMAN 326 - Grzeskowiak" },
  { value: "GERMAN 326 - Rastalsky", label: "GERMAN 326 - Rastalsky" },
  { value: "GERMAN 326 - Mills", label: "GERMAN 326 - Mills" },
  { value: "GERMAN 326 - Federhofer", label: "GERMAN 326 - Federhofer" },
  { value: "GERMAN 350 - Federhofer", label: "GERMAN 350 - Federhofer" },
  { value: "GERMAN 402 - Gailus", label: "GERMAN 402 - Gailus" },
  { value: "GERMAN 426 - Rodena-Krasan", label: "GERMAN 426 - Rodena-Krasan" },
  {
    value: "GERMAN 426 - Uritescu-Lombard",
    label: "GERMAN 426 - Uritescu-Lombard",
  },
  { value: "GERMAN 430 - Grzeskowiak", label: "GERMAN 430 - Grzeskowiak" },
  { value: "GERMAN 449 - Dickinson", label: "GERMAN 449 - Dickinson" },
  { value: "GERMAN 449 - Markovits", label: "GERMAN 449 - Markovits" },
  { value: "GERMAN 505 - Toon", label: "GERMAN 505 - Toon" },
  { value: "GERMAN 512 - Dabak", label: "GERMAN 512 - Dabak" },
  { value: "GERMAN 303 - Amrine", label: "GERMAN 303 - Amrine" },
  { value: "GERMAN 303 - Hell", label: "GERMAN 303 - Hell" },
  { value: "GERMAN 303", label: "GERMAN 303" },
  { value: "GERMAN 303 - Luchina", label: "GERMAN 303 - Luchina" },
  { value: "GERMAN 303 - Toman", label: "GERMAN 303 - Toman" },
  { value: "GERMAN 304 - Amrine", label: "GERMAN 304 - Amrine" },
  { value: "GERMAN 312 - Measel", label: "GERMAN 312 - Measel" },
  { value: "GERMAN 358 - Rodena-Krasan", label: "GERMAN 358 - Rodena-Krasan" },
  { value: "GERMAN 389 - Mills", label: "GERMAN 389 - Mills" },
  { value: "GERMAN 396 - Thurman", label: "GERMAN 396 - Thurman" },
  { value: "GERMAN 416 - Hell", label: "GERMAN 416 - Hell" },
  { value: "GERMAN 416 - Ewing", label: "GERMAN 416 - Ewing" },
  {
    value: "GERMAN 416 - Uritescu-Lombard",
    label: "GERMAN 416 - Uritescu-Lombard",
  },
  { value: "GERMAN 470 - Mills", label: "GERMAN 470 - Mills" },
  { value: "GERMAN 492 - McIsaac", label: "GERMAN 492 - McIsaac" },
  { value: "GREEK 102 - Markus", label: "GREEK 102 - Markus" },
  { value: "GREEK 103", label: "GREEK 103" },
  { value: "GREEK 103 - Berlin", label: "GREEK 103 - Berlin" },
  { value: "GREEK 302 - Berlin", label: "GREEK 302 - Berlin" },
  { value: "GREEK 470 - Ahbel-Rappe", label: "GREEK 470 - Ahbel-Rappe" },
  { value: "GREEK 503 - Markus", label: "GREEK 503 - Markus" },
  { value: "GREEK 504", label: "GREEK 504" },
  { value: "GREEK 504 - Berlin", label: "GREEK 504 - Berlin" },
  { value: "GREEK 508 - Berlin", label: "GREEK 508 - Berlin" },
  { value: "GREEK 515 - Foster", label: "GREEK 515 - Foster" },
  { value: "GREEK 524 - Forsdyke", label: "GREEK 524 - Forsdyke" },
  { value: "GREEKMOD 350 - Leontis", label: "GREEKMOD 350 - Leontis" },
  { value: "GREEKMOD 102 - Margomenou", label: "GREEKMOD 102 - Margomenou" },
  { value: "GREEKMOD 202 - Margomenou", label: "GREEKMOD 202 - Margomenou" },
  { value: "GREEKMOD 502 - Margomenou", label: "GREEKMOD 502 - Margomenou" },
  { value: "GREEKMOD 504 - Margomenou", label: "GREEKMOD 504 - Margomenou" },
  { value: "GREEKMOD 375 - Margomenou", label: "GREEKMOD 375 - Margomenou" },
  { value: "GTBOOKS 212 - Aleksic", label: "GTBOOKS 212 - Aleksic" },
  { value: "GUITAR 111 - Edwards", label: "GUITAR 111 - Edwards" },
  { value: "GUITAR 112 - Edwards", label: "GUITAR 112 - Edwards" },
  { value: "HARP 450", label: "HARP 450" },
  { value: "HARP 550", label: "HARP 550" },
  { value: "HBEHED 530 - Schmidt", label: "HBEHED 530 - Schmidt" },
  {
    value: "HBEHED 597 - Zikmund-Fisher",
    label: "HBEHED 597 - Zikmund-Fisher",
  },
  { value: "HEBREW 102", label: "HEBREW 102" },
  { value: "HEBREW 102 - Hareven", label: "HEBREW 102 - Hareven" },
  { value: "HEBREW 201 - Weizman", label: "HEBREW 201 - Weizman" },
  { value: "HEBREW 202", label: "HEBREW 202" },
  { value: "HEBREW 202 - Weizman", label: "HEBREW 202 - Weizman" },
  { value: "HEBREW 202 - Aronson", label: "HEBREW 202 - Aronson" },
  { value: "HEBREW 302 - Eubanks Segal", label: "HEBREW 302 - Eubanks Segal" },
  { value: "HEBREW 206 - Aronson", label: "HEBREW 206 - Aronson" },
  { value: "HEBREW 410 - Raz", label: "HEBREW 410 - Raz" },
  { value: "HISTART 208 - Miller", label: "HISTART 208 - Miller" },
  { value: "HISTART 210 - Biro", label: "HISTART 210 - Biro" },
  { value: "HISTART 215 - Zimmerman", label: "HISTART 215 - Zimmerman" },
  { value: "HISTART 221 - Nevett", label: "HISTART 221 - Nevett" },
  { value: "HISTART 224 - Doris", label: "HISTART 224 - Doris" },
  { value: "HISTART 240 - Sears", label: "HISTART 240 - Sears" },
  {
    value: "HISTART 253 - Mays, Das , Chatterjee",
    label: "HISTART 253 - Mays, Das , Chatterjee",
  },
  { value: "HISTART 272 - Ward", label: "HISTART 272 - Ward" },
  { value: "HISTART 294 - Liu", label: "HISTART 294 - Liu" },
  { value: "HISTART 370 - Lay", label: "HISTART 370 - Lay" },
  { value: "HISTART 382 - Richards", label: "HISTART 382 - Richards" },
  { value: "HISTART 389 - Stone", label: "HISTART 389 - Stone" },
  { value: "HISTART 392 - Carr", label: "HISTART 392 - Carr" },
  { value: "HISTART 394 - McMahon", label: "HISTART 394 - McMahon" },
  { value: "HISTART 394 - Chatterjee", label: "HISTART 394 - Chatterjee" },
  { value: "HISTART 394 - Miller", label: "HISTART 394 - Miller" },
  { value: "HISTART 489 - Toman", label: "HISTART 489 - Toman" },
  { value: "HISTART 194 - Timmermann", label: "HISTART 194 - Timmermann" },
  { value: "HISTART 253 - Chatterjee", label: "HISTART 253 - Chatterjee" },
  { value: "HISTART 362 - Doris", label: "HISTART 362 - Doris" },
  { value: "HISTART 371 - Biro", label: "HISTART 371 - Biro" },
  { value: "HISTART 393 - Gear", label: "HISTART 393 - Gear" },
  { value: "HISTART 393 - Miller", label: "HISTART 393 - Miller" },
  { value: "HISTART 393 - Ward", label: "HISTART 393 - Ward" },
  { value: "HISTART 393 - McMahon", label: "HISTART 393 - McMahon" },
  { value: "HISTART 393 - Liu", label: "HISTART 393 - Liu" },
  { value: "HISTART 393 - Crasnow", label: "HISTART 393 - Crasnow" },
  { value: "HISTART 466 - Carr", label: "HISTART 466 - Carr" },
  { value: "HISTART 497 - Rozas-Krause", label: "HISTART 497 - Rozas-Krause" },
  { value: "HISTART 497 - Rife", label: "HISTART 497 - Rife" },
  { value: "HISTART 497 - Miller", label: "HISTART 497 - Miller" },
  { value: "HISTART 499 - Lay", label: "HISTART 499 - Lay" },
  { value: "HISTART 577 - Chanchani", label: "HISTART 577 - Chanchani" },
  {
    value: "HISTORY 102 - Kivelson, Spector",
    label: "HISTORY 102 - Kivelson, Spector",
  },
  { value: "HISTORY 201 - Dewitt", label: "HISTORY 201 - Dewitt" },
  { value: "HISTORY 205 - Cassel", label: "HISTORY 205 - Cassel" },
  { value: "HISTORY 211 - Fancy", label: "HISTORY 211 - Fancy" },
  { value: "HISTORY 217 - Ballinger", label: "HISTORY 217 - Ballinger" },
  { value: "HISTORY 222 - Neis", label: "HISTORY 222 - Neis" },
  { value: "HISTORY 229 - Frye", label: "HISTORY 229 - Frye" },
  { value: "HISTORY 230 - Akiboh", label: "HISTORY 230 - Akiboh" },
  { value: "HISTORY 230 - Peterson", label: "HISTORY 230 - Peterson" },
  { value: "HISTORY 230 - Pinsker", label: "HISTORY 230 - Pinsker" },
  { value: "HISTORY 230 - Poteet", label: "HISTORY 230 - Poteet" },
  { value: "HISTORY 230 - Knysh", label: "HISTORY 230 - Knysh" },
  { value: "HISTORY 230 - Tonomura", label: "HISTORY 230 - Tonomura" },
  { value: "HISTORY 230 - Wollenberg", label: "HISTORY 230 - Wollenberg" },
  { value: "HISTORY 230 - Roby", label: "HISTORY 230 - Roby" },
  { value: "HISTORY 240 - Sinha", label: "HISTORY 240 - Sinha" },
  { value: "HISTORY 244 - Lieberman", label: "HISTORY 244 - Lieberman" },
  { value: "HISTORY 247 - Peterson", label: "HISTORY 247 - Peterson" },
  { value: "HISTORY 248 - de la Cruz", label: "HISTORY 248 - de la Cruz" },
  { value: "HISTORY 254 - Marwil", label: "HISTORY 254 - Marwil" },
  { value: "HISTORY 255 - Sinha", label: "HISTORY 255 - Sinha" },
  { value: "HISTORY 261 - Brick", label: "HISTORY 261 - Brick" },
  { value: "HISTORY 262 - Berrey", label: "HISTORY 262 - Berrey" },
  { value: "HISTORY 265 - Cowles", label: "HISTORY 265 - Cowles" },
  { value: "HISTORY 269 - Neis", label: "HISTORY 269 - Neis" },
  { value: "HISTORY 272 - Countryman", label: "HISTORY 272 - Countryman" },
  { value: "HISTORY 285 - Carson", label: "HISTORY 285 - Carson" },
  { value: "HISTORY 287 - Mills", label: "HISTORY 287 - Mills" },
  { value: "HISTORY 304 - Crisostomo", label: "HISTORY 304 - Crisostomo" },
  { value: "HISTORY 304 - Beckman", label: "HISTORY 304 - Beckman" },
  { value: "HISTORY 307 - Cipa", label: "HISTORY 307 - Cipa" },
  { value: "HISTORY 314 - Cole", label: "HISTORY 314 - Cole" },
  { value: "HISTORY 319 - Folland", label: "HISTORY 319 - Folland" },
  { value: "HISTORY 321 - Israel", label: "HISTORY 321 - Israel" },
  {
    value: "HISTORY 322 - Spector, Thurman",
    label: "HISTORY 322 - Spector, Thurman",
  },
  { value: "HISTORY 325 - Mir", label: "HISTORY 325 - Mir" },
  { value: "HISTORY 326 - Gaggio", label: "HISTORY 326 - Gaggio" },
  { value: "HISTORY 328 - Beltran", label: "HISTORY 328 - Beltran" },
  { value: "HISTORY 328 - Fajardo", label: "HISTORY 328 - Fajardo" },
  { value: "HISTORY 328 - Suny", label: "HISTORY 328 - Suny" },
  { value: "HISTORY 328 - Hussein", label: "HISTORY 328 - Hussein" },
  { value: "HISTORY 328 - Wollenberg", label: "HISTORY 328 - Wollenberg" },
  { value: "HISTORY 328 - Sekeryan", label: "HISTORY 328 - Sekeryan" },
  { value: "HISTORY 328 - Roby", label: "HISTORY 328 - Roby" },
  { value: "HISTORY 328 - Knysh", label: "HISTORY 328 - Knysh" },
  { value: "HISTORY 328 - Witgen", label: "HISTORY 328 - Witgen" },
  { value: "HISTORY 329 - Krinitsky", label: "HISTORY 329 - Krinitsky" },
  { value: "HISTORY 333 - Maiorova", label: "HISTORY 333 - Maiorova" },
  { value: "HISTORY 337 - Simmons", label: "HISTORY 337 - Simmons" },
  { value: "HISTORY 348 - Caulfield", label: "HISTORY 348 - Caulfield" },
  { value: "HISTORY 352 - Chang", label: "HISTORY 352 - Chang" },
  { value: "HISTORY 357 - Jimenez", label: "HISTORY 357 - Jimenez" },
  { value: "HISTORY 358 - Moyer", label: "HISTORY 358 - Moyer" },
  { value: "HISTORY 370 - Jones", label: "HISTORY 370 - Jones" },
  { value: "HISTORY 376 - Kazanjian", label: "HISTORY 376 - Kazanjian" },
  { value: "HISTORY 397 - Babayan", label: "HISTORY 397 - Babayan" },
  { value: "HISTORY 401 - Moyer", label: "HISTORY 401 - Moyer" },
  { value: "HISTORY 434 - Suny", label: "HISTORY 434 - Suny" },
  { value: "HISTORY 443 - Cole", label: "HISTORY 443 - Cole" },
  { value: "HISTORY 445 - Hawes", label: "HISTORY 445 - Hawes" },
  { value: "HISTORY 446 - Chang", label: "HISTORY 446 - Chang" },
  { value: "HISTORY 449 - Crisostomo", label: "HISTORY 449 - Crisostomo" },
  { value: "HISTORY 454 - Lawsin", label: "HISTORY 454 - Lawsin" },
  { value: "HISTORY 468 - Jones", label: "HISTORY 468 - Jones" },
  { value: "SPANISH 439 - Fridman", label: "SPANISH 439 - Fridman" },
  { value: "SPANISH 440 - Russo", label: "SPANISH 440 - Russo" },
  {
    value: "SPANISH 443 - Villalobos Ruminott",
    label: "SPANISH 443 - Villalobos Ruminott",
  },
  { value: "SPANISH 445 - Arnall", label: "SPANISH 445 - Arnall" },
  { value: "SPANISH 450 - Szpiech", label: "SPANISH 450 - Szpiech" },
  { value: "SPANISH 473 - Verdesio", label: "SPANISH 473 - Verdesio" },
  { value: "SPANISH 474 - Ferrari", label: "SPANISH 474 - Ferrari" },
  { value: "SPANISH 474 - Sanjines", label: "SPANISH 474 - Sanjines" },
  { value: "SPANISH 480 - Costa Curras", label: "SPANISH 480 - Costa Curras" },
  { value: "SPANISH 485 - Verdesio", label: "SPANISH 485 - Verdesio" },
  { value: "SPANISH 487 - Henriksen", label: "SPANISH 487 - Henriksen" },
  { value: "SPANISH 487 - Satterfield", label: "SPANISH 487 - Satterfield" },
  { value: "SPANISH 488 - Williams", label: "SPANISH 488 - Williams" },
  { value: "STATS 206", label: "STATS 206" },
  { value: "STATS 250", label: "STATS 250" },
  { value: "STATS 306", label: "STATS 306" },
  { value: "STATS 401", label: "STATS 401" },
  { value: "STATS 406", label: "STATS 406" },
  { value: "STATS 412 - Fink", label: "STATS 412 - Fink" },
  { value: "STATS 412 - Miller", label: "STATS 412 - Miller" },
  { value: "STATS 413", label: "STATS 413" },
  { value: "STATS 415", label: "STATS 415" },
  { value: "STATS 425 - Fink", label: "STATS 425 - Fink" },
  { value: "STATS 425 - Green", label: "STATS 425 - Green" },
  { value: "STATS 425 - Li", label: "STATS 425 - Li" },
  { value: "STATS 425 - Rome", label: "STATS 425 - Rome" },
  { value: "STATS 426", label: "STATS 426" },
  { value: "STATS 430", label: "STATS 430" },
  { value: "STATS 449", label: "STATS 449" },
  { value: "STATS 480", label: "STATS 480" },
  { value: "STATS 485", label: "STATS 485" },
  { value: "STRATEGY 290 - Chung", label: "STRATEGY 290 - Chung" },
  { value: "STRATEGY 290 - Lee", label: "STRATEGY 290 - Lee" },
  { value: "STRATEGY 290 - Nagarajan", label: "STRATEGY 290 - Nagarajan" },
  { value: "STRATEGY 290 - Penner-Hahn", label: "STRATEGY 290 - Penner-Hahn" },
  { value: "STRATEGY 290 - Sanz", label: "STRATEGY 290 - Sanz" },
  { value: "STRATEGY 310 - Wan", label: "STRATEGY 310 - Wan" },
  { value: "STRATEGY 444 - Gordon", label: "STRATEGY 444 - Gordon" },
  { value: "TCHNCLCM 300 - Derbesy", label: "TCHNCLCM 300 - Derbesy" },
  { value: "TCHNCLCM 300 - Drake", label: "TCHNCLCM 300 - Drake" },
  { value: "TCHNCLCM 300 - Fishstrom", label: "TCHNCLCM 300 - Fishstrom" },
  { value: "TCHNCLCM 300 - Hanson", label: "TCHNCLCM 300 - Hanson" },
  { value: "TCHNCLCM 300 - Lewis", label: "TCHNCLCM 300 - Lewis" },
  { value: "TCHNCLCM 300 - Montgomery", label: "TCHNCLCM 300 - Montgomery" },
  { value: "TCHNCLCM 300 - Pavlov", label: "TCHNCLCM 300 - Pavlov" },
  { value: "TCHNCLCM 300 - Snyder", label: "TCHNCLCM 300 - Snyder" },
  { value: "TCHNCLCM 300 - Springsteen", label: "TCHNCLCM 300 - Springsteen" },
  {
    value: "TCHNCLCM 380 - Himsel-Burcon",
    label: "TCHNCLCM 380 - Himsel-Burcon",
  },
  { value: "TCHNCLCM 380 - Royston", label: "TCHNCLCM 380 - Royston" },
  { value: "TCHNCLCM 380 - Wisniewski", label: "TCHNCLCM 380 - Wisniewski" },
  { value: "TCHNCLCM 496 - Casper", label: "TCHNCLCM 496 - Casper" },
  { value: "TCHNCLCM 496 - Fowler", label: "TCHNCLCM 496 - Fowler" },
  { value: "TCHNCLCM 496 - Khan", label: "TCHNCLCM 496 - Khan" },
  { value: "TCHNCLCM 496 - Pavlov", label: "TCHNCLCM 496 - Pavlov" },
  { value: "TCHNCLCM 496 - Royston", label: "TCHNCLCM 496 - Royston" },
  { value: "TCHNCLCM 497 - Alfano", label: "TCHNCLCM 497 - Alfano" },
  { value: "TCHNCLCM 497 - Hogg Jr", label: "TCHNCLCM 497 - Hogg Jr" },
  { value: "TCHNCLCM 497 - Jensen", label: "TCHNCLCM 497 - Jensen" },
  { value: "TCHNCLCM 497 - Montgomery", label: "TCHNCLCM 497 - Montgomery" },
  { value: "TCHNCLCM 497 - Sheffield", label: "TCHNCLCM 497 - Sheffield" },
  { value: "TCHNCLCM 497 - Vigiletti", label: "TCHNCLCM 497 - Vigiletti" },
  { value: "THEORY 150", label: "THEORY 150" },
  { value: "THEORY 150 - Arthur", label: "THEORY 150 - Arthur" },
  { value: "THEORY 150 - Devries", label: "THEORY 150 - Devries" },
  { value: "THEORY 150 - Hall", label: "THEORY 150 - Hall" },
  { value: "THEORY 150 - Hannaford", label: "THEORY 150 - Hannaford" },
  { value: "THEORY 150 - Rusch", label: "THEORY 150 - Rusch" },
  { value: "THEORY 150 - Vojcic", label: "THEORY 150 - Vojcic" },
  { value: "THEORY 212 - Tran", label: "THEORY 212 - Tran" },
  { value: "THEORY 236 - Fournier", label: "THEORY 236 - Fournier" },
  { value: "THEORY 238 - Mukherji", label: "THEORY 238 - Mukherji" },
  { value: "THEORY 239 - Petty", label: "THEORY 239 - Petty" },
  { value: "THEORY 250 - Knoedler", label: "THEORY 250 - Knoedler" },
  { value: "THEORY 430 - Martin", label: "THEORY 430 - Martin" },
  { value: "THEORY 433 - Vojcic", label: "THEORY 433 - Vojcic" },
  { value: "THEORY 443 - Satyendra", label: "THEORY 443 - Satyendra" },
  { value: "THEORY 460 - Hannaford", label: "THEORY 460 - Hannaford" },
  { value: "THEORY 460 - Mukherji", label: "THEORY 460 - Mukherji" },
  { value: "THEORY 461 - Korsyn", label: "THEORY 461 - Korsyn" },
  { value: "THTREMUS 101 - Gomez", label: "THTREMUS 101 - Gomez" },
  { value: "THTREMUS 102 - Traister", label: "THTREMUS 102 - Traister" },
  { value: "THTREMUS 110 - Maylie", label: "THTREMUS 110 - Maylie" },
  { value: "THTREMUS 182 - Cantor", label: "THTREMUS 182 - Cantor" },
  { value: "THTREMUS 182 - Packard", label: "THTREMUS 182 - Packard" },
  { value: "THTREMUS 211 - Westlake", label: "THTREMUS 211 - Westlake" },
  { value: "THTREMUS 227 - Garcia", label: "THTREMUS 227 - Garcia" },
  { value: "THTREMUS 233", label: "THTREMUS 233" },
  { value: "THTREMUS 240 - Murphy", label: "THTREMUS 240 - Murphy" },
  { value: "THTREMUS 245 - Uffner", label: "THTREMUS 245 - Uffner" },
  { value: "THTREMUS 250 - Decker", label: "THTREMUS 250 - Decker" },
  { value: "THTREMUS 256 - Murphy", label: "THTREMUS 256 - Murphy" },
  { value: "THTREMUS 271 - Kassabova", label: "THTREMUS 271 - Kassabova" },
  { value: "THTREMUS 274 - Traister", label: "THTREMUS 274 - Traister" },
  { value: "THTREMUS 285", label: "THTREMUS 285" },
  { value: "THTREMUS 291 - Moreland", label: "THTREMUS 291 - Moreland" },
  { value: "THTREMUS 322 - Hughes", label: "THTREMUS 322 - Hughes" },
  { value: "THTREMUS 323 - Westlake", label: "THTREMUS 323 - Westlake" },
  { value: "THTREMUS 325 - Hooker", label: "THTREMUS 325 - Hooker" },
  { value: "THTREMUS 327 - Packard", label: "THTREMUS 327 - Packard" },
  { value: "THTREMUS 328", label: "THTREMUS 328" },
  { value: "THTREMUS 345", label: "THTREMUS 345" },
  { value: "THTREMUS 350 - Lindsay", label: "THTREMUS 350 - Lindsay" },
  { value: "THTREMUS 351", label: "THTREMUS 351" },
  { value: "THTREMUS 352", label: "THTREMUS 352" },
  { value: "THTREMUS 360 - Judge", label: "THTREMUS 360 - Judge" },
  { value: "THTREMUS 370 - Myers", label: "THTREMUS 370 - Myers" },
  { value: "THTREMUS 371 - Tulip", label: "THTREMUS 371 - Tulip" },
  { value: "THTREMUS 385 - Cuyler", label: "THTREMUS 385 - Cuyler" },
  { value: "THTREMUS 386", label: "THTREMUS 386" },
  { value: "THTREMUS 387", label: "THTREMUS 387" },
  { value: "THTREMUS 388", label: "THTREMUS 388" },
  { value: "THTREMUS 395 - Moreland", label: "THTREMUS 395 - Moreland" },
  { value: "THTREMUS 399", label: "THTREMUS 399" },
  { value: "THTREMUS 399 - Cantor", label: "THTREMUS 399 - Cantor" },
  { value: "THTREMUS 399 - Casas", label: "THTREMUS 399 - Casas" },
  { value: "THTREMUS 399 - Eaton", label: "THTREMUS 399 - Eaton" },
  { value: "THTREMUS 399 - Hooker", label: "THTREMUS 399 - Hooker" },
  { value: "THTREMUS 399 - Mendeloff", label: "THTREMUS 399 - Mendeloff" },
  { value: "THTREMUS 399 - Oliver", label: "THTREMUS 399 - Oliver" },
  { value: "THTREMUS 399 - Traister", label: "THTREMUS 399 - Traister" },
  { value: "THTREMUS 403 - Myers", label: "THTREMUS 403 - Myers" },
  { value: "THTREMUS 404 - Myers", label: "THTREMUS 404 - Myers" },
  { value: "THTREMUS 426 - Cuyler", label: "THTREMUS 426 - Cuyler" },
  { value: "THTREMUS 429 - Casas", label: "THTREMUS 429 - Casas" },
  { value: "THTREMUS 438 - Cuyler", label: "THTREMUS 438 - Cuyler" },
  { value: "THTREMUS 443 - Tulip", label: "THTREMUS 443 - Tulip" },
  { value: "THTREMUS 445", label: "THTREMUS 445" },
  { value: "THTREMUS 446 - Uffner", label: "THTREMUS 446 - Uffner" },
  { value: "THTREMUS 451", label: "THTREMUS 451" },
  { value: "THTREMUS 461", label: "THTREMUS 461" },
  { value: "THTREMUS 463 - Judge", label: "THTREMUS 463 - Judge" },
  { value: "THTREMUS 464", label: "THTREMUS 464" },
  { value: "THTREMUS 470", label: "THTREMUS 470" },
  {
    value: "THTREMUS 482 - Packard, Lindsay",
    label: "THTREMUS 482 - Packard, Lindsay",
  },
  { value: "THTREMUS 495", label: "THTREMUS 495" },
  { value: "TO 300 - Melville", label: "TO 300 - Melville" },
  { value: "TO 300 - Pandiarajan", label: "TO 300 - Pandiarajan" },
  { value: "TO 302", label: "TO 302" },
  { value: "TO 302 - Roy", label: "TO 302 - Roy" },
  { value: "TO 411 - Begen", label: "TO 411 - Begen" },
  { value: "TO 411 - Etzion", label: "TO 411 - Etzion" },
  { value: "TO 412 - Etzion", label: "TO 412 - Etzion" },
  { value: "TO 414 - Kumar", label: "TO 414 - Kumar" },
  { value: "TO 420", label: "TO 420" },
  { value: "TO 421 - Roy", label: "TO 421 - Roy" },
  { value: "TO 428 - Wu", label: "TO 428 - Wu" },
  { value: "TO 433 - Kumar", label: "TO 433 - Kumar" },
  { value: "TO 435 - Leider", label: "TO 435 - Leider" },
  { value: "TO 448 - Svaan", label: "TO 448 - Svaan" },
  { value: "TO 465 - Nelson", label: "TO 465 - Nelson" },
  { value: "TO 470 - Jasin", label: "TO 470 - Jasin" },
  { value: "TURKISH 102 - Sevinc", label: "TURKISH 102 - Sevinc" },
  { value: "TURKISH 202 - Sevinc", label: "TURKISH 202 - Sevinc" },
  { value: "UARTS 250", label: "UARTS 250" },
  { value: "UARTS 460", label: "UARTS 460" },
  { value: "UC 101 - Nguyen", label: "UC 101 - Nguyen" },
  { value: "UC 110 - Wallace", label: "UC 110 - Wallace" },
  { value: "UC 151 - Joiner", label: "UC 151 - Joiner" },
  { value: "UC 202 - Penry", label: "UC 202 - Penry" },
  { value: "UC 204 - Moreno, Groller", label: "UC 204 - Moreno, Groller" },
  { value: "UC 214 - Johnson", label: "UC 214 - Johnson" },
  { value: "UC 214 - Price", label: "UC 214 - Price" },
  { value: "UC 252 - Flint", label: "UC 252 - Flint" },
  { value: "UC 280 - Ferrez", label: "UC 280 - Ferrez" },
  { value: "UC 280 - Knox", label: "UC 280 - Knox" },
  { value: "UC 302", label: "UC 302" },
  { value: "UC 310 - Kantaris", label: "UC 310 - Kantaris" },
  { value: "UC 380 - Nemtanu", label: "UC 380 - Nemtanu" },
  { value: "UC 390 - Espinoza", label: "UC 390 - Espinoza" },
  { value: "UC 402", label: "UC 402" },
  { value: "UC 402 - Dye, Moreno", label: "UC 402 - Dye, Moreno" },
  { value: "UC 403", label: "UC 403" },
  { value: "UC 410 - Kantaris", label: "UC 410 - Kantaris" },
  { value: "UKR 152 - Bondarenko", label: "UKR 152 - Bondarenko" },
  { value: "UKR 252 - Bondarenko", label: "UKR 252 - Bondarenko" },
  { value: "UKR 352 - Rogovyk", label: "UKR 352 - Rogovyk" },
  { value: "URP 423 - Etienne", label: "URP 423 - Etienne" },
  { value: "URP 425 - DAnieri", label: "URP 425 - DAnieri" },
  { value: "VIOLA 450", label: "VIOLA 450" },
  { value: "VIOLIN 450", label: "VIOLIN 450" },
  { value: "VIOLIN 460 - Schotten", label: "VIOLIN 460 - Schotten" },
  { value: "VOICE 111 - Grosvenor", label: "VOICE 111 - Grosvenor" },
  { value: "VOICE 111 - Worrell", label: "VOICE 111 - Worrell" },
  { value: "VOICELIT 202 - Cheek", label: "VOICELIT 202 - Cheek" },
  {
    value: "VOICELIT 203 - Rusche, Cheek",
    label: "VOICELIT 203 - Rusche, Cheek",
  },
  { value: "VOICELIT 410", label: "VOICELIT 410" },
  { value: "VOICELIT 450 - Herseth", label: "VOICELIT 450 - Herseth" },
  { value: "VOICELIT 460 - Olsen", label: "VOICELIT 460 - Olsen" },
  { value: "VOICELIT 460 - Severtson", label: "VOICELIT 460 - Severtson" },
  { value: "VOICELIT 491 - Katz", label: "VOICELIT 491 - Katz" },
  { value: "WELLNESS 200 - Savvidou", label: "WELLNESS 200 - Savvidou" },
  { value: "WELLNESS 220 - Obrecht", label: "WELLNESS 220 - Obrecht" },
  { value: "WELLNESS 412 - Matuza", label: "WELLNESS 412 - Matuza" },
  { value: "WGS 150 - Aristarkhova", label: "WGS 150 - Aristarkhova" },
  { value: "WGS 150 - Lawsin", label: "WGS 150 - Lawsin" },
  { value: "WGS 150 - Tsoffar", label: "WGS 150 - Tsoffar" },
  { value: "WGS 213 - Tonomura", label: "WGS 213 - Tonomura" },
  { value: "WGS 213 - Zemgulys", label: "WGS 213 - Zemgulys" },
  { value: "WGS 214 - Ensor", label: "WGS 214 - Ensor" },
  { value: "WGS 220 - Gabriel", label: "WGS 220 - Gabriel" },
  { value: "WGS 224 - Neis", label: "WGS 224 - Neis" },
  { value: "WGS 235 - Cable", label: "WGS 235 - Cable" },
  { value: "WGS 240 - Dumes", label: "WGS 240 - Dumes" },
  { value: "WGS 243 - Calvo-Quiros", label: "WGS 243 - Calvo-Quiros" },
  { value: "WGS 265 - Bolivar", label: "WGS 265 - Bolivar" },
  { value: "WGS 270 - Peterson", label: "WGS 270 - Peterson" },
  { value: "WGS 291", label: "WGS 291" },
  { value: "WGS 293 - Mendoza", label: "WGS 293 - Mendoza" },
  { value: "WGS 311 - Alexy", label: "WGS 311 - Alexy" },
  { value: "WGS 313 - Dhar", label: "WGS 313 - Dhar" },
  { value: "WGS 314 - Kolenda-Mason", label: "WGS 314 - Kolenda-Mason" },
  { value: "WGS 315 - Murphy", label: "WGS 315 - Murphy" },
  { value: "WGS 319 - Simmons", label: "WGS 319 - Simmons" },
  { value: "WGS 330 - Dhar", label: "WGS 330 - Dhar" },
  { value: "WGS 330 - Mendoza", label: "WGS 330 - Mendoza" },
  { value: "WGS 331 - Gabriel", label: "WGS 331 - Gabriel" },
  { value: "WGS 337 - Simmons", label: "WGS 337 - Simmons" },
  { value: "WGS 341 - Eubanks Segal", label: "WGS 341 - Eubanks Segal" },
  { value: "WGS 343 - Karem Albrecht", label: "WGS 343 - Karem Albrecht" },
  { value: "WGS 344 - Andre, Brissey", label: "WGS 344 - Andre, Brissey" },
  { value: "WGS 345 - Ali", label: "WGS 345 - Ali" },
  { value: "WGS 345 - Dhar", label: "WGS 345 - Dhar" },
  { value: "WGS 347 - Sweeney", label: "WGS 347 - Sweeney" },
  { value: "WGS 351 - Welch", label: "WGS 351 - Welch" },
  { value: "WGS 363 - Lawsin", label: "WGS 363 - Lawsin" },
  { value: "WGS 370 - Jones", label: "WGS 370 - Jones" },
  { value: "WGS 389 - Cole", label: "WGS 389 - Cole" },
  { value: "WGS 397 - Babayan", label: "WGS 397 - Babayan" },
  { value: "WGS 400 - Johnson, Harris", label: "WGS 400 - Johnson, Harris" },
  { value: "WGS 410 - Peterson", label: "WGS 410 - Peterson" },
  { value: "WGS 411 - Hubbs", label: "WGS 411 - Hubbs" },
  { value: "WGS 431 - Beischel", label: "WGS 431 - Beischel" },
  { value: "WGS 433 - Kelow-Bennett", label: "WGS 433 - Kelow-Bennett" },
  { value: "WGS 434 - Brissey", label: "WGS 434 - Brissey" },
  { value: "WGS 435 - Jackson", label: "WGS 435 - Jackson" },
  { value: "WGS 435 - Merrill", label: "WGS 435 - Merrill" },
  { value: "WGS 435 - Rai", label: "WGS 435 - Rai" },
  { value: "WGS 435 - Saluk", label: "WGS 435 - Saluk" },
  { value: "WGS 442 - McClelland", label: "WGS 442 - McClelland" },
  { value: "WGS 447 - McGann", label: "WGS 447 - McGann" },
  { value: "WGS 451 - Smock", label: "WGS 451 - Smock" },
  { value: "WGS 483 - Cortina", label: "WGS 483 - Cortina" },
  { value: "WRITING 100", label: "WRITING 100" },
  { value: "WRITING 100 - Conway", label: "WRITING 100 - Conway" },
  { value: "WRITING 200 - Babcock", label: "WRITING 200 - Babcock" },
  { value: "WRITING 200 - Cassel", label: "WRITING 200 - Cassel" },
  { value: "WRITING 200 - Hetzel", label: "WRITING 200 - Hetzel" },
  { value: "WRITING 200 - Li", label: "WRITING 200 - Li" },
  { value: "WRITING 201 - Beal", label: "WRITING 201 - Beal" },
  { value: "WRITING 201 - Karczynski", label: "WRITING 201 - Karczynski" },
  { value: "WRITING 201 - Tell", label: "WRITING 201 - Tell" },
  { value: "WRITING 220 - Brancho", label: "WRITING 220 - Brancho" },
  { value: "WRITING 220 - Hetzel", label: "WRITING 220 - Hetzel" },
  { value: "WRITING 229 - Li", label: "WRITING 229 - Li" },
  { value: "WRITING 300 - Sessolo", label: "WRITING 300 - Sessolo" },
  { value: "WRITING 305 - Cassel", label: "WRITING 305 - Cassel" },
  { value: "WRITING 305 - Sano", label: "WRITING 305 - Sano" },
  { value: "WRITING 340 - Li", label: "WRITING 340 - Li" },
  { value: "WRITING 350 - Cicciarelli", label: "WRITING 350 - Cicciarelli" },
  { value: "WRITING 420 - Babcock", label: "WRITING 420 - Babcock" },
  { value: "WRITING 420 - Manis", label: "WRITING 420 - Manis" },
  { value: "WRITING 420 - McDaniel", label: "WRITING 420 - McDaniel" },
  { value: "YIDDISH 102 - Luchina", label: "YIDDISH 102 - Luchina" },
  { value: "YIDDISH 202", label: "YIDDISH 202" },
  { value: "YIDDISH 202 - Luchina", label: "YIDDISH 202 - Luchina" },
  { value: "YIDDISH 432 - Luchina", label: "YIDDISH 432 - Luchina" },
  { value: "ANTHRCUL 337 - Mueggler", label: "ANTHRCUL 337 - Mueggler" },
  { value: "ARABAM 311 - Crasnow", label: "ARABAM 311 - Crasnow" },
  { value: "ARCH 507", label: "ARCH 507" },
  { value: "ARCH 509 - Maigret", label: "ARCH 509 - Maigret" },
  { value: "ARTDES 399 - Metsker", label: "ARTDES 399 - Metsker" },
  { value: "ARTSADMN 406 - Dworkin", label: "ARTSADMN 406 - Dworkin" },
  { value: "ASIAN 261 - Rebull", label: "ASIAN 261 - Rebull" },
  { value: "ASIAN 465 - Carr", label: "ASIAN 465 - Carr" },
  { value: "ASIAN 480 - Nornes", label: "ASIAN 480 - Nornes" },
  { value: "ASIANLAN 126", label: "ASIANLAN 126" },
  { value: "AT 305 - Lepley", label: "AT 305 - Lepley" },
  { value: "AUTO 533 - Dasgupta", label: "AUTO 533 - Dasgupta" },
  { value: "BA 553 - Spreitzer", label: "BA 553 - Spreitzer" },
  { value: "BCOM 369 - Crawford", label: "BCOM 369 - Crawford" },
  { value: "BIOLCHEM 403 - Nichols", label: "BIOLCHEM 403 - Nichols" },
  { value: "BIOSTAT 522", label: "BIOSTAT 522" },
  { value: "BL 507 - Kress", label: "BL 507 - Kress" },
  { value: "CDB 450 - Hortsch", label: "CDB 450 - Hortsch" },
  { value: "CEE 574 - Li", label: "CEE 574 - Li" },
  { value: "CHE 344 - Singh", label: "CHE 344 - Singh" },
  { value: "CHE 460 - Tadd", label: "CHE 460 - Tadd" },
  { value: "CHEM 247 - Bailey", label: "CHEM 247 - Bailey" },
  { value: "CHEM 580 - Kubarych", label: "CHEM 580 - Kubarych" },
  { value: "CLCIV 125 - Schironi", label: "CLCIV 125 - Schironi" },
  {
    value: "CLCIV 253 - Mays, Chatterjee, Das",
    label: "CLCIV 253 - Mays, Chatterjee, Das",
  },
  { value: "CLCIV 379 - Stone", label: "CLCIV 379 - Stone" },
  { value: "CLIMATE 463 - Samson", label: "CLIMATE 463 - Samson" },
  { value: "CLIMATE 323", label: "CLIMATE 323" },
  { value: "COMM 431 - Collings", label: "COMM 431 - Collings" },
  { value: "COMP 516 - Santos", label: "COMP 516 - Santos" },
  { value: "CONDUCT 503 - Haithcock", label: "CONDUCT 503 - Haithcock" },
  { value: "DANCE 595 - Croft", label: "DANCE 595 - Croft" },
  { value: "DANCE 412", label: "DANCE 412" },
  { value: "DATASCI 503 - Zhu", label: "DATASCI 503 - Zhu" },
  { value: "DIGITAL 200 - Li", label: "DIGITAL 200 - Li" },
  { value: "EARTH 122 - Samson", label: "EARTH 122 - Samson" },
  {
    value: "EARTH 477 - Cruz Da Silva Castro",
    label: "EARTH 477 - Cruz Da Silva Castro",
  },
  { value: "EAS 539 - Currie", label: "EAS 539 - Currie" },
  { value: "ECON 101 - Stevenson", label: "ECON 101 - Stevenson" },
  { value: "ECON 485 - Cho", label: "ECON 485 - Cho" },
  { value: "EDUC 446 - Pasque", label: "EDUC 446 - Pasque" },
  { value: "EDUC 304 - Waychunas", label: "EDUC 304 - Waychunas" },
  { value: "EDUC 390 - Dyer", label: "EDUC 390 - Dyer" },
  { value: "EDUC 571 - Stanzler", label: "EDUC 571 - Stanzler" },
  { value: "EECS 203", label: "EECS 203" },
  { value: "EECS 376", label: "EECS 376" },
  {
    value: "EECS 482 - Kapritsos, Kasikci, Chen",
    label: "EECS 482 - Kapritsos, Kasikci, Chen",
  },
  { value: "EECS 514 - Gianchandani", label: "EECS 514 - Gianchandani" },
  { value: "EECS 586 - Lee", label: "EECS 586 - Lee" },
  { value: "EHS 594 - Batterman", label: "EHS 594 - Batterman" },
  { value: "ELI 393 - Kato", label: "ELI 393 - Kato" },
  { value: "ENGLISH 320 - Balachander", label: "ENGLISH 320 - Balachander" },
  { value: "ENGLISH 124 - Hagler", label: "ENGLISH 124 - Hagler" },
  { value: "ENGLISH 125 - Parra Garcia", label: "ENGLISH 125 - Parra Garcia" },
  { value: "ENGLISH 125 - Williams", label: "ENGLISH 125 - Williams" },
  { value: "ENGLISH 125 - Tunstall", label: "ENGLISH 125 - Tunstall" },
  { value: "ENGLISH 225 - Tessier", label: "ENGLISH 225 - Tessier" },
  { value: "ENGLISH 140 - Najita", label: "ENGLISH 140 - Najita" },
  { value: "ENGLISH 428 - Walker", label: "ENGLISH 428 - Walker" },
  { value: "ENGR 371 - Guo", label: "ENGR 371 - Guo" },
  { value: "ENGR 599 - Ku", label: "ENGR 599 - Ku" },
  { value: "ENS 181 - Collier", label: "ENS 181 - Collier" },
  { value: "ENS 467", label: "ENS 467" },
  { value: "ENTR 409 - Fortino", label: "ENTR 409 - Fortino" },
  { value: "ENVIRON 437 - Becker", label: "ENVIRON 437 - Becker" },
  { value: "EPID 516 - Smith", label: "EPID 516 - Smith" },
  { value: "FIN 580", label: "FIN 580" },
  { value: "GERMAN 231", label: "GERMAN 231" },
  { value: "GREEK 402 - Ready", label: "GREEK 402 - Ready" },
  { value: "HISTART 342 - Willette", label: "HISTART 342 - Willette" },
  { value: "HISTART 351 - Willette", label: "HISTART 351 - Willette" },
  { value: "HISTORY 230 - Witgen", label: "HISTORY 230 - Witgen" },
  { value: "HISTORY 328", label: "HISTORY 328" },
  { value: "STATS 425 - Chan", label: "STATS 425 - Chan" },
  { value: "TCHNCLCM 380 - Hanson", label: "TCHNCLCM 380 - Hanson" },
  { value: "THTREMUS 335 - Lucas", label: "THTREMUS 335 - Lucas" },
  { value: "TO 425 - Koksalan", label: "TO 425 - Koksalan" },
  { value: "URP 390 - Norman", label: "URP 390 - Norman" },
  { value: "WGS 245 - Hubbs", label: "WGS 245 - Hubbs" },
  { value: "WGS 431 - Jones", label: "WGS 431 - Jones" },
  { value: "ENGLISH 230 - Soberano", label: "ENGLISH 230 - Soberano" },
  { value: "ENVIRON 119 - Yarce", label: "ENVIRON 119 - Yarce" },
  { value: "HEBREW 201", label: "HEBREW 201" },
  { value: "THEORY 250 - Fournier", label: "THEORY 250 - Fournier" },
  { value: "WRITING 405 - McDaniel", label: "WRITING 405 - McDaniel" },
  { value: "HISTORY 195 - Osgood", label: "HISTORY 195 - Osgood" },
  { value: "HISTORY 195 - Patterson", label: "HISTORY 195 - Patterson" },
  { value: "HISTORY 197 - de Pee", label: "HISTORY 197 - de Pee" },
  { value: "HISTORY 197 - Marwil", label: "HISTORY 197 - Marwil" },
  { value: "HISTORY 197 - Mills", label: "HISTORY 197 - Mills" },
  { value: "HISTORY 202 - Ballinger", label: "HISTORY 202 - Ballinger" },
  { value: "HISTORY 202 - Cipa", label: "HISTORY 202 - Cipa" },
  { value: "HISTORY 202 - Shin", label: "HISTORY 202 - Shin" },
  { value: "HISTORY 202 - Wroblewski", label: "HISTORY 202 - Wroblewski" },
  { value: "HISTORY 384 - Veidlinger", label: "HISTORY 384 - Veidlinger" },
  { value: "HISTORY 396 - Thurman", label: "HISTORY 396 - Thurman" },
  { value: "HISTORY 407 - Caulfield", label: "HISTORY 407 - Caulfield" },
  { value: "HISTORY 417 - Gailus", label: "HISTORY 417 - Gailus" },
  { value: "HISTORY 444 - Goldman", label: "HISTORY 444 - Goldman" },
  { value: "HISTORY 447 - Gull", label: "HISTORY 447 - Gull" },
  { value: "HISTORY 481 - Toman", label: "HISTORY 481 - Toman" },
  { value: "HISTORY 496 - Cole", label: "HISTORY 496 - Cole" },
  { value: "HISTORY 496 - Poteet", label: "HISTORY 496 - Poteet" },
  { value: "HISTORY 496 - Shin", label: "HISTORY 496 - Shin" },
  { value: "HISTORY 496 - Thompson", label: "HISTORY 496 - Thompson" },
  { value: "HISTORY 497 - Fancy", label: "HISTORY 497 - Fancy" },
  { value: "HISTORY 497 - Israel", label: "HISTORY 497 - Israel" },
  { value: "HISTORY 497 - Squatriti", label: "HISTORY 497 - Squatriti" },
  { value: "HISTORY 498 - de Pee", label: "HISTORY 498 - de Pee" },
  { value: "HISTORY 498 - Jimenez", label: "HISTORY 498 - Jimenez" },
  { value: "HISTORY 499 - Spooner", label: "HISTORY 499 - Spooner" },
  { value: "HONORS 170", label: "HONORS 170" },
  { value: "HONORS 230 - Adunbi", label: "HONORS 230 - Adunbi" },
  { value: "HONORS 231 - Mallette", label: "HONORS 231 - Mallette" },
  { value: "HONORS 232 - McNeil", label: "HONORS 232 - McNeil" },
  { value: "HONORS 233 - Olsen", label: "HONORS 233 - Olsen" },
  { value: "HS 301 - Harden, Mason", label: "HS 301 - Harden, Mason" },
  {
    value: "HUMGEN 480 - Bielas, Kwan, Iwase",
    label: "HUMGEN 480 - Bielas, Kwan, Iwase",
  },
  { value: "IHS 340 - Huffnagle", label: "IHS 340 - Huffnagle" },
  { value: "INFLXMED 401 - Stanley", label: "INFLXMED 401 - Stanley" },
  { value: "INSTHUM 311 - Krugliak", label: "INSTHUM 311 - Krugliak" },
  { value: "INTLSTD 101 - Tsai", label: "INTLSTD 101 - Tsai" },
  { value: "INTLSTD 170 - Sula", label: "INTLSTD 170 - Sula" },
  { value: "INTLSTD 301 - Stonington", label: "INTLSTD 301 - Stonington" },
  { value: "INTLSTD 348 - Caulfield", label: "INTLSTD 348 - Caulfield" },
  { value: "INTLSTD 384 - Randall", label: "INTLSTD 384 - Randall" },
  { value: "INTLSTD 385 - Kawar", label: "INTLSTD 385 - Kawar" },
  { value: "INTLSTD 385 - Nordaas", label: "INTLSTD 385 - Nordaas" },
  { value: "INTLSTD 385 - Tsai", label: "INTLSTD 385 - Tsai" },
  { value: "INTLSTD 401 - Caulfield", label: "INTLSTD 401 - Caulfield" },
  { value: "INTLSTD 401 - Fariss", label: "INTLSTD 401 - Fariss" },
  { value: "INTLSTD 401 - Manuel", label: "INTLSTD 401 - Manuel" },
  { value: "INTLSTD 401 - Marcum", label: "INTLSTD 401 - Marcum" },
  { value: "INTLSTD 401 - Nathan", label: "INTLSTD 401 - Nathan" },
  { value: "INTLSTD 401 - Nordaas", label: "INTLSTD 401 - Nordaas" },
  { value: "INTLSTD 401 - Phung", label: "INTLSTD 401 - Phung" },
  { value: "INTLSTD 401 - Sarlati", label: "INTLSTD 401 - Sarlati" },
  { value: "INTLSTD 401 - Stonington", label: "INTLSTD 401 - Stonington" },
  { value: "INTLSTD 401 - Tanielian", label: "INTLSTD 401 - Tanielian" },
  { value: "INTLSTD 401 - Tsai", label: "INTLSTD 401 - Tsai" },
  { value: "INTLSTD 401 - Uehling", label: "INTLSTD 401 - Uehling" },
  { value: "INTLSTD 401 - Zameret", label: "INTLSTD 401 - Zameret" },
  { value: "INTLSTD 470 - Saluk", label: "INTLSTD 470 - Saluk" },
  { value: "INTLSTD 489 - Herwitz", label: "INTLSTD 489 - Herwitz" },
  { value: "INTLSTD 499 - Marcum", label: "INTLSTD 499 - Marcum" },
  { value: "INTMED 409 - Erb-Downward", label: "INTMED 409 - Erb-Downward" },
  { value: "INTPERF 160 - Hughes", label: "INTPERF 160 - Hughes" },
  { value: "IOE 201 - Mondisa", label: "IOE 201 - Mondisa" },
  { value: "IOE 202 - Shi", label: "IOE 202 - Shi" },
  { value: "IOE 265 - Stirling", label: "IOE 265 - Stirling" },
  { value: "IOE 310 - Cohn", label: "IOE 310 - Cohn" },
  { value: "IOE 316 - DeRoos", label: "IOE 316 - DeRoos" },
  { value: "IOE 333 - DSouza", label: "IOE 333 - DSouza" },
  { value: "IOE 334", label: "IOE 334" },
  { value: "IOE 366 - Byon", label: "IOE 366 - Byon" },
  { value: "IOE 373 - Garcia-Guzman", label: "IOE 373 - Garcia-Guzman" },
  { value: "IOE 410 - Nagarajan", label: "IOE 410 - Nagarajan" },
  { value: "IOE 419 - Daskin", label: "IOE 419 - Daskin" },
  { value: "IOE 424 - Levantrosser", label: "IOE 424 - Levantrosser" },
  { value: "IOE 424 - Sathe", label: "IOE 424 - Sathe" },
  { value: "IOE 430 - Liu", label: "IOE 430 - Liu" },
  { value: "IOE 436 - Green", label: "IOE 436 - Green" },
  { value: "IOE 438 - Frantz", label: "IOE 438 - Frantz" },
  { value: "IOE 441 - Gusikhin", label: "IOE 441 - Gusikhin" },
  { value: "IOE 453 - Kamaly", label: "IOE 453 - Kamaly" },
  { value: "IOE 460 - Wu", label: "IOE 460 - Wu" },
  { value: "IOE 463 - Armstrong", label: "IOE 463 - Armstrong" },
  { value: "IOE 465 - Yang", label: "IOE 465 - Yang" },
  { value: "IOE 466 - Jin", label: "IOE 466 - Jin" },
  { value: "IOE 474 - Garcia-Guzman", label: "IOE 474 - Garcia-Guzman" },
  { value: "IOE 481 - Van Oyen", label: "IOE 481 - Van Oyen" },
  { value: "IOE 491 - Fattahi", label: "IOE 491 - Fattahi" },
  { value: "IOE 491 - Levantrosser", label: "IOE 491 - Levantrosser" },
  { value: "ISLAM 216 - Knysh", label: "ISLAM 216 - Knysh" },
  { value: "ISLAM 315 - Bardenstein", label: "ISLAM 315 - Bardenstein" },
  { value: "ISLAM 320 - Cipa", label: "ISLAM 320 - Cipa" },
  { value: "ISLAM 321 - Knysh", label: "ISLAM 321 - Knysh" },
  { value: "ISLAM 325 - Mir", label: "ISLAM 325 - Mir" },
  { value: "ISLAM 328 - Ali", label: "ISLAM 328 - Ali" },
  { value: "ISLAM 390 - Crasnow", label: "ISLAM 390 - Crasnow" },
  { value: "ISLAM 392", label: "ISLAM 392" },
  { value: "ISLAM 392 - Crasnow", label: "ISLAM 392 - Crasnow" },
  { value: "ISLAM 392 - Das", label: "ISLAM 392 - Das" },
  { value: "ISLAM 430 - Le Gassick", label: "ISLAM 430 - Le Gassick" },
  { value: "ISLAM 443 - Cole", label: "ISLAM 443 - Cole" },
  { value: "ISLAM 490 - Cross", label: "ISLAM 490 - Cross" },
  { value: "ITALIAN 101 - Mosciatti", label: "ITALIAN 101 - Mosciatti" },
  { value: "ITALIAN 101 - Salvatore", label: "ITALIAN 101 - Salvatore" },
  { value: "ITALIAN 102 - Audiberti", label: "ITALIAN 102 - Audiberti" },
  {
    value: "ITALIAN 102 - Rodriguez Mojica",
    label: "ITALIAN 102 - Rodriguez Mojica",
  },
  {
    value: "ITALIAN 231 - Lasker-Ferretti",
    label: "ITALIAN 231 - Lasker-Ferretti",
  },
  {
    value: "ITALIAN 231 - Liu, Lasker-Ferretti",
    label: "ITALIAN 231 - Liu, Lasker-Ferretti",
  },
  {
    value: "ITALIAN 232 - Garrido Baez, Lasker-Ferretti",
    label: "ITALIAN 232 - Garrido Baez, Lasker-Ferretti",
  },
  {
    value: "ITALIAN 232 - Lasker-Ferretti",
    label: "ITALIAN 232 - Lasker-Ferretti",
  },
  {
    value: "ITALIAN 232 - Mosciatti, Lasker-Ferretti",
    label: "ITALIAN 232 - Mosciatti, Lasker-Ferretti",
  },
  { value: "ITALIAN 310 - Binetti", label: "ITALIAN 310 - Binetti" },
  { value: "ITALIAN 313 - Squatriti", label: "ITALIAN 313 - Squatriti" },
  { value: "ITALIAN 314 - Gaggio", label: "ITALIAN 314 - Gaggio" },
  { value: "ITALIAN 316", label: "ITALIAN 316" },
  { value: "ITALIAN 340 - Binetti", label: "ITALIAN 340 - Binetti" },
  { value: "ITALIAN 374 - Ricco", label: "ITALIAN 374 - Ricco" },
  { value: "ITALIAN 415 - Ricco", label: "ITALIAN 415 - Ricco" },
  {
    value: "JAZZ 221 - Kirschenmann, Wood",
    label: "JAZZ 221 - Kirschenmann, Wood",
  },
  { value: "JAZZ 450 - Travers", label: "JAZZ 450 - Travers" },
  { value: "JAZZ 454", label: "JAZZ 454" },
  { value: "JAZZ 454 - Okazaki", label: "JAZZ 454 - Okazaki" },
  { value: "JAZZ 454 - Sarath", label: "JAZZ 454 - Sarath" },
  { value: "JAZZ 454 - Travers", label: "JAZZ 454 - Travers" },
  { value: "JAZZ 455", label: "JAZZ 455" },
  { value: "JAZZ 455 - Travers", label: "JAZZ 455 - Travers" },
  { value: "JAZZ 462", label: "JAZZ 462" },
  { value: "JAZZ 467", label: "JAZZ 467" },
  { value: "JAZZ 472", label: "JAZZ 472" },
  { value: "JAZZ 479 - Wilson", label: "JAZZ 479 - Wilson" },
  { value: "JAZZ 480 - Lucas", label: "JAZZ 480 - Lucas" },
  { value: "JUDAIC 102 - Luchina", label: "JUDAIC 102 - Luchina" },
  { value: "JUDAIC 121 - Scott", label: "JUDAIC 121 - Scott" },
  { value: "JUDAIC 150 - Tsoffar", label: "JUDAIC 150 - Tsoffar" },
  { value: "JUDAIC 202", label: "JUDAIC 202" },
  { value: "JUDAIC 202 - Luchina", label: "JUDAIC 202 - Luchina" },
  { value: "JUDAIC 218", label: "JUDAIC 218" },
  { value: "JUDAIC 218 - Mordoch", label: "JUDAIC 218 - Mordoch" },
  { value: "JUDAIC 218 - Roby", label: "JUDAIC 218 - Roby" },
  { value: "JUDAIC 218 - Toman", label: "JUDAIC 218 - Toman" },
  { value: "JUDAIC 224 - Neis", label: "JUDAIC 224 - Neis" },
  { value: "JUDAIC 244 - Lieberman", label: "JUDAIC 244 - Lieberman" },
  { value: "JUDAIC 253 - Mays", label: "JUDAIC 253 - Mays" },
  {
    value: "JUDAIC 253 - Mays, Das , Chatterjee",
    label: "JUDAIC 253 - Mays, Das , Chatterjee",
  },
  {
    value: "JUDAIC 258 - Levinson, Fox, Robertson",
    label: "JUDAIC 258 - Levinson, Fox, Robertson",
  },
  { value: "JUDAIC 260 - Neis", label: "JUDAIC 260 - Neis" },
  { value: "JUDAIC 281 - Pinsker", label: "JUDAIC 281 - Pinsker" },
  { value: "JUDAIC 310 - Ginsburg", label: "JUDAIC 310 - Ginsburg" },
  { value: "JUDAIC 317 - Eubanks Segal", label: "JUDAIC 317 - Eubanks Segal" },
  { value: "JUDAIC 317 - Raz", label: "JUDAIC 317 - Raz" },
  { value: "JUDAIC 318", label: "JUDAIC 318" },
  { value: "JUDAIC 318 - Hussein", label: "JUDAIC 318 - Hussein" },
  { value: "JUDAIC 318 - Luchina", label: "JUDAIC 318 - Luchina" },
  {
    value: "JUDAIC 318 - Spector, Thurman",
    label: "JUDAIC 318 - Spector, Thurman",
  },
  { value: "JUDAIC 318 - Wollenberg", label: "JUDAIC 318 - Wollenberg" },
  { value: "JUDAIC 340", label: "JUDAIC 340" },
  { value: "JUDAIC 340 - Roby", label: "JUDAIC 340 - Roby" },
  { value: "JUDAIC 351 - Toebosch", label: "JUDAIC 351 - Toebosch" },
  { value: "JUDAIC 388 - Veidlinger", label: "JUDAIC 388 - Veidlinger" },
  { value: "JUDAIC 389 - Levinson", label: "JUDAIC 389 - Levinson" },
  { value: "JUDAIC 417 - Boccaccini", label: "JUDAIC 417 - Boccaccini" },
  { value: "JUDAIC 417 - DeGrado", label: "JUDAIC 417 - DeGrado" },
  { value: "JUDAIC 417 - Ginsburg", label: "JUDAIC 417 - Ginsburg" },
  { value: "JUDAIC 432 - Luchina", label: "JUDAIC 432 - Luchina" },
  { value: "KINESLGY 302 - Barton", label: "KINESLGY 302 - Barton" },
  {
    value: "KINESLGY 302 - Donahue, Barton, Szymanski",
    label: "KINESLGY 302 - Donahue, Barton, Szymanski",
  },
  {
    value: "KINESLGY 302 - Gross, Hennessey, Barton",
    label: "KINESLGY 302 - Gross, Hennessey, Barton",
  },
  {
    value: "KINESLGY 302 - Kwak, Barton",
    label: "KINESLGY 302 - Kwak, Barton",
  },
  {
    value: "KINESLGY 302 - Robinson, Barton",
    label: "KINESLGY 302 - Robinson, Barton",
  },
  { value: "KINESLGY 413", label: "KINESLGY 413" },
  { value: "KINESLGY 413 - Mergos", label: "KINESLGY 413 - Mergos" },
  { value: "KINESLGY 413 - Umberger", label: "KINESLGY 413 - Umberger" },
  { value: "KINESLGY 422 - Vesia", label: "KINESLGY 422 - Vesia" },
  { value: "KINESLGY 425 - Ulrich", label: "KINESLGY 425 - Ulrich" },
  { value: "KINESLGY 429", label: "KINESLGY 429" },
  { value: "KINESLGY 439", label: "KINESLGY 439" },
  { value: "KINESLGY 442 - Haus", label: "KINESLGY 442 - Haus" },
  { value: "KINESLGY 443 - Ludlow", label: "KINESLGY 443 - Ludlow" },
  { value: "KINESLGY 446 - Hasson", label: "KINESLGY 446 - Hasson" },
  { value: "KINESLGY 449", label: "KINESLGY 449" },
  { value: "KINESLGY 451 - Harris", label: "KINESLGY 451 - Harris" },
  { value: "KINESLGY 452", label: "KINESLGY 452" },
  { value: "KRSTD 472 - Walsh", label: "KRSTD 472 - Walsh" },
  { value: "LACS 321 - Tsai", label: "LACS 321 - Tsai" },
  { value: "LACS 446", label: "LACS 446" },
  { value: "LACS 462 - Langland", label: "LACS 462 - Langland" },
  { value: "LACS 464 - Langland", label: "LACS 464 - Langland" },
  { value: "LACS 466 - Langland", label: "LACS 466 - Langland" },
  { value: "LACS 472 - Carlos Rios", label: "LACS 472 - Carlos Rios" },
  { value: "LACS 474 - Carlos Rios", label: "LACS 474 - Carlos Rios" },
  { value: "LACS 476 - Carlos Rios", label: "LACS 476 - Carlos Rios" },
  { value: "LATIN 101 - Green", label: "LATIN 101 - Green" },
  { value: "LATIN 102 - Mastronardi", label: "LATIN 102 - Mastronardi" },
  { value: "LATIN 102 - Nash", label: "LATIN 102 - Nash" },
  { value: "LATIN 102 - Salehzai", label: "LATIN 102 - Salehzai" },
  { value: "LATIN 231 - Soter", label: "LATIN 231 - Soter" },
  { value: "LATIN 231 - Tohm", label: "LATIN 231 - Tohm" },
  { value: "LATIN 232 - Buchanan", label: "LATIN 232 - Buchanan" },
  { value: "LATIN 232 - Caston", label: "LATIN 232 - Caston" },
  { value: "LATIN 232 - Mayo", label: "LATIN 232 - Mayo" },
  { value: "LATIN 233 - Markus", label: "LATIN 233 - Markus" },
  { value: "LATIN 294 - Soter", label: "LATIN 294 - Soter" },
  { value: "LATIN 301 - Markus", label: "LATIN 301 - Markus" },
  {
    value: "LATINOAM 224 - Galvan-Santibanez",
    label: "LATINOAM 224 - Galvan-Santibanez",
  },
  {
    value: "LATINOAM 243 - Calvo-Quiros",
    label: "LATINOAM 243 - Calvo-Quiros",
  },
  {
    value: "LATINOAM 310 - Sanchez-Snell",
    label: "LATINOAM 310 - Sanchez-Snell",
  },
  { value: "LATINOAM 311 - Anderson", label: "LATINOAM 311 - Anderson" },
  { value: "LATINOAM 311 - Beltran", label: "LATINOAM 311 - Beltran" },
  { value: "LATINOAM 405 - Ocampo", label: "LATINOAM 405 - Ocampo" },
  { value: "LING 102 - Abner", label: "LING 102 - Abner" },
  { value: "LING 102 - Duanmu", label: "LING 102 - Duanmu" },
  { value: "LING 102 - Meek", label: "LING 102 - Meek" },
  { value: "LING 111 - Keshet", label: "LING 111 - Keshet" },
  { value: "LING 114 - Levinson", label: "LING 114 - Levinson" },
  { value: "LING 192 - Baxter", label: "LING 192 - Baxter" },
  { value: "LING 193 - McNulty", label: "LING 193 - McNulty" },
  { value: "LING 209 - Baptista", label: "LING 209 - Baptista" },
  { value: "LING 272 - Lempert", label: "LING 272 - Lempert" },
  { value: "LING 313 - Duanmu", label: "LING 313 - Duanmu" },
  { value: "LING 315 - Abner", label: "LING 315 - Abner" },
  { value: "LING 316 - Abney", label: "LING 316 - Abney" },
  { value: "LING 347 - Levinson", label: "LING 347 - Levinson" },
  { value: "LING 351 - Guo", label: "LING 351 - Guo" },
  { value: "LING 352 - Gardner-Neblett", label: "LING 352 - Gardner-Neblett" },
  { value: "LING 368 - Baxter", label: "LING 368 - Baxter" },
  { value: "LING 370 - Namboodiripad", label: "LING 370 - Namboodiripad" },
  { value: "LING 390 - Dyer", label: "LING 390 - Dyer" },
  { value: "LING 390 - Romano Gillette", label: "LING 390 - Romano Gillette" },
  { value: "LING 394 - McNulty", label: "LING 394 - McNulty" },
  { value: "LING 408 - Toon", label: "LING 408 - Toon" },
  { value: "LING 413 - Beddor", label: "LING 413 - Beddor" },
  { value: "LING 446", label: "LING 446" },
  { value: "LING 447 - Boland", label: "LING 447 - Boland" },
  { value: "LING 471 - Lempert", label: "LING 471 - Lempert" },
  { value: "LING 492 - Krivokapic", label: "LING 492 - Krivokapic" },
  { value: "LING 497 - Brennan", label: "LING 497 - Brennan" },
  { value: "LING 497 - Thomason", label: "LING 497 - Thomason" },
  { value: "LSWA 140 - Tucker", label: "LSWA 140 - Tucker" },
  { value: "LSWA 228 - Beal", label: "LSWA 228 - Beal" },
  { value: "LSWA 230 - Beal", label: "LSWA 230 - Beal" },
  { value: "LSWA 230 - Silver", label: "LSWA 230 - Silver" },
  { value: "LSWA 230 - Tell", label: "LSWA 230 - Tell" },
  { value: "MATH 105", label: "MATH 105" },
  { value: "MATH 105 - Lande", label: "MATH 105 - Lande" },
  { value: "MATH 115", label: "MATH 115" },
  { value: "MATH 116", label: "MATH 116" },
  { value: "MATH 117", label: "MATH 117" },
  { value: "MATH 147 - Cao", label: "MATH 147 - Cao" },
  { value: "MATH 176 - Heffers", label: "MATH 176 - Heffers" },
  { value: "MATH 186 - Weiss", label: "MATH 186 - Weiss" },
  { value: "MATH 214", label: "MATH 214" },
  { value: "MATH 215", label: "MATH 215" },
  { value: "MATH 216", label: "MATH 216" },
  { value: "MATH 217", label: "MATH 217" },
  { value: "MATH 285 - Bellis", label: "MATH 285 - Bellis" },
  { value: "MATH 286 - Bergman", label: "MATH 286 - Bergman" },
  { value: "MATH 289 - Patel", label: "MATH 289 - Patel" },
  { value: "MATH 296 - Koch", label: "MATH 296 - Koch" },
  { value: "MATH 297 - Schneider", label: "MATH 297 - Schneider" },
  { value: "MATH 310 - Dunworth", label: "MATH 310 - Dunworth" },
  {
    value: "MATH 316 - Grande Izquierdo",
    label: "MATH 316 - Grande Izquierdo",
  },
  { value: "MATH 316 - Silverman", label: "MATH 316 - Silverman" },
  { value: "MATH 333", label: "MATH 333" },
  { value: "MATH 351 - Page", label: "MATH 351 - Page" },
  { value: "MATH 354 - Bellis", label: "MATH 354 - Bellis" },
  { value: "MATH 371 - Guo", label: "MATH 371 - Guo" },
  { value: "MATH 371 - Strauss", label: "MATH 371 - Strauss" },
  { value: "MATH 389 - Martone, Boland", label: "MATH 389 - Martone, Boland" },
  { value: "MATH 396", label: "MATH 396" },
  { value: "MATH 396 - Hani", label: "MATH 396 - Hani" },
  { value: "MATH 404 - Viswanath", label: "MATH 404 - Viswanath" },
  { value: "MATH 412 - Olano", label: "MATH 412 - Olano" },
  { value: "MATH 412 - Smolkin", label: "MATH 412 - Smolkin" },
  { value: "MATH 416 - Norwood", label: "MATH 416 - Norwood" },
  { value: "MATH 417 - Koziol", label: "MATH 417 - Koziol" },
  { value: "MATH 423 - Bernhardt", label: "MATH 423 - Bernhardt" },
  { value: "MATH 423 - Deng", label: "MATH 423 - Deng" },
  { value: "MATH 424 - Cao", label: "MATH 424 - Cao" },
  { value: "MATH 425 - Chan", label: "MATH 425 - Chan" },
  { value: "MATH 425 - Fink", label: "MATH 425 - Fink" },
  { value: "MATH 425 - Green", label: "MATH 425 - Green" },
  { value: "MATH 425 - Li", label: "MATH 425 - Li" },
  { value: "MATH 425 - Rome", label: "MATH 425 - Rome" },
  { value: "MATH 433 - Wright", label: "MATH 433 - Wright" },
  { value: "MATH 440", label: "MATH 440" },
  { value: "MATH 450 - Wen", label: "MATH 450 - Wen" },
  { value: "MATH 451 - Borcea", label: "MATH 451 - Borcea" },
  { value: "MATH 451 - Patel", label: "MATH 451 - Patel" },
  { value: "MATH 452 - Liu", label: "MATH 452 - Liu" },
  { value: "MATH 454 - Megginson", label: "MATH 454 - Megginson" },
  { value: "MATH 462", label: "MATH 462" },
  { value: "MATH 462 - Dunworth", label: "MATH 462 - Dunworth" },
  { value: "MATH 465 - George", label: "MATH 465 - George" },
  { value: "MATH 474 - Cohen", label: "MATH 474 - Cohen" },
  { value: "MATH 475 - Koziol", label: "MATH 475 - Koziol" },
  { value: "MATH 489 - White", label: "MATH 489 - White" },
  { value: "MATH 494 - Zieve", label: "MATH 494 - Zieve" },
  { value: "MATH 498 - Bass", label: "MATH 498 - Bass" },
  { value: "MATH 498 - DeBacker", label: "MATH 498 - DeBacker" },
  { value: "MATSCIE 220 - Wynarsky", label: "MATSCIE 220 - Wynarsky" },
  { value: "MATSCIE 242", label: "MATSCIE 242" },
  { value: "MATSCIE 242 - Kioupakis", label: "MATSCIE 242 - Kioupakis" },
  { value: "MATSCIE 250 - Goldman", label: "MATSCIE 250 - Goldman" },
  {
    value: "MATSCIE 293 - Beck, Yalisove",
    label: "MATSCIE 293 - Beck, Yalisove",
  },
  { value: "MATSCIE 335", label: "MATSCIE 335" },
  {
    value: "MATSCIE 335 - Thornton, Sun",
    label: "MATSCIE 335 - Thornton, Sun",
  },
  { value: "MATSCIE 365 - Chambers", label: "MATSCIE 365 - Chambers" },
  {
    value: "MATSCIE 440 - Poudeu-Poudeu",
    label: "MATSCIE 440 - Poudeu-Poudeu",
  },
  { value: "MATSCIE 465 - Hovden", label: "MATSCIE 465 - Hovden" },
  { value: "MATSCIE 482 - Shtein, Li", label: "MATSCIE 482 - Shtein, Li" },
  { value: "MCDB 306 - Spillane", label: "MCDB 306 - Spillane" },
  {
    value: "MCDB 310 - Akey, Nandakumar",
    label: "MCDB 310 - Akey, Nandakumar",
  },
  { value: "MCDB 321 - Li", label: "MCDB 321 - Li" },
  { value: "MCDB 401 - Bembenek", label: "MCDB 401 - Bembenek" },
  { value: "MCDB 401 - Stockbridge", label: "MCDB 401 - Stockbridge" },
  { value: "MCDB 409 - Erb-Downward", label: "MCDB 409 - Erb-Downward" },
  { value: "MCDB 411 - Akey", label: "MCDB 411 - Akey" },
  { value: "MCDB 420 - Guerra", label: "MCDB 420 - Guerra" },
  {
    value: "MCDB 422 - Clowney, Collins",
    label: "MCDB 422 - Clowney, Collins",
  },
  { value: "MCDB 423", label: "MCDB 423" },
  { value: "MCDB 424", label: "MCDB 424" },
  { value: "MCDB 428", label: "MCDB 428" },
  { value: "MCDB 428 - DeSantis, Li", label: "MCDB 428 - DeSantis, Li" },
  { value: "MCDB 429 - Archbold", label: "MCDB 429 - Archbold" },
  { value: "MCDB 435 - Chang", label: "MCDB 435 - Chang" },
  { value: "MCDB 441 - Wang", label: "MCDB 441 - Wang" },
  { value: "MCDB 452 - Wong", label: "MCDB 452 - Wong" },
  { value: "MCDB 459 - Aton", label: "MCDB 459 - Aton" },
  { value: "MCDB 463 - Duan", label: "MCDB 463 - Duan" },
  { value: "MCDB 494 - Kurdziel", label: "MCDB 494 - Kurdziel" },
  { value: "MCDB 499 - Jakob", label: "MCDB 499 - Jakob" },
  { value: "MECHENG 211 - Estrada", label: "MECHENG 211 - Estrada" },
  { value: "MECHENG 211 - Lu", label: "MECHENG 211 - Lu" },
  {
    value: "MECHENG 235 - Bala Chandran",
    label: "MECHENG 235 - Bala Chandran",
  },
  { value: "MECHENG 235 - Violi", label: "MECHENG 235 - Violi" },
  { value: "MECHENG 240 - Grosh", label: "MECHENG 240 - Grosh" },
  { value: "MECHENG 240 - Tol", label: "MECHENG 240 - Tol" },
  {
    value: "MECHENG 250 - Umbriac, Cooper",
    label: "MECHENG 250 - Umbriac, Cooper",
  },
  { value: "MECHENG 305 - Huan", label: "MECHENG 305 - Huan" },
  { value: "MECHENG 311 - Barber", label: "MECHENG 311 - Barber" },
  { value: "MECHENG 320 - Dowling", label: "MECHENG 320 - Dowling" },
  { value: "MECHENG 335 - Boehman", label: "MECHENG 335 - Boehman" },
  { value: "MECHENG 335 - Kaviany", label: "MECHENG 335 - Kaviany" },
  { value: "MECHENG 336 - Liang", label: "MECHENG 336 - Liang" },
  {
    value: "MECHENG 350 - Umbriac, Brei",
    label: "MECHENG 350 - Umbriac, Brei",
  },
  { value: "MECHENG 360 - Barton", label: "MECHENG 360 - Barton" },
  { value: "MECHENG 360 - Fazeli", label: "MECHENG 360 - Fazeli" },
  { value: "MECHENG 382 - Fan", label: "MECHENG 382 - Fan" },
  { value: "MECHENG 382 - Gavini", label: "MECHENG 382 - Gavini" },
  { value: "MECHENG 395 - Sick, Liang", label: "MECHENG 395 - Sick, Liang" },
  { value: "MECHENG 400 - Schultz", label: "MECHENG 400 - Schultz" },
  { value: "MECHENG 401 - Ni", label: "MECHENG 401 - Ni" },
  { value: "MECHENG 420 - Akhavan", label: "MECHENG 420 - Akhavan" },
  { value: "MECHENG 433 - Dasgupta", label: "MECHENG 433 - Dasgupta" },
  { value: "MECHENG 433 - Kwabi", label: "MECHENG 433 - Kwabi" },
  { value: "MECHENG 440 - Epureanu", label: "MECHENG 440 - Epureanu" },
  {
    value: "MECHENG 450 - Cooper, Sienko, Liu, Skerlos, Saitou, Shorter",
    label: "MECHENG 450 - Cooper, Sienko, Liu, Skerlos, Saitou, Shorter",
  },
  { value: "MECHENG 451 - Pan", label: "MECHENG 451 - Pan" },
  { value: "MECHENG 476 - Grotberg", label: "MECHENG 476 - Grotberg" },
  { value: "MECHENG 481 - Mazumder", label: "MECHENG 481 - Mazumder" },
  {
    value: "MECHENG 495 - Capecelatro, Hildinger, Royston",
    label: "MECHENG 495 - Capecelatro, Hildinger, Royston",
  },
  { value: "MECHENG 499 - Daly", label: "MECHENG 499 - Daly" },
  { value: "MECHENG 499 - Grosh", label: "MECHENG 499 - Grosh" },
  { value: "MECHENG 499 - Johnsen", label: "MECHENG 499 - Johnsen" },
  { value: "MECHENG 499 - Popa", label: "MECHENG 499 - Popa" },
  { value: "MECHENG 499 - Tol", label: "MECHENG 499 - Tol" },
  { value: "MECHENG 499 - Umbriac", label: "MECHENG 499 - Umbriac" },
  { value: "MEDCHEM 310 - Scott", label: "MEDCHEM 310 - Scott" },
  { value: "MELANG 102 - Scott", label: "MELANG 102 - Scott" },
  { value: "MELANG 465 - Muehlberger", label: "MELANG 465 - Muehlberger" },
  { value: "MEMS 211 - Fancy", label: "MEMS 211 - Fancy" },
  { value: "MEMS 240 - Sears", label: "MEMS 240 - Sears" },
  { value: "MEMS 325 - Mir", label: "MEMS 325 - Mir" },
  { value: "MEMS 360 - Burde", label: "MEMS 360 - Burde" },
  { value: "MEMS 367 - Cohen", label: "MEMS 367 - Cohen" },
  { value: "MEMS 386 - Gutt", label: "MEMS 386 - Gutt" },
  { value: "MEMS 442 - Gull", label: "MEMS 442 - Gull" },
  { value: "MENAS 244 - Lieberman", label: "MENAS 244 - Lieberman" },
  { value: "MENAS 332 - Ali", label: "MENAS 332 - Ali" },
  { value: "MENAS 332 - Roby", label: "MENAS 332 - Roby" },
  { value: "MENAS 462", label: "MENAS 462" },
  { value: "MENAS 463", label: "MENAS 463" },
  { value: "MFG 402 - Ni", label: "MFG 402 - Ni" },
  { value: "MFG 426 - Levantrosser", label: "MFG 426 - Levantrosser" },
  { value: "MFG 441 - Gusikhin", label: "MFG 441 - Gusikhin" },
  { value: "MFG 453 - Pan", label: "MFG 453 - Pan" },
  { value: "MFG 456 - Kamaly", label: "MFG 456 - Kamaly" },
  { value: "MFG 462 - Dong", label: "MFG 462 - Dong" },
  { value: "MFG 463 - Armstrong", label: "MFG 463 - Armstrong" },
  { value: "MFG 466 - Jin", label: "MFG 466 - Jin" },
  { value: "MFG 480 - Shtein, Li", label: "MFG 480 - Shtein, Li" },
  { value: "MICRBIOL 350", label: "MICRBIOL 350" },
  { value: "MICRBIOL 405", label: "MICRBIOL 405" },
  {
    value: "MICRBIOL 409 - Erb-Downward",
    label: "MICRBIOL 409 - Erb-Downward",
  },
  {
    value: "MICRBIOL 415 - Spindler, Ono",
    label: "MICRBIOL 415 - Spindler, Ono",
  },
  { value: "MICRBIOL 430", label: "MICRBIOL 430" },
  { value: "MICRBIOL 440 - Moore", label: "MICRBIOL 440 - Moore" },
  { value: "MIDEAST 216 - Knysh", label: "MIDEAST 216 - Knysh" },
  { value: "MIDEAST 236 - Boccaccini", label: "MIDEAST 236 - Boccaccini" },
  { value: "MIDEAST 239 - Beckman", label: "MIDEAST 239 - Beckman" },
  { value: "MIDEAST 281 - Pinsker", label: "MIDEAST 281 - Pinsker" },
  { value: "MIDEAST 284 - Lieberman", label: "MIDEAST 284 - Lieberman" },
  {
    value: "MIDEAST 290 - Eubanks Segal",
    label: "MIDEAST 290 - Eubanks Segal",
  },
  { value: "MIDEAST 290 - Raz", label: "MIDEAST 290 - Raz" },
  { value: "MIDEAST 291", label: "MIDEAST 291" },
  { value: "MIDEAST 291 - Cross", label: "MIDEAST 291 - Cross" },
  { value: "MIDEAST 291 - Roby", label: "MIDEAST 291 - Roby" },
  { value: "MIDEAST 291 - Wollenberg", label: "MIDEAST 291 - Wollenberg" },
  { value: "MIDEAST 295 - DeGrado", label: "MIDEAST 295 - DeGrado" },
  { value: "MIDEAST 295 - Eliav", label: "MIDEAST 295 - Eliav" },
  { value: "MIDEAST 295 - Macfarlane", label: "MIDEAST 295 - Macfarlane" },
  { value: "MIDEAST 315 - Bardenstein", label: "MIDEAST 315 - Bardenstein" },
  { value: "MIDEAST 320 - Cipa", label: "MIDEAST 320 - Cipa" },
  { value: "MIDEAST 321 - Knysh", label: "MIDEAST 321 - Knysh" },
  { value: "MIDEAST 328 - Ali", label: "MIDEAST 328 - Ali" },
  { value: "MIDEAST 329 - Bardenstein", label: "MIDEAST 329 - Bardenstein" },
  { value: "MIDEAST 337 - Beckman", label: "MIDEAST 337 - Beckman" },
  { value: "MIDEAST 341 - Crisostomo", label: "MIDEAST 341 - Crisostomo" },
  { value: "MIDEAST 355 - Eliav", label: "MIDEAST 355 - Eliav" },
  { value: "MIDEAST 375 - Mir", label: "MIDEAST 375 - Mir" },
  { value: "MIDEAST 387 - Babayan", label: "MIDEAST 387 - Babayan" },
  {
    value: "MIDEAST 390 - Eubanks Segal",
    label: "MIDEAST 390 - Eubanks Segal",
  },
  { value: "MIDEAST 391", label: "MIDEAST 391" },
  { value: "MIDEAST 391 - Luchina", label: "MIDEAST 391 - Luchina" },
  { value: "MIDEAST 391 - Roby", label: "MIDEAST 391 - Roby" },
  { value: "MIDEAST 426 - Pifer", label: "MIDEAST 426 - Pifer" },
  { value: "MIDEAST 427 - Cross", label: "MIDEAST 427 - Cross" },
  { value: "MIDEAST 430 - Le Gassick", label: "MIDEAST 430 - Le Gassick" },
  { value: "MIDEAST 445 - Crisostomo", label: "MIDEAST 445 - Crisostomo" },
  { value: "MIDEAST 445 - Richards", label: "MIDEAST 445 - Richards" },
  { value: "MIDEAST 487 - Cole", label: "MIDEAST 487 - Cole" },
  { value: "MIDEAST 490", label: "MIDEAST 490" },
  { value: "MIDEAST 490 - Boccaccini", label: "MIDEAST 490 - Boccaccini" },
  { value: "MIDEAST 490 - DeGrado", label: "MIDEAST 490 - DeGrado" },
  { value: "MIDEAST 490 - Ginsburg", label: "MIDEAST 490 - Ginsburg" },
  { value: "MILSCI 102 - Groller", label: "MILSCI 102 - Groller" },
  { value: "MILSCI 102 - Kositz", label: "MILSCI 102 - Kositz" },
  {
    value: "MILSCI 202 - Moreno, Groller",
    label: "MILSCI 202 - Moreno, Groller",
  },
  { value: "MILSCI 302", label: "MILSCI 302" },
  { value: "MILSCI 402 - Dye, Moreno", label: "MILSCI 402 - Dye, Moreno" },
  { value: "MKT 300 - Fong", label: "MKT 300 - Fong" },
  { value: "MKT 302 - Castelo Branco", label: "MKT 302 - Castelo Branco" },
  { value: "MKT 302 - Ku", label: "MKT 302 - Ku" },
  { value: "MKT 302 - Shaw", label: "MKT 302 - Shaw" },
  { value: "MKT 310 - Carter", label: "MKT 310 - Carter" },
  { value: "MKT 312 - Olson", label: "MKT 312 - Olson" },
  { value: "MKT 313 - Angell, Carter", label: "MKT 313 - Angell, Carter" },
  { value: "MKT 315 - Metzger", label: "MKT 315 - Metzger" },
  { value: "MKT 322 - Collins", label: "MKT 322 - Collins" },
  { value: "MKT 323 - Schulz", label: "MKT 323 - Schulz" },
  { value: "MKT 407 - Angell", label: "MKT 407 - Angell" },
  { value: "MKT 409 - Collins", label: "MKT 409 - Collins" },
  { value: "MKT 424", label: "MKT 424" },
  { value: "MKT 425 - Sriram", label: "MKT 425 - Sriram" },
  { value: "MKT 426 - Schwartz", label: "MKT 426 - Schwartz" },
  { value: "MKT 430 - Angell, Burson", label: "MKT 430 - Angell, Burson" },
  { value: "MKT 450 - Ramaswamy", label: "MKT 450 - Ramaswamy" },
  { value: "MO 320 - Mayer", label: "MO 320 - Mayer" },
  { value: "MO 321", label: "MO 321" },
  { value: "MO 410 - Page", label: "MO 410 - Page" },
  { value: "MO 415 - Alexander", label: "MO 415 - Alexander" },
  { value: "MO 455 - Dutton, Worline", label: "MO 455 - Dutton, Worline" },
  { value: "MO 463 - Degraff", label: "MO 463 - Degraff" },
  { value: "MO 468 - Spreitzer , Erwin", label: "MO 468 - Spreitzer , Erwin" },
  {
    value: "MOVESCI 110 - Bodary, Vesia, Gross",
    label: "MOVESCI 110 - Bodary, Vesia, Gross",
  },
  {
    value: "MOVESCI 110 - Gross, Vesia, Bodary",
    label: "MOVESCI 110 - Gross, Vesia, Bodary",
  },
  {
    value: "MOVESCI 110 - Vesia, Gross, Bodary",
    label: "MOVESCI 110 - Vesia, Gross, Bodary",
  },
  { value: "MOVESCI 219 - Clark", label: "MOVESCI 219 - Clark" },
  {
    value: "MOVESCI 219 - Palmieri-Smith",
    label: "MOVESCI 219 - Palmieri-Smith",
  },
  { value: "MOVESCI 219 - Richardson", label: "MOVESCI 219 - Richardson" },
  { value: "MOVESCI 230 - Gross", label: "MOVESCI 230 - Gross" },
  { value: "MOVESCI 240 - Chen", label: "MOVESCI 240 - Chen" },
  { value: "MOVESCI 241 - Bodary", label: "MOVESCI 241 - Bodary" },
  { value: "MOVESCI 250 - Lepley", label: "MOVESCI 250 - Lepley" },
  { value: "MOVESCI 320 - Brown", label: "MOVESCI 320 - Brown" },
  { value: "MOVESCI 330 - Lipps", label: "MOVESCI 330 - Lipps" },
  { value: "MOVESCI 340 - Ludlow", label: "MOVESCI 340 - Ludlow" },
  { value: "MOVESCI 361 - Mergos", label: "MOVESCI 361 - Mergos" },
  { value: "MOVESCI 363 - Mergos", label: "MOVESCI 363 - Mergos" },
  { value: "MOVESCI 403", label: "MOVESCI 403" },
  { value: "MOVESCI 413", label: "MOVESCI 413" },
  { value: "MOVESCI 413 - Mergos", label: "MOVESCI 413 - Mergos" },
  { value: "MOVESCI 413 - Umberger", label: "MOVESCI 413 - Umberger" },
  { value: "MOVESCI 422 - Vesia", label: "MOVESCI 422 - Vesia" },
  { value: "MOVESCI 425 - Ulrich", label: "MOVESCI 425 - Ulrich" },
  { value: "MOVESCI 429", label: "MOVESCI 429" },
  { value: "MOVESCI 434", label: "MOVESCI 434" },
  { value: "MOVESCI 439", label: "MOVESCI 439" },
  { value: "MOVESCI 442 - Haus", label: "MOVESCI 442 - Haus" },
  { value: "MOVESCI 446 - Hasson", label: "MOVESCI 446 - Hasson" },
  { value: "MOVESCI 449", label: "MOVESCI 449" },
  { value: "MOVESCI 451 - Harris", label: "MOVESCI 451 - Harris" },
  { value: "MOVESCI 452", label: "MOVESCI 452" },
  { value: "MOVESCI 475 - Czajka", label: "MOVESCI 475 - Czajka" },
  { value: "MUSED 111 - Fitzpatrick", label: "MUSED 111 - Fitzpatrick" },
  { value: "MUSED 202 - Hopkins", label: "MUSED 202 - Hopkins" },
  { value: "MUSED 203 - Vecchio", label: "MUSED 203 - Vecchio" },
  { value: "MUSED 205 - Vecchio", label: "MUSED 205 - Vecchio" },
  {
    value: "MUSED 207 - Urena Gonzalez, Fitzpatrick",
    label: "MUSED 207 - Urena Gonzalez, Fitzpatrick",
  },
  { value: "MUSED 320 - Skadsem", label: "MUSED 320 - Skadsem" },
  { value: "MUSED 340", label: "MUSED 340" },
  { value: "MUSED 340 - Skadsem", label: "MUSED 340 - Skadsem" },
  { value: "MUSED 342 - Rodriguez", label: "MUSED 342 - Rodriguez" },
  { value: "MUSED 370", label: "MUSED 370" },
  { value: "MUSED 370 - Hopkins", label: "MUSED 370 - Hopkins" },
  { value: "MUSED 371 - Weaver", label: "MUSED 371 - Weaver" },
  {
    value: "MUSED 408 - Collins, McCarthy",
    label: "MUSED 408 - Collins, McCarthy",
  },
  { value: "MUSED 470 - Conway", label: "MUSED 470 - Conway" },
  { value: "MUSED 495", label: "MUSED 495" },
  { value: "MUSED 496 - McCarthy", label: "MUSED 496 - McCarthy" },
  { value: "MUSEUMS 401 - Hennebury", label: "MUSEUMS 401 - Hennebury" },
  { value: "MUSICOL 122 - Lwanga", label: "MUSICOL 122 - Lwanga" },
  { value: "MUSICOL 123 - Garrett", label: "MUSICOL 123 - Garrett" },
  { value: "MUSICOL 130 - Stein", label: "MUSICOL 130 - Stein" },
  { value: "MUSICOL 240 - Pierce", label: "MUSICOL 240 - Pierce" },
  { value: "MUSICOL 306", label: "MUSICOL 306" },
  { value: "MUSICOL 346 - Bodiford", label: "MUSICOL 346 - Bodiford" },
  { value: "MUSICOL 346 - Mengozzi", label: "MUSICOL 346 - Mengozzi" },
  { value: "MUSICOL 346 - Stein", label: "MUSICOL 346 - Stein" },
  { value: "MUSICOL 405 - Kaur", label: "MUSICOL 405 - Kaur" },
  { value: "MUSICOL 406", label: "MUSICOL 406" },
  { value: "MUSICOL 406 - Castro", label: "MUSICOL 406 - Castro" },
  { value: "MUSICOL 406 - Lam", label: "MUSICOL 406 - Lam" },
  { value: "MUSICOL 406 - Whiting", label: "MUSICOL 406 - Whiting" },
  { value: "MUSICOL 407", label: "MUSICOL 407" },
  { value: "MUSICOL 407 - Garrett", label: "MUSICOL 407 - Garrett" },
  { value: "MUSICOL 407 - Velasquez", label: "MUSICOL 407 - Velasquez" },
  { value: "MUSICOL 408 - Pierce", label: "MUSICOL 408 - Pierce" },
  { value: "MUSICOL 414 - Cruz", label: "MUSICOL 414 - Cruz" },
  { value: "MUSICOL 477 - Borders", label: "MUSICOL 477 - Borders" },
  {
    value: "MUSPERF 200 - Andre, Brissey",
    label: "MUSPERF 200 - Andre, Brissey",
  },
  { value: "MUSPERF 300 - Thompson", label: "MUSPERF 300 - Thompson" },
  { value: "MUSPERF 401 - Aaron", label: "MUSPERF 401 - Aaron" },
  { value: "MUSPERF 401 - Belen", label: "MUSPERF 401 - Belen" },
  { value: "MUSPERF 401 - Berofsky", label: "MUSPERF 401 - Berofsky" },
  { value: "MUSPERF 401 - Cantor", label: "MUSPERF 401 - Cantor" },
  { value: "MUSPERF 401 - Chambers", label: "MUSPERF 401 - Chambers" },
  { value: "MUSPERF 401 - Coade", label: "MUSPERF 401 - Coade" },
  { value: "MUSPERF 401 - Corey", label: "MUSPERF 401 - Corey" },
  { value: "MUSPERF 401 - Goosman", label: "MUSPERF 401 - Goosman" },
  { value: "MUSPERF 401 - Greene", label: "MUSPERF 401 - Greene" },
  { value: "MUSPERF 401 - Hahn", label: "MUSPERF 401 - Hahn" },
  { value: "MUSPERF 401 - Halen", label: "MUSPERF 401 - Halen" },
  { value: "MUSPERF 401 - Harding", label: "MUSPERF 401 - Harding" },
  { value: "MUSPERF 401 - Jackson", label: "MUSPERF 401 - Jackson" },
  { value: "MUSPERF 401 - Katz", label: "MUSPERF 401 - Katz" },
  { value: "MUSPERF 401 - Kibbie", label: "MUSPERF 401 - Kibbie" },
  { value: "MUSPERF 401 - Kiesler", label: "MUSPERF 401 - Kiesler" },
  { value: "MUSPERF 401 - Porter", label: "MUSPERF 401 - Porter" },
  { value: "MUSPERF 401 - Sarath", label: "MUSPERF 401 - Sarath" },
  { value: "MUSPERF 401 - Skelton", label: "MUSPERF 401 - Skelton" },
  { value: "MUSPERF 401 - Washington", label: "MUSPERF 401 - Washington" },
  { value: "MUSTHTRE 280", label: "MUSTHTRE 280" },
  { value: "MUSTHTRE 324", label: "MUSTHTRE 324" },
  { value: "MUSTHTRE 336", label: "MUSTHTRE 336" },
  { value: "MUSTHTRE 352", label: "MUSTHTRE 352" },
  { value: "MUSTHTRE 354", label: "MUSTHTRE 354" },
  { value: "MUSTHTRE 360", label: "MUSTHTRE 360" },
  { value: "MUSTHTRE 424", label: "MUSTHTRE 424" },
  { value: "MUSTHTRE 436", label: "MUSTHTRE 436" },
  { value: "MUSTHTRE 450", label: "MUSTHTRE 450" },
  { value: "MUSTHTRE 458 - Shankel", label: "MUSTHTRE 458 - Shankel" },
  { value: "MUSTHTRE 480", label: "MUSTHTRE 480" },
  { value: "MUSTHTRE 496", label: "MUSTHTRE 496" },
  { value: "NATIVEAM 204 - Witgen", label: "NATIVEAM 204 - Witgen" },
  {
    value: "NATIVEAM 223 - Pitawanakwat",
    label: "NATIVEAM 223 - Pitawanakwat",
  },
  { value: "NATIVEAM 311 - Witgen", label: "NATIVEAM 311 - Witgen" },
  {
    value: "NATIVEAM 323 - Pitawanakwat",
    label: "NATIVEAM 323 - Pitawanakwat",
  },
  { value: "NATIVEAM 498 - Stillman", label: "NATIVEAM 498 - Stillman" },
  { value: "NAVARCH 210 - Karr", label: "NAVARCH 210 - Karr" },
  { value: "NAVARCH 280 - Pan", label: "NAVARCH 280 - Pan" },
  { value: "NAVARCH 310 - Vlahopoulos", label: "NAVARCH 310 - Vlahopoulos" },
  { value: "NAVARCH 321", label: "NAVARCH 321" },
  { value: "NAVARCH 321 - Bernitsas", label: "NAVARCH 321 - Bernitsas" },
  { value: "NAVARCH 332 - McCoy", label: "NAVARCH 332 - McCoy" },
  { value: "NAVARCH 370 - Singer", label: "NAVARCH 370 - Singer" },
  { value: "NAVARCH 440", label: "NAVARCH 440" },
  { value: "NAVARCH 440 - Maki", label: "NAVARCH 440 - Maki" },
  { value: "NAVARCH 461 - Dong", label: "NAVARCH 461 - Dong" },
  { value: "NAVARCH 471 - Singer", label: "NAVARCH 471 - Singer" },
  { value: "NAVARCH 499 - Bernitsas", label: "NAVARCH 499 - Bernitsas" },
  { value: "NAVSCI 102 - Nguyen", label: "NAVSCI 102 - Nguyen" },
  { value: "NAVSCI 202 - Dyer Jr", label: "NAVSCI 202 - Dyer Jr" },
  { value: "NAVSCI 301", label: "NAVSCI 301" },
  { value: "NAVSCI 310 - Kantaris", label: "NAVSCI 310 - Kantaris" },
  { value: "NAVSCI 410 - Kantaris", label: "NAVSCI 410 - Kantaris" },
  { value: "NERS 201 - Field", label: "NERS 201 - Field" },
  { value: "NERS 211 - Jovanovic", label: "NERS 211 - Jovanovic" },
  { value: "NERS 250", label: "NERS 250" },
  { value: "NERS 250 - Allen", label: "NERS 250 - Allen" },
  { value: "NERS 312 - Wehe", label: "NERS 312 - Wehe" },
  { value: "NERS 315", label: "NERS 315" },
  { value: "NERS 344 - Sun", label: "NERS 344 - Sun" },
  { value: "NERS 425", label: "NERS 425" },
  { value: "NERS 442 - Kochunas", label: "NERS 442 - Kochunas" },
  { value: "NERS 472 - Gilgenbach", label: "NERS 472 - Gilgenbach" },
  { value: "NERS 492 - Kuranz", label: "NERS 492 - Kuranz" },
  { value: "NERS 551", label: "NERS 551" },
  { value: "NURS 194 - Charania, Doman", label: "NURS 194 - Charania, Doman" },
  { value: "NURS 210 - Petrosyan", label: "NURS 210 - Petrosyan" },
  { value: "NURS 218 - Peitzmeier", label: "NURS 218 - Peitzmeier" },
  { value: "NURS 220 - Gabriel", label: "NURS 220 - Gabriel" },
  { value: "NURS 240 - Prochnow, Jiang", label: "NURS 240 - Prochnow, Jiang" },
  {
    value: "NURS 244 - Saslow, Anderson",
    label: "NURS 244 - Saslow, Anderson",
  },
  {
    value: "NURS 262 - Shakoor, Accurso",
    label: "NURS 262 - Shakoor, Accurso",
  },
  { value: "NURS 295 - Mutumba", label: "NURS 295 - Mutumba" },
  { value: "NURS 370 - Antilla", label: "NURS 370 - Antilla" },
  {
    value: "NURS 371 - Voepel-Lewis, Duffy",
    label: "NURS 371 - Voepel-Lewis, Duffy",
  },
  {
    value: "NURS 372 - Prochnow, Harden",
    label: "NURS 372 - Prochnow, Harden",
  },
  { value: "NURS 373 - Strobbe", label: "NURS 373 - Strobbe" },
  { value: "NURS 395 - Kahle", label: "NURS 395 - Kahle" },
  { value: "NURS 421 - Eagle", label: "NURS 421 - Eagle" },
  { value: "NURS 454 - Rosemberg", label: "NURS 454 - Rosemberg" },
  { value: "NURS 456 - Stoddard", label: "NURS 456 - Stoddard" },
  { value: "NURS 457 - Price", label: "NURS 457 - Price" },
  { value: "NURS 459 - Price", label: "NURS 459 - Price" },
  { value: "OBOE 111", label: "OBOE 111" },
  { value: "OPERA 341", label: "OPERA 341" },
  { value: "OPERA 451", label: "OPERA 451" },
  { value: "OPERA 455", label: "OPERA 455" },
  { value: "ORGSTUDY 195 - Fein", label: "ORGSTUDY 195 - Fein" },
  { value: "ORGSTUDY 201 - Sweetman", label: "ORGSTUDY 201 - Sweetman" },
  { value: "ORGSTUDY 204 - Levine", label: "ORGSTUDY 204 - Levine" },
  { value: "ORGSTUDY 208 - Soderstrom", label: "ORGSTUDY 208 - Soderstrom" },
  { value: "ORGSTUDY 425 - Ho", label: "ORGSTUDY 425 - Ho" },
  { value: "ORGSTUDY 440 - Samford", label: "ORGSTUDY 440 - Samford" },
  { value: "ORGSTUDY 445 - Fein", label: "ORGSTUDY 445 - Fein" },
  { value: "ORGSTUDY 450 - Samford", label: "ORGSTUDY 450 - Samford" },
  { value: "ORGSTUDY 495 - Camp", label: "ORGSTUDY 495 - Camp" },
  { value: "ORGSTUDY 495 - Sweetman", label: "ORGSTUDY 495 - Sweetman" },
  { value: "PAT 100 - Camc", label: "PAT 100 - Camc" },
  { value: "PAT 100 - Granzow", label: "PAT 100 - Granzow" },
  { value: "PAT 100 - Gurevich", label: "PAT 100 - Gurevich" },
  { value: "PAT 100 - Kirshner", label: "PAT 100 - Kirshner" },
  {
    value: "PAT 100 - OModhrain, Ulintz",
    label: "PAT 100 - OModhrain, Ulintz",
  },
  { value: "PAT 100 - Rush", label: "PAT 100 - Rush" },
  {
    value: "PAT 102 - OModhrain, Ulintz",
    label: "PAT 102 - OModhrain, Ulintz",
  },
  { value: "PAT 200 - Dooley", label: "PAT 200 - Dooley" },
  { value: "PAT 200 - Edwards", label: "PAT 200 - Edwards" },
  { value: "PAT 200 - hill", label: "PAT 200 - hill" },
  { value: "PAT 201 - Edwards", label: "PAT 201 - Edwards" },
  { value: "PAT 202 - Dooley", label: "PAT 202 - Dooley" },
  { value: "PAT 202 - Santos", label: "PAT 202 - Santos" },
  { value: "PAT 204 - Camc", label: "PAT 204 - Camc" },
  { value: "PAT 204 - Ozcan", label: "PAT 204 - Ozcan" },
  { value: "PAT 300 - Camc", label: "PAT 300 - Camc" },
  { value: "PAT 300 - Granzow", label: "PAT 300 - Granzow" },
  { value: "PAT 300 - Gurevich", label: "PAT 300 - Gurevich" },
  { value: "PAT 300 - Kirshner", label: "PAT 300 - Kirshner" },
  { value: "PAT 300 - OModhrain", label: "PAT 300 - OModhrain" },
  { value: "PAT 300 - Rush", label: "PAT 300 - Rush" },
  { value: "PAT 305 - Thompson", label: "PAT 305 - Thompson" },
  { value: "PAT 332", label: "PAT 332" },
  { value: "PAT 412 - Rush", label: "PAT 412 - Rush" },
  { value: "PAT 413 - Gurevich", label: "PAT 413 - Gurevich" },
  { value: "PAT 422 - Corey", label: "PAT 422 - Corey" },
  { value: "PAT 432 - Edwards", label: "PAT 432 - Edwards" },
  { value: "PAT 442 - Kirshner", label: "PAT 442 - Kirshner" },
  { value: "PAT 451", label: "PAT 451" },
  { value: "PAT 452 - Gurevich", label: "PAT 452 - Gurevich" },
  { value: "PAT 454 - Granzow", label: "PAT 454 - Granzow" },
  { value: "PAT 461 - Granzow", label: "PAT 461 - Granzow" },
  { value: "PAT 472 - Kuuskoski", label: "PAT 472 - Kuuskoski" },
  { value: "PAT 490 - Camc", label: "PAT 490 - Camc" },
  { value: "PAT 490 - Corey", label: "PAT 490 - Corey" },
  { value: "PAT 490 - Dooley", label: "PAT 490 - Dooley" },
  { value: "PAT 490 - Granzow", label: "PAT 490 - Granzow" },
  { value: "PAT 490 - Gurevich", label: "PAT 490 - Gurevich" },
  { value: "PAT 490 - Kirshner", label: "PAT 490 - Kirshner" },
  { value: "PAT 490 - OModhrain", label: "PAT 490 - OModhrain" },
  { value: "PAT 490 - Rush", label: "PAT 490 - Rush" },
  { value: "PAT 490 - Santos", label: "PAT 490 - Santos" },
  { value: "PAT 498 - LeBoeuf", label: "PAT 498 - LeBoeuf" },
  { value: "PERCUSS 488 - Perkins", label: "PERCUSS 488 - Perkins" },
  { value: "PERSIAN 102 - Aghaei", label: "PERSIAN 102 - Aghaei" },
  { value: "PERSIAN 202 - Aghaei", label: "PERSIAN 202 - Aghaei" },
  { value: "PHARMACY 212 - Luzum", label: "PHARMACY 212 - Luzum" },
  { value: "PHARMACY 217 - Ellingrod", label: "PHARMACY 217 - Ellingrod" },
  { value: "PHARMACY 412 - Hertz", label: "PHARMACY 412 - Hertz" },
  { value: "PHARMSCI 101 - Lee", label: "PHARMSCI 101 - Lee" },
  { value: "PHARMSCI 211 - Amidon", label: "PHARMSCI 211 - Amidon" },
  { value: "PHARMSCI 402 - Amidon", label: "PHARMSCI 402 - Amidon" },
  { value: "PHARMSCI 420 - Rosania", label: "PHARMSCI 420 - Rosania" },
  { value: "PHIL 101 - Joyce", label: "PHIL 101 - Joyce" },
  { value: "PHIL 110 - Edmonds", label: "PHIL 110 - Edmonds" },
  { value: "PHIL 183 - Edmonds", label: "PHIL 183 - Edmonds" },
  { value: "PHIL 196 - Baker", label: "PHIL 196 - Baker" },
  { value: "PHIL 230 - Lopez Jr", label: "PHIL 230 - Lopez Jr" },
  { value: "PHIL 289 - Harmon", label: "PHIL 289 - Harmon" },
  { value: "PHIL 298 - Fairchild", label: "PHIL 298 - Fairchild" },
  { value: "PHIL 305 - Weatherson", label: "PHIL 305 - Weatherson" },
  { value: "PHIL 340 - Swanson", label: "PHIL 340 - Swanson" },
  { value: "PHIL 355 - Lowe", label: "PHIL 355 - Lowe" },
  { value: "PHIL 359 - Maitra", label: "PHIL 359 - Maitra" },
  { value: "PHIL 361 - Railton", label: "PHIL 361 - Railton" },
  { value: "PHIL 365 - Mandair", label: "PHIL 365 - Mandair" },
  { value: "PHIL 376 - Bouma", label: "PHIL 376 - Bouma" },
  { value: "PHIL 383 - Lormand", label: "PHIL 383 - Lormand" },
  { value: "PHIL 391 - Caston", label: "PHIL 391 - Caston" },
  { value: "PHIL 397 - Davis", label: "PHIL 397 - Davis" },
  { value: "PHIL 414 - Thomason", label: "PHIL 414 - Thomason" },
  { value: "PHIL 416 - Fairchild", label: "PHIL 416 - Fairchild" },
  { value: "PHIL 430 - Munroe", label: "PHIL 430 - Munroe" },
  { value: "PHIL 444 - Weatherson", label: "PHIL 444 - Weatherson" },
  { value: "PHIL 446 - Maitra", label: "PHIL 446 - Maitra" },
  { value: "PHIL 450 - Lormand", label: "PHIL 450 - Lormand" },
  { value: "PHIL 475 - Gailus", label: "PHIL 475 - Gailus" },
  {
    value: "PHRMACOL 425 - Jutkiewicz, Courtney, OMalley, Hernandez-Casner",
    label: "PHRMACOL 425 - Jutkiewicz, Courtney, OMalley, Hernandez-Casner",
  },
  { value: "PHYSED 255 - Winkelseth", label: "PHYSED 255 - Winkelseth" },
  { value: "PHYSED 336 - Winkelseth", label: "PHYSED 336 - Winkelseth" },
  { value: "PHYSICS 106 - Chupp", label: "PHYSICS 106 - Chupp" },
  { value: "PHYSICS 115 - Tomasch", label: "PHYSICS 115 - Tomasch" },
  { value: "PHYSICS 116 - Spitz", label: "PHYSICS 116 - Spitz" },
  { value: "PHYSICS 119", label: "PHYSICS 119" },
  { value: "PHYSICS 119 - Spica", label: "PHYSICS 119 - Spica" },
  { value: "PHYSICS 121 - Tomasch", label: "PHYSICS 121 - Tomasch" },
  { value: "PHYSICS 135", label: "PHYSICS 135" },
  { value: "PHYSICS 135 - Lau", label: "PHYSICS 135 - Lau" },
  { value: "PHYSICS 135 - Melnichuk", label: "PHYSICS 135 - Melnichuk" },
  { value: "PHYSICS 140", label: "PHYSICS 140" },
  { value: "PHYSICS 140 - Eckhause", label: "PHYSICS 140 - Eckhause" },
  { value: "PHYSICS 140 - Evrard", label: "PHYSICS 140 - Evrard" },
  { value: "PHYSICS 140 - Melnichuk", label: "PHYSICS 140 - Melnichuk" },
  { value: "PHYSICS 140 - Popov", label: "PHYSICS 140 - Popov" },
  { value: "PHYSICS 160 - Merlin", label: "PHYSICS 160 - Merlin" },
  { value: "PHYSICS 235", label: "PHYSICS 235" },
  { value: "PHYSICS 235 - Chaney", label: "PHYSICS 235 - Chaney" },
  { value: "PHYSICS 235 - Meiners", label: "PHYSICS 235 - Meiners" },
  { value: "PHYSICS 240 - Schwarz", label: "PHYSICS 240 - Schwarz" },
  { value: "PHYSICS 240 - Winn", label: "PHYSICS 240 - Winn" },
  {
    value: "PHYSICS 260 - Campbell, Spanton",
    label: "PHYSICS 260 - Campbell, Spanton",
  },
  { value: "PHYSICS 288 - Newman", label: "PHYSICS 288 - Newman" },
  { value: "PHYSICS 290 - Zochowski", label: "PHYSICS 290 - Zochowski" },
  {
    value: "PHYSICS 340 - Uher, Eckhause",
    label: "PHYSICS 340 - Uher, Eckhause",
  },
  { value: "PHYSICS 351 - Deegan", label: "PHYSICS 351 - Deegan" },
  { value: "PHYSICS 360 - Elvang", label: "PHYSICS 360 - Elvang" },
  {
    value: "PHYSICS 390 - Soares-Santos",
    label: "PHYSICS 390 - Soares-Santos",
  },
  { value: "PHYSICS 391", label: "PHYSICS 391" },
  { value: "PHYSICS 401 - Zhu", label: "PHYSICS 401 - Zhu" },
  { value: "PHYSICS 405 - Qian", label: "PHYSICS 405 - Qian" },
  { value: "PHYSICS 406 - Aidala", label: "PHYSICS 406 - Aidala" },
  { value: "PHYSICS 411 - Gull", label: "PHYSICS 411 - Gull" },
  { value: "PHYSICS 417 - Yang", label: "PHYSICS 417 - Yang" },
  { value: "PHYSICS 420 - Avestruz", label: "PHYSICS 420 - Avestruz" },
  { value: "PHYSICS 430 - Ivanova", label: "PHYSICS 430 - Ivanova" },
  { value: "PHYSICS 441", label: "PHYSICS 441" },
  { value: "PHYSICS 442", label: "PHYSICS 442" },
  { value: "PHYSICS 450", label: "PHYSICS 450" },
  { value: "PHYSICS 453 - Riles", label: "PHYSICS 453 - Riles" },
  { value: "PHYSICS 457 - Zhou", label: "PHYSICS 457 - Zhou" },
  { value: "PHYSICS 463 - Mao", label: "PHYSICS 463 - Mao" },
  { value: "PHYSIOL 404", label: "PHYSIOL 404" },
  { value: "PHYSIOL 415", label: "PHYSIOL 415" },
  { value: "PIANO 110", label: "PIANO 110" },
  { value: "PIANO 110 - Cho", label: "PIANO 110 - Cho" },
  { value: "PIANO 110 - Kasman", label: "PIANO 110 - Kasman" },
  { value: "PIANO 110 - Kim", label: "PIANO 110 - Kim" },
  { value: "PIANO 110 - Lee", label: "PIANO 110 - Lee" },
  { value: "PIANO 110 - Ma", label: "PIANO 110 - Ma" },
  { value: "PIANO 110 - Solomonick", label: "PIANO 110 - Solomonick" },
  { value: "PIANO 110 - Wong", label: "PIANO 110 - Wong" },
  { value: "PIANO 110 - Zhang", label: "PIANO 110 - Zhang" },
  { value: "PIANO 111 - Cho", label: "PIANO 111 - Cho" },
  { value: "PIANO 111 - Lee", label: "PIANO 111 - Lee" },
  { value: "PIANO 111 - Liu", label: "PIANO 111 - Liu" },
  { value: "PIANO 111 - Wong", label: "PIANO 111 - Wong" },
  { value: "PIANO 112 - Benson", label: "PIANO 112 - Benson" },
  { value: "PIANO 112 - Kim", label: "PIANO 112 - Kim" },
  { value: "PIANO 112 - Ren", label: "PIANO 112 - Ren" },
  { value: "PIANO 112 - Sahin", label: "PIANO 112 - Sahin" },
  { value: "PIANO 112 - Santoso", label: "PIANO 112 - Santoso" },
  { value: "PIANO 112 - Shinnick", label: "PIANO 112 - Shinnick" },
  { value: "PIANO 112 - Wan", label: "PIANO 112 - Wan" },
  { value: "PIANO 112 - Yang", label: "PIANO 112 - Yang" },
  { value: "PIANO 113 - Chang", label: "PIANO 113 - Chang" },
  { value: "PIANO 114 - Chang", label: "PIANO 114 - Chang" },
  { value: "PIANO 205 - Howell", label: "PIANO 205 - Howell" },
  { value: "PIANOLP 402 - Grijalva", label: "PIANOLP 402 - Grijalva" },
  { value: "PNE 201", label: "PNE 201" },
  { value: "PNE 202", label: "PNE 202" },
  { value: "PNE 203", label: "PNE 203" },
  { value: "PNE 204", label: "PNE 204" },
  { value: "PNE 205", label: "PNE 205" },
  { value: "PNE 206", label: "PNE 206" },
  { value: "PNE 207", label: "PNE 207" },
  { value: "PNE 208", label: "PNE 208" },
  { value: "PNE 209", label: "PNE 209" },
  { value: "PNE 210", label: "PNE 210" },
  { value: "PNE 211", label: "PNE 211" },
  { value: "PNE 212", label: "PNE 212" },
  { value: "PNE 213", label: "PNE 213" },
  { value: "PNE 214", label: "PNE 214" },
  { value: "PNE 215", label: "PNE 215" },
  { value: "PNE 216", label: "PNE 216" },
  { value: "PNE 217", label: "PNE 217" },
  { value: "PNE 218", label: "PNE 218" },
  { value: "PNE 219", label: "PNE 219" },
  { value: "PNE 220", label: "PNE 220" },
  { value: "PNE 221", label: "PNE 221" },
  { value: "PNE 222", label: "PNE 222" },
  { value: "PNE 223", label: "PNE 223" },
  { value: "PNE 224", label: "PNE 224" },
  { value: "PNE 225", label: "PNE 225" },
  { value: "PNE 301", label: "PNE 301" },
  { value: "PNE 302", label: "PNE 302" },
  { value: "PNE 303", label: "PNE 303" },
  { value: "PNE 304", label: "PNE 304" },
  { value: "PNE 305", label: "PNE 305" },
  { value: "PNE 306", label: "PNE 306" },
  { value: "PNE 307", label: "PNE 307" },
  { value: "PNE 308", label: "PNE 308" },
  { value: "PNE 309", label: "PNE 309" },
  { value: "PNE 310", label: "PNE 310" },
  { value: "PNE 311", label: "PNE 311" },
  { value: "PNE 312", label: "PNE 312" },
  { value: "PNE 326", label: "PNE 326" },
  { value: "PNE 328", label: "PNE 328" },
  { value: "PNE 329", label: "PNE 329" },
  { value: "PNE 330", label: "PNE 330" },
  { value: "PNE 331", label: "PNE 331" },
  { value: "PNE 332", label: "PNE 332" },
  { value: "PNE 333", label: "PNE 333" },
  { value: "PNE 334", label: "PNE 334" },
  { value: "PNE 335", label: "PNE 335" },
  { value: "PNE 336", label: "PNE 336" },
  { value: "PNE 337", label: "PNE 337" },
  { value: "PNE 351", label: "PNE 351" },
  { value: "PNE 353", label: "PNE 353" },
  { value: "PNE 357", label: "PNE 357" },
  { value: "PNE 359", label: "PNE 359" },
  { value: "PNE 360", label: "PNE 360" },
  { value: "PNE 361", label: "PNE 361" },
  { value: "PNE 362", label: "PNE 362" },
  { value: "PNE 363", label: "PNE 363" },
  { value: "PNE 364", label: "PNE 364" },
  { value: "PNE 365", label: "PNE 365" },
  { value: "PNE 366", label: "PNE 366" },
  { value: "PNE 376", label: "PNE 376" },
  { value: "PNE 377", label: "PNE 377" },
  { value: "PNE 378", label: "PNE 378" },
  { value: "PNE 379", label: "PNE 379" },
  { value: "PNE 380", label: "PNE 380" },
  { value: "PNE 382", label: "PNE 382" },
  { value: "PNE 383", label: "PNE 383" },
  { value: "PNE 384", label: "PNE 384" },
  { value: "PNE 385", label: "PNE 385" },
  { value: "PNE 386", label: "PNE 386" },
  { value: "PNE 387", label: "PNE 387" },
  { value: "PNE 402", label: "PNE 402" },
  { value: "PNE 403", label: "PNE 403" },
  { value: "PNE 404", label: "PNE 404" },
  { value: "PNE 406", label: "PNE 406" },
  { value: "PNE 407", label: "PNE 407" },
  { value: "PNE 408", label: "PNE 408" },
  { value: "PNE 409", label: "PNE 409" },
  { value: "PNE 410", label: "PNE 410" },
  { value: "PNE 411", label: "PNE 411" },
  { value: "PNE 426", label: "PNE 426" },
  { value: "PNE 427", label: "PNE 427" },
  { value: "PNE 428", label: "PNE 428" },
  { value: "PNE 429", label: "PNE 429" },
  { value: "PNE 430", label: "PNE 430" },
  { value: "PNE 431", label: "PNE 431" },
  { value: "PNE 432", label: "PNE 432" },
  { value: "PNE 434", label: "PNE 434" },
  { value: "PNE 435", label: "PNE 435" },
  { value: "PNE 436", label: "PNE 436" },
  { value: "PNE 438", label: "PNE 438" },
  { value: "PNE 439", label: "PNE 439" },
  { value: "PNE 442", label: "PNE 442" },
  { value: "PNE 451", label: "PNE 451" },
  { value: "PNE 452", label: "PNE 452" },
  { value: "PNE 453", label: "PNE 453" },
  { value: "PNE 455", label: "PNE 455" },
  { value: "PNE 458", label: "PNE 458" },
  { value: "PNE 461", label: "PNE 461" },
  { value: "PNE 462", label: "PNE 462" },
  { value: "PNE 463", label: "PNE 463" },
  { value: "PNE 464", label: "PNE 464" },
  { value: "PNE 465", label: "PNE 465" },
  { value: "PNE 466", label: "PNE 466" },
  { value: "PNE 467", label: "PNE 467" },
  { value: "POLISH 122 - Westwalewicz", label: "POLISH 122 - Westwalewicz" },
  { value: "POLISH 222 - Westwalewicz", label: "POLISH 222 - Westwalewicz" },
  { value: "POLISH 314", label: "POLISH 314" },
  { value: "POLISH 322 - Pasek", label: "POLISH 322 - Pasek" },
  { value: "POLISH 422 - Pasek", label: "POLISH 422 - Pasek" },
  { value: "POLSCI 101 - LaVaque-Manty", label: "POLSCI 101 - LaVaque-Manty" },
  { value: "POLSCI 111 - Shipan", label: "POLSCI 111 - Shipan" },
  { value: "POLSCI 140 - Hicken", label: "POLSCI 140 - Hicken" },
  { value: "POLSCI 160 - Koremenos", label: "POLSCI 160 - Koremenos" },
  { value: "POLSCI 190 - Hall", label: "POLSCI 190 - Hall" },
  { value: "POLSCI 190 - Kinder", label: "POLSCI 190 - Kinder" },
  { value: "POLSCI 190 - Ostfeld", label: "POLSCI 190 - Ostfeld" },
  { value: "POLSCI 307 - Manuel", label: "POLSCI 307 - Manuel" },
  { value: "POLSCI 320 - Lowande", label: "POLSCI 320 - Lowande" },
  { value: "POLSCI 324 - Hutchings", label: "POLSCI 324 - Hutchings" },
  { value: "POLSCI 339 - Gallagher", label: "POLSCI 339 - Gallagher" },
  { value: "POLSCI 340 - Tsebelis", label: "POLSCI 340 - Tsebelis" },
  { value: "POLSCI 381 - LaVaque-Manty", label: "POLSCI 381 - LaVaque-Manty" },
  { value: "POLSCI 386 - Markovits", label: "POLSCI 386 - Markovits" },
  { value: "POLSCI 389 - Fong", label: "POLSCI 389 - Fong" },
  { value: "POLSCI 389 - Inglehart", label: "POLSCI 389 - Inglehart" },
  { value: "POLSCI 389 - Morrow", label: "POLSCI 389 - Morrow" },
  { value: "POLSCI 389 - Nordaas", label: "POLSCI 389 - Nordaas" },
  { value: "POLSCI 389 - Peel", label: "POLSCI 389 - Peel" },
  { value: "POLSCI 389 - Rivers", label: "POLSCI 389 - Rivers" },
  { value: "POLSCI 389 - Temin", label: "POLSCI 389 - Temin" },
  { value: "POLSCI 396 - Westwalewicz", label: "POLSCI 396 - Westwalewicz" },
  { value: "POLSCI 399 - Horner", label: "POLSCI 399 - Horner" },
  { value: "POLSCI 402 - Tyler", label: "POLSCI 402 - Tyler" },
  { value: "POLSCI 434 - Suny", label: "POLSCI 434 - Suny" },
  { value: "POLSCI 489 - Davenport", label: "POLSCI 489 - Davenport" },
  { value: "POLSCI 489 - Davis", label: "POLSCI 489 - Davis" },
  { value: "POLSCI 489 - Markovits", label: "POLSCI 489 - Markovits" },
  { value: "POLSCI 489 - Mickey", label: "POLSCI 489 - Mickey" },
  { value: "POLSCI 489 - Nathan", label: "POLSCI 489 - Nathan" },
  { value: "POLSCI 489 - Nordaas", label: "POLSCI 489 - Nordaas" },
  { value: "POLSCI 489 - Pitcher", label: "POLSCI 489 - Pitcher" },
  { value: "POLSCI 489 - Shiraito", label: "POLSCI 489 - Shiraito" },
  { value: "POLSCI 489 - Zeisberg", label: "POLSCI 489 - Zeisberg" },
  { value: "POLSCI 491 - Demessie", label: "POLSCI 491 - Demessie" },
  { value: "POLSCI 492", label: "POLSCI 492" },
  { value: "POLSCI 494 - Min", label: "POLSCI 494 - Min" },
  { value: "POLSCI 495 - Heffernan", label: "POLSCI 495 - Heffernan" },
  { value: "POLSCI 495 - Manuel", label: "POLSCI 495 - Manuel" },
  { value: "POLSCI 496 - Ocampo", label: "POLSCI 496 - Ocampo" },
  { value: "POLSCI 498 - Fariss", label: "POLSCI 498 - Fariss" },
  {
    value: "PORTUG 102 - Segarra Costaguta Mattos",
    label: "PORTUG 102 - Segarra Costaguta Mattos",
  },
  {
    value: "PORTUG 232 - Segarra Costaguta Mattos",
    label: "PORTUG 232 - Segarra Costaguta Mattos",
  },
  {
    value: "PORTUG 287 - Vieira Parrine SantAna",
    label: "PORTUG 287 - Vieira Parrine SantAna",
  },
  { value: "PPE 300 - Lowe", label: "PPE 300 - Lowe" },
  { value: "PPE 400 - Lowe", label: "PPE 400 - Lowe" },
  { value: "PSYCH 111 - Buvinger", label: "PSYCH 111 - Buvinger" },
  { value: "PSYCH 111 - Hoeffner", label: "PSYCH 111 - Hoeffner" },
  { value: "PSYCH 111 - Schreier", label: "PSYCH 111 - Schreier" },
  { value: "PSYCH 112 - Malley", label: "PSYCH 112 - Malley" },
  { value: "PSYCH 120 - Jodl", label: "PSYCH 120 - Jodl" },
  { value: "PSYCH 120 - Park", label: "PSYCH 120 - Park" },
  { value: "PSYCH 122 - Hicks", label: "PSYCH 122 - Hicks" },
  { value: "PSYCH 211", label: "PSYCH 211" },
  { value: "PSYCH 213", label: "PSYCH 213" },
  { value: "PSYCH 220 - Cummings", label: "PSYCH 220 - Cummings" },
  { value: "PSYCH 223 - Fretz", label: "PSYCH 223 - Fretz" },
  { value: "PSYCH 230 - Rothschild", label: "PSYCH 230 - Rothschild" },
  { value: "PSYCH 240", label: "PSYCH 240" },
  { value: "PSYCH 242 - Baptista", label: "PSYCH 242 - Baptista" },
  { value: "PSYCH 250 - Ward", label: "PSYCH 250 - Ward" },
  { value: "PSYCH 250 - Warneken", label: "PSYCH 250 - Warneken" },
  { value: "PSYCH 265 - Cowles", label: "PSYCH 265 - Cowles" },
  { value: "PSYCH 270 - Lopez-Duran", label: "PSYCH 270 - Lopez-Duran" },
  { value: "PSYCH 280 - Ackerman", label: "PSYCH 280 - Ackerman" },
  { value: "PSYCH 290 - Durkee", label: "PSYCH 290 - Durkee" },
  { value: "PSYCH 291", label: "PSYCH 291" },
  { value: "PSYCH 303 - Rabinowitz", label: "PSYCH 303 - Rabinowitz" },
  { value: "PSYCH 304", label: "PSYCH 304" },
  { value: "PSYCH 305", label: "PSYCH 305" },
  { value: "PSYCH 306", label: "PSYCH 306" },
  { value: "PSYCH 308", label: "PSYCH 308" },
  { value: "PSYCH 310 - Hwang", label: "PSYCH 310 - Hwang" },
  { value: "PSYCH 310 - Schoem", label: "PSYCH 310 - Schoem" },
  { value: "PSYCH 311", label: "PSYCH 311" },
  { value: "PSYCH 311 - Hicks", label: "PSYCH 311 - Hicks" },
  { value: "PSYCH 312", label: "PSYCH 312" },
  { value: "PSYCH 324", label: "PSYCH 324" },
  { value: "PSYCH 325", label: "PSYCH 325" },
  { value: "PSYCH 333 - Berridge", label: "PSYCH 333 - Berridge" },
  { value: "PSYCH 337 - Beehner", label: "PSYCH 337 - Beehner" },
  { value: "PSYCH 339", label: "PSYCH 339" },
  { value: "PSYCH 340 - Brang", label: "PSYCH 340 - Brang" },
  { value: "PSYCH 340 - Perkins", label: "PSYCH 340 - Perkins" },
  { value: "PSYCH 341", label: "PSYCH 341" },
  { value: "PSYCH 344 - Guo", label: "PSYCH 344 - Guo" },
  { value: "PSYCH 345 - Zahodne", label: "PSYCH 345 - Zahodne" },
  { value: "PSYCH 347 - Snodgrass", label: "PSYCH 347 - Snodgrass" },
  { value: "PSYCH 349 - Levinson", label: "PSYCH 349 - Levinson" },
  {
    value: "PSYCH 352 - Gardner-Neblett",
    label: "PSYCH 352 - Gardner-Neblett",
  },
  { value: "PSYCH 353 - Schreier", label: "PSYCH 353 - Schreier" },
  { value: "PSYCH 355 - Gelman", label: "PSYCH 355 - Gelman" },
  { value: "PSYCH 355 - Perkins", label: "PSYCH 355 - Perkins" },
  { value: "PSYCH 358 - Keating", label: "PSYCH 358 - Keating" },
  { value: "PSYCH 362 - De Young", label: "PSYCH 362 - De Young" },
  { value: "PSYCH 371", label: "PSYCH 371" },
  { value: "PSYCH 373 - Olson", label: "PSYCH 373 - Olson" },
  { value: "PSYCH 380 - Sekaquaptewa", label: "PSYCH 380 - Sekaquaptewa" },
  { value: "PSYCH 389 - Dunning", label: "PSYCH 389 - Dunning" },
  { value: "PSYCH 390 - Mahalingam", label: "PSYCH 390 - Mahalingam" },
  { value: "PSYCH 393 - Rabinowitz", label: "PSYCH 393 - Rabinowitz" },
  { value: "PSYCH 395 - Kira", label: "PSYCH 395 - Kira" },
  { value: "PSYCH 401 - Aragona", label: "PSYCH 401 - Aragona" },
  { value: "PSYCH 401 - Beischel", label: "PSYCH 401 - Beischel" },
  { value: "PSYCH 401 - Belgrade", label: "PSYCH 401 - Belgrade" },
  { value: "PSYCH 401 - Gohar", label: "PSYCH 401 - Gohar" },
  { value: "PSYCH 401 - Holden", label: "PSYCH 401 - Holden" },
  { value: "PSYCH 401 - Malley", label: "PSYCH 401 - Malley" },
  { value: "PSYCH 401 - Melani", label: "PSYCH 401 - Melani" },
  { value: "PSYCH 401 - Miller", label: "PSYCH 401 - Miller" },
  { value: "PSYCH 401 - Rabinowitz", label: "PSYCH 401 - Rabinowitz" },
  { value: "PSYCH 401 - Rios", label: "PSYCH 401 - Rios" },
  { value: "PSYCH 401 - Schiestl", label: "PSYCH 401 - Schiestl" },
  { value: "PSYCH 402 - Baumgartner", label: "PSYCH 402 - Baumgartner" },
  { value: "PSYCH 411 - Kaplowitz", label: "PSYCH 411 - Kaplowitz" },
  { value: "PSYCH 413 - Pachella", label: "PSYCH 413 - Pachella" },
  { value: "PSYCH 424 - Lustig", label: "PSYCH 424 - Lustig" },
  { value: "PSYCH 426 - Lustig", label: "PSYCH 426 - Lustig" },
  { value: "PSYCH 429 - Lustig", label: "PSYCH 429 - Lustig" },
  { value: "PSYCH 430 - Aragona", label: "PSYCH 430 - Aragona" },
  { value: "PSYCH 430 - Cummings", label: "PSYCH 430 - Cummings" },
  { value: "PSYCH 433 - Ahmed", label: "PSYCH 433 - Ahmed" },
  { value: "PSYCH 435 - Sarter", label: "PSYCH 435 - Sarter" },
  {
    value: "PSYCH 436 - Eban-Rothschild",
    label: "PSYCH 436 - Eban-Rothschild",
  },
  { value: "PSYCH 440 - Perkins", label: "PSYCH 440 - Perkins" },
  { value: "PSYCH 445 - Boland", label: "PSYCH 445 - Boland" },
  { value: "PSYCH 446 - Preston", label: "PSYCH 446 - Preston" },
  { value: "PSYCH 447 - Meyer", label: "PSYCH 447 - Meyer" },
  { value: "PSYCH 447 - Snodgrass", label: "PSYCH 447 - Snodgrass" },
  { value: "PSYCH 449 - Michal", label: "PSYCH 449 - Michal" },
  { value: "PSYCH 456 - Volling", label: "PSYCH 456 - Volling" },
  { value: "PSYCH 457 - Jodl", label: "PSYCH 457 - Jodl" },
  { value: "PSYCH 457 - Miller", label: "PSYCH 457 - Miller" },
  { value: "PSYCH 467 - Cortina", label: "PSYCH 467 - Cortina" },
  { value: "PSYCH 467 - Kira", label: "PSYCH 467 - Kira" },
  { value: "PSYCH 469 - Rosenbaum", label: "PSYCH 469 - Rosenbaum" },
  { value: "PSYCH 474 - Chang", label: "PSYCH 474 - Chang" },
  { value: "PSYCH 475 - Graham-Bermann", label: "PSYCH 475 - Graham-Bermann" },
  { value: "PSYCH 477 - Nagata", label: "PSYCH 477 - Nagata" },
  { value: "PUBHLTH 200 - Youatt", label: "PUBHLTH 200 - Youatt" },
  { value: "PUBHLTH 305 - Rozek", label: "PUBHLTH 305 - Rozek" },
  {
    value: "PUBHLTH 309 - Bauer, Aaronson",
    label: "PUBHLTH 309 - Bauer, Aaronson",
  },
  { value: "PUBHLTH 310 - Anderson", label: "PUBHLTH 310 - Anderson" },
  { value: "PUBHLTH 313 - Harper", label: "PUBHLTH 313 - Harper" },
  { value: "PUBHLTH 382 - Neblett", label: "PUBHLTH 382 - Neblett" },
  { value: "PUBHLTH 383 - Zawistowski", label: "PUBHLTH 383 - Zawistowski" },
  { value: "PUBHLTH 402 - Piette", label: "PUBHLTH 402 - Piette" },
  { value: "PUBHLTH 413 - Yang", label: "PUBHLTH 413 - Yang" },
  { value: "PUBHLTH 460 - Rickard", label: "PUBHLTH 460 - Rickard" },
  { value: "PUBPOL 250 - Raimi", label: "PUBPOL 250 - Raimi" },
  { value: "PUBPOL 300 - Spencer", label: "PUBPOL 300 - Spencer" },
  { value: "PUBPOL 413", label: "PUBPOL 413" },
  { value: "PUBPOL 413 - Scheerer", label: "PUBPOL 413 - Scheerer" },
  { value: "PUBPOL 418 - Henry", label: "PUBPOL 418 - Henry" },
  { value: "PUBPOL 423 - Hills II", label: "PUBPOL 423 - Hills II" },
  { value: "PUBPOL 447", label: "PUBPOL 447" },
  { value: "PUBPOL 456 - Hieftje", label: "PUBPOL 456 - Hieftje" },
  { value: "PUBPOL 467", label: "PUBPOL 467" },
  { value: "PUBPOL 474 - Green", label: "PUBPOL 474 - Green" },
  { value: "PUBPOL 474 - Henry", label: "PUBPOL 474 - Henry" },
  { value: "PUBPOL 474 - Kabo", label: "PUBPOL 474 - Kabo" },
  { value: "PUBPOL 475 - Bednar", label: "PUBPOL 475 - Bednar" },
  {
    value: "PUBPOL 475 - Farley, Neidert",
    label: "PUBPOL 475 - Farley, Neidert",
  },
  { value: "PUBPOL 475 - Hall", label: "PUBPOL 475 - Hall" },
  { value: "PUBPOL 475 - Harris", label: "PUBPOL 475 - Harris" },
  { value: "PUBPOL 475 - Levitsky", label: "PUBPOL 475 - Levitsky" },
  { value: "PUBPOL 475 - Raimi", label: "PUBPOL 475 - Raimi" },
  { value: "PUBPOL 477", label: "PUBPOL 477" },
  { value: "PUBPOL 495 - Ali", label: "PUBPOL 495 - Ali" },
  { value: "PUBPOL 495 - Morse", label: "PUBPOL 495 - Morse" },
  { value: "PUBPOL 495 - Pilkauskas", label: "PUBPOL 495 - Pilkauskas" },
  { value: "QMSS 201 - Whitaker", label: "QMSS 201 - Whitaker" },
  { value: "QMSS 251 - Bruch, Romero", label: "QMSS 251 - Bruch, Romero" },
  { value: "QMSS 451 - Whitaker", label: "QMSS 451 - Whitaker" },
  { value: "RCARTS 270", label: "RCARTS 270" },
  { value: "RCARTS 334", label: "RCARTS 334" },
  { value: "RCARTS 350", label: "RCARTS 350" },
  { value: "RCARTS 370", label: "RCARTS 370" },
  { value: "RCARTS 379", label: "RCARTS 379" },
  { value: "RCARTS 390", label: "RCARTS 390" },
  { value: "RCARTS 399", label: "RCARTS 399" },
  { value: "RCASL 102 - Berwanger", label: "RCASL 102 - Berwanger" },
  { value: "RCASL 202 - Berwanger", label: "RCASL 202 - Berwanger" },
  { value: "RCCORE 100 - Brandel", label: "RCCORE 100 - Brandel" },
  { value: "RCCORE 100 - Stainton", label: "RCCORE 100 - Stainton" },
  { value: "RCCORE 334 - Merrill", label: "RCCORE 334 - Merrill" },
  { value: "RCHUMS 150 - Flinn", label: "RCHUMS 150 - Flinn" },
  { value: "RCHUMS 202 - Wetzel", label: "RCHUMS 202 - Wetzel" },
  { value: "RCHUMS 220 - Matthews", label: "RCHUMS 220 - Matthews" },
  { value: "RCHUMS 221 - Messer", label: "RCHUMS 221 - Messer" },
  { value: "RCHUMS 251 - Wells", label: "RCHUMS 251 - Wells" },
  { value: "RCHUMS 272 - Ward", label: "RCHUMS 272 - Ward" },
  { value: "RCHUMS 273 - Oh", label: "RCHUMS 273 - Oh" },
  { value: "RCHUMS 280 - Westlake", label: "RCHUMS 280 - Westlake" },
  { value: "RCHUMS 281 - Mendeloff", label: "RCHUMS 281 - Mendeloff" },
  { value: "RCHUMS 303", label: "RCHUMS 303" },
  { value: "RCHUMS 312", label: "RCHUMS 312" },
  { value: "RCHUMS 320 - Thomas", label: "RCHUMS 320 - Thomas" },
  { value: "RCHUMS 321 - Messer", label: "RCHUMS 321 - Messer" },
  { value: "RCHUMS 325 - Matthews", label: "RCHUMS 325 - Matthews" },
  { value: "RCHUMS 325 - Messer", label: "RCHUMS 325 - Messer" },
  { value: "RCHUMS 325 - Rosegrant", label: "RCHUMS 325 - Rosegrant" },
  { value: "RCHUMS 325 - Thomas", label: "RCHUMS 325 - Thomas" },
  { value: "RCHUMS 326 - Matthews", label: "RCHUMS 326 - Matthews" },
  { value: "RCHUMS 326 - Messer", label: "RCHUMS 326 - Messer" },
  { value: "RCHUMS 326 - Rosegrant", label: "RCHUMS 326 - Rosegrant" },
  { value: "RCHUMS 326 - Thomas", label: "RCHUMS 326 - Thomas" },
  { value: "RCHUMS 334", label: "RCHUMS 334" },
  { value: "RCHUMS 334 - Amrine", label: "RCHUMS 334 - Amrine" },
  {
    value: "RCHUMS 334 - Andre, Brissey",
    label: "RCHUMS 334 - Andre, Brissey",
  },
  { value: "RCHUMS 334 - Crasnow", label: "RCHUMS 334 - Crasnow" },
  {
    value: "RCHUMS 334 - Gordon-Gurfinkel",
    label: "RCHUMS 334 - Gordon-Gurfinkel",
  },
  { value: "RCHUMS 334 - Maiorova", label: "RCHUMS 334 - Maiorova" },
  { value: "RCHUMS 334 - Matthews", label: "RCHUMS 334 - Matthews" },
  { value: "RCHUMS 334 - Rosegrant", label: "RCHUMS 334 - Rosegrant" },
  { value: "RCHUMS 334 - Steinberg", label: "RCHUMS 334 - Steinberg" },
  { value: "RCHUMS 334 - Wells", label: "RCHUMS 334 - Wells" },
  { value: "RCHUMS 335 - Lucas", label: "RCHUMS 335 - Lucas" },
  { value: "RCHUMS 337 - Goodenough", label: "RCHUMS 337 - Goodenough" },
  { value: "RCHUMS 341", label: "RCHUMS 341" },
  {
    value: "RCHUMS 341 - Gordon-Gurfinkel",
    label: "RCHUMS 341 - Gordon-Gurfinkel",
  },
  { value: "RCHUMS 344 - Willette", label: "RCHUMS 344 - Willette" },
  { value: "RCHUMS 381 - Walsh", label: "RCHUMS 381 - Walsh" },
  { value: "RCHUMS 387", label: "RCHUMS 387" },
  { value: "RCHUMS 423", label: "RCHUMS 423" },
  { value: "RCHUMS 425 - Matthews", label: "RCHUMS 425 - Matthews" },
  { value: "RCHUMS 425 - Messer", label: "RCHUMS 425 - Messer" },
  { value: "RCHUMS 425 - Rosegrant", label: "RCHUMS 425 - Rosegrant" },
  { value: "RCHUMS 425 - Thomas", label: "RCHUMS 425 - Thomas" },
  { value: "RCHUMS 426 - Matthews", label: "RCHUMS 426 - Matthews" },
  { value: "RCHUMS 426 - Messer", label: "RCHUMS 426 - Messer" },
  { value: "RCHUMS 426 - Rosegrant", label: "RCHUMS 426 - Rosegrant" },
  { value: "RCHUMS 426 - Thomas", label: "RCHUMS 426 - Thomas" },
  { value: "RCHUMS 481", label: "RCHUMS 481" },
  { value: "RCHUMS 481 - Mendeloff", label: "RCHUMS 481 - Mendeloff" },
  { value: "RCHUMS 482", label: "RCHUMS 482" },
  { value: "RCHUMS 482 - Mendeloff", label: "RCHUMS 482 - Mendeloff" },
  { value: "RCIDIV 305 - Murphy", label: "RCIDIV 305 - Murphy" },
  { value: "RCIDIV 341 - Crisostomo", label: "RCIDIV 341 - Crisostomo" },
  { value: "RCIDIV 350 - Crowell", label: "RCIDIV 350 - Crowell" },
  {
    value: "RCIDIV 350 - Regester, Berger",
    label: "RCIDIV 350 - Regester, Berger",
  },
  { value: "RCIDIV 351 - Burkam", label: "RCIDIV 351 - Burkam" },
  { value: "RCIDIV 390 - Murphy", label: "RCIDIV 390 - Murphy" },
  { value: "RCIDIV 391 - Soderstrom", label: "RCIDIV 391 - Soderstrom" },
  { value: "RCLANG 190 - Bayraktar", label: "RCLANG 190 - Bayraktar" },
  {
    value: "RCLANG 191 - Cribari-Assali",
    label: "RCLANG 191 - Cribari-Assali",
  },
  {
    value: "RCLANG 194 - Gutierrez Tashian",
    label: "RCLANG 194 - Gutierrez Tashian",
  },
  { value: "RCLANG 256 - Okuno", label: "RCLANG 256 - Okuno" },
  {
    value: "RCLANG 290 - Butler-Borruat",
    label: "RCLANG 290 - Butler-Borruat",
  },
  { value: "RCLANG 291 - Goertz", label: "RCLANG 291 - Goertz" },
  { value: "RCLANG 293 - Makin", label: "RCLANG 293 - Makin" },
  { value: "RCLANG 294 - Nunez", label: "RCLANG 294 - Nunez" },
  { value: "RCLANG 296 - Okuno", label: "RCLANG 296 - Okuno" },
  { value: "RCLANG 310 - Bayraktar", label: "RCLANG 310 - Bayraktar" },
  {
    value: "RCLANG 320 - Butler-Borruat",
    label: "RCLANG 320 - Butler-Borruat",
  },
  { value: "RCLANG 320 - Filion", label: "RCLANG 320 - Filion" },
  { value: "RCLANG 321 - Goertz", label: "RCLANG 321 - Goertz" },
  { value: "RCLANG 324 - Espinoza-Pino", label: "RCLANG 324 - Espinoza-Pino" },
  { value: "RCLANG 324 - Lopez-Cotin", label: "RCLANG 324 - Lopez-Cotin" },
  { value: "RCLANG 324 - Munoz-Diaz", label: "RCLANG 324 - Munoz-Diaz" },
  { value: "RCLANG 334 - Espinoza-Pino", label: "RCLANG 334 - Espinoza-Pino" },
  { value: "RCMUSIC 258 - Gould", label: "RCMUSIC 258 - Gould" },
  { value: "RCMUSIC 320", label: "RCMUSIC 320" },
  { value: "RCMUSIC 321", label: "RCMUSIC 321" },
  { value: "RCMUSIC 350 - Kirschenmann", label: "RCMUSIC 350 - Kirschenmann" },
  { value: "RCMUSIC 351", label: "RCMUSIC 351" },
  { value: "RCNSCI 202 - Benedict", label: "RCNSCI 202 - Benedict" },
  { value: "RCNSCI 260 - Evans", label: "RCNSCI 260 - Evans" },
  { value: "RCSSCI 230 - Burkam", label: "RCSSCI 230 - Burkam" },
  { value: "RCSSCI 250 - Kawar", label: "RCSSCI 250 - Kawar" },
  { value: "RCSSCI 260 - Lynch", label: "RCSSCI 260 - Lynch" },
  { value: "RCSSCI 275 - Carson", label: "RCSSCI 275 - Carson" },
  { value: "RCSSCI 290 - Murphy", label: "RCSSCI 290 - Murphy" },
  { value: "RCSSCI 301 - Robinson", label: "RCSSCI 301 - Robinson" },
  { value: "RCSSCI 330 - Ward", label: "RCSSCI 330 - Ward" },
  { value: "RCSSCI 343 - Zelner", label: "RCSSCI 343 - Zelner" },
  { value: "RCSSCI 350 - DAnieri", label: "RCSSCI 350 - DAnieri" },
  { value: "RCSSCI 360 - Dillard", label: "RCSSCI 360 - Dillard" },
  { value: "RCSSCI 360 - Krinitsky", label: "RCSSCI 360 - Krinitsky" },
  { value: "RCSSCI 360 - Lynch", label: "RCSSCI 360 - Lynch" },
  { value: "RCSSCI 360 - Pickus", label: "RCSSCI 360 - Pickus" },
  { value: "RCSSCI 360 - Sanchez-Snell", label: "RCSSCI 360 - Sanchez-Snell" },
  { value: "RCSSCI 360 - Turnley", label: "RCSSCI 360 - Turnley" },
  { value: "RCSSCI 371 - Ahn", label: "RCSSCI 371 - Ahn" },
  { value: "RCSSCI 390 - Dyer", label: "RCSSCI 390 - Dyer" },
  {
    value: "RCSSCI 390 - Romano Gillette",
    label: "RCSSCI 390 - Romano Gillette",
  },
  { value: "RCSSCI 461 - Kawar", label: "RCSSCI 461 - Kawar" },
  { value: "RCSSCI 461 - Pickus", label: "RCSSCI 461 - Pickus" },
  { value: "REEES 396 - Westwalewicz", label: "REEES 396 - Westwalewicz" },
  { value: "RELIGION 111 - Scott", label: "RELIGION 111 - Scott" },
  { value: "RELIGION 204 - Knysh", label: "RELIGION 204 - Knysh" },
  { value: "RELIGION 225 - Brick", label: "RELIGION 225 - Brick" },
  { value: "RELIGION 230 - Lopez Jr", label: "RELIGION 230 - Lopez Jr" },
  {
    value: "RELIGION 231 - Ujeed, Lopez Jr",
    label: "RELIGION 231 - Ujeed, Lopez Jr",
  },
  { value: "RELIGION 236 - Boccaccini", label: "RELIGION 236 - Boccaccini" },
  { value: "RELIGION 248 - de la Cruz", label: "RELIGION 248 - de la Cruz" },
  {
    value: "RELIGION 258 - Levinson, Fox, Robertson",
    label: "RELIGION 258 - Levinson, Fox, Robertson",
  },
  { value: "RELIGION 260 - Neis", label: "RELIGION 260 - Neis" },
  { value: "RELIGION 262", label: "RELIGION 262" },
  { value: "RELIGION 306 - Mandair", label: "RELIGION 306 - Mandair" },
  { value: "RELIGION 325 - Mir", label: "RELIGION 325 - Mir" },
  { value: "RELIGION 326 - Eliav", label: "RELIGION 326 - Eliav" },
  { value: "RELIGION 328 - White", label: "RELIGION 328 - White" },
  { value: "RELIGION 365 - Mandair", label: "RELIGION 365 - Mandair" },
  { value: "ROB 103", label: "ROB 103" },
  { value: "ROMLANG 250 - Ekotto", label: "ROMLANG 250 - Ekotto" },
  { value: "ROMLING 298 - Roosevelt", label: "ROMLING 298 - Roosevelt" },
  { value: "ROMLING 333 - Goetz", label: "ROMLING 333 - Goetz" },
  { value: "ROMLING 413 - Garcia-Amaya", label: "ROMLING 413 - Garcia-Amaya" },
  { value: "ROMLING 415 - Gallego", label: "ROMLING 415 - Gallego" },
  { value: "ROMLING 487 - Henriksen", label: "ROMLING 487 - Henriksen" },
  { value: "ROMLING 487 - Satterfield", label: "ROMLING 487 - Satterfield" },
  { value: "RUSSIAN 102", label: "RUSSIAN 102" },
  { value: "RUSSIAN 202", label: "RUSSIAN 202" },
  { value: "RUSSIAN 203 - Makin", label: "RUSSIAN 203 - Makin" },
  { value: "RUSSIAN 225 - Shkolnik", label: "RUSSIAN 225 - Shkolnik" },
  { value: "RUSSIAN 302 - Shkolnik", label: "RUSSIAN 302 - Shkolnik" },
  { value: "RUSSIAN 346 - Maiorova", label: "RUSSIAN 346 - Maiorova" },
  { value: "RUSSIAN 375 - Maiorova", label: "RUSSIAN 375 - Maiorova" },
  { value: "RUSSIAN 402", label: "RUSSIAN 402" },
  { value: "RUSSIAN 402 - Shkolnik", label: "RUSSIAN 402 - Shkolnik" },
  { value: "RUSSIAN 450 - Makin", label: "RUSSIAN 450 - Makin" },
  { value: "RUSSIAN 499 - Makin", label: "RUSSIAN 499 - Makin" },
  { value: "SACREDMU 491 - Kuperus", label: "SACREDMU 491 - Kuperus" },
  { value: "SCAND 104 - Gull", label: "SCAND 104 - Gull" },
  { value: "SCAND 234 - Eriksson", label: "SCAND 234 - Eriksson" },
  { value: "SCAND 325 - Eriksson", label: "SCAND 325 - Eriksson" },
  { value: "SCAND 442 - Gull", label: "SCAND 442 - Gull" },
  { value: "SEAS 455 - Phung", label: "SEAS 455 - Phung" },
  { value: "SI 106 - Oney", label: "SI 106 - Oney" },
  { value: "SI 110 - Wallace", label: "SI 110 - Wallace" },
  { value: "SI 206 - Ericson", label: "SI 206 - Ericson" },
  {
    value: "SI 320 - Godin, Besprozvany",
    label: "SI 320 - Godin, Besprozvany",
  },
  { value: "SI 330 - Li", label: "SI 330 - Li" },
  { value: "SI 334 - Cohn", label: "SI 334 - Cohn" },
  { value: "SI 339 - van Lent", label: "SI 339 - van Lent" },
  { value: "SI 340 - Chen", label: "SI 340 - Chen" },
  { value: "SI 360 - Borland", label: "SI 360 - Borland" },
  { value: "SI 364 - Severance", label: "SI 364 - Severance" },
  { value: "SI 390 - Frazier", label: "SI 390 - Frazier" },
  { value: "SI 410 - Pasquetto", label: "SI 410 - Pasquetto" },
  { value: "SI 422 - Marcu", label: "SI 422 - Marcu" },
  { value: "SI 430 - Naseem", label: "SI 430 - Naseem" },
  { value: "SI 431 - Schoenebeck", label: "SI 431 - Schoenebeck" },
  { value: "SI 460 - Borland", label: "SI 460 - Borland" },
  { value: "SI 485 - Jacobs", label: "SI 485 - Jacobs" },
  { value: "SI 487", label: "SI 487" },
  { value: "SI 489 - Schoenebeck", label: "SI 489 - Schoenebeck" },
  { value: "SIABRD 301", label: "SIABRD 301" },
  { value: "SIABRD 302", label: "SIABRD 302" },
  { value: "SIABRD 303", label: "SIABRD 303" },
  { value: "SIABRD 304", label: "SIABRD 304" },
  { value: "SIABRD 330", label: "SIABRD 330" },
  { value: "SLAVIC 150 - Pasek", label: "SLAVIC 150 - Pasek" },
  { value: "SLAVIC 151 - Khagi", label: "SLAVIC 151 - Khagi" },
  { value: "SLAVIC 225 - Toman", label: "SLAVIC 225 - Toman" },
  { value: "SLAVIC 245 - Aleksic", label: "SLAVIC 245 - Aleksic" },
  { value: "SLAVIC 290", label: "SLAVIC 290" },
  { value: "SLAVIC 290 - Krutikov", label: "SLAVIC 290 - Krutikov" },
  { value: "SLAVIC 290 - Luchina", label: "SLAVIC 290 - Luchina" },
  { value: "SLAVIC 290 - Pasek", label: "SLAVIC 290 - Pasek" },
  { value: "SLAVIC 312", label: "SLAVIC 312" },
  { value: "SLAVIC 396 - Westwalewicz", label: "SLAVIC 396 - Westwalewicz" },
  { value: "SLAVIC 470 - Pierce", label: "SLAVIC 470 - Pierce" },
  { value: "SLAVIC 470 - Toman", label: "SLAVIC 470 - Toman" },
  { value: "SLAVIC 471 - Herscher", label: "SLAVIC 471 - Herscher" },
  { value: "SM 100 - Button", label: "SM 100 - Button" },
  { value: "SM 100 - Simpkins", label: "SM 100 - Simpkins" },
  { value: "SM 101", label: "SM 101" },
  { value: "SM 101 - Basten", label: "SM 101 - Basten" },
  { value: "SM 101 - Luther", label: "SM 101 - Luther" },
  { value: "SM 111", label: "SM 111" },
  { value: "SM 111 - Basten", label: "SM 111 - Basten" },
  { value: "SM 203", label: "SM 203" },
  { value: "SM 203 - George", label: "SM 203 - George" },
  { value: "SM 217 - Donahue", label: "SM 217 - Donahue" },
  { value: "SM 217 - Phelan", label: "SM 217 - Phelan" },
  { value: "SM 238 - Szymanski", label: "SM 238 - Szymanski" },
  { value: "SM 239 - Szymanski", label: "SM 239 - Szymanski" },
  { value: "SM 241 - Fort", label: "SM 241 - Fort" },
  { value: "SM 246 - Basten", label: "SM 246 - Basten" },
  { value: "SM 249 - Park", label: "SM 249 - Park" },
  { value: "SM 313 - Grant Long", label: "SM 313 - Grant Long" },
  { value: "SM 313 - Wade", label: "SM 313 - Wade" },
  { value: "SM 313 - Wang", label: "SM 313 - Wang" },
  { value: "SM 317 - Donahue", label: "SM 317 - Donahue" },
  { value: "SM 330 - Wang", label: "SM 330 - Wang" },
  { value: "SM 331 - Fort", label: "SM 331 - Fort" },
  { value: "SM 332 - Heinze", label: "SM 332 - Heinze" },
  { value: "SM 333 - Pollick", label: "SM 333 - Pollick" },
  { value: "SM 403", label: "SM 403" },
  { value: "SM 430 - Babiak", label: "SM 430 - Babiak" },
  { value: "SM 431 - Sant", label: "SM 431 - Sant" },
  { value: "SM 432 - Phelan", label: "SM 432 - Phelan" },
  { value: "SM 433 - Pollick", label: "SM 433 - Pollick" },
  { value: "SM 435 - Kwak", label: "SM 435 - Kwak" },
  { value: "SM 436 - Pollick", label: "SM 436 - Pollick" },
  { value: "SM 437 - George", label: "SM 437 - George" },
  { value: "SM 438 - Fort", label: "SM 438 - Fort" },
  { value: "SM 439 - Kwak", label: "SM 439 - Kwak" },
  { value: "SM 440 - Rosentraub", label: "SM 440 - Rosentraub" },
  { value: "SM 441 - Rosentraub", label: "SM 441 - Rosentraub" },
  { value: "SM 444 - Wade", label: "SM 444 - Wade" },
  { value: "SM 445 - Sant", label: "SM 445 - Sant" },
  { value: "SM 446 - Collins", label: "SM 446 - Collins" },
  { value: "SM 450 - Wang", label: "SM 450 - Wang" },
  { value: "SM 454 - Park", label: "SM 454 - Park" },
  { value: "SM 499 - Babiak", label: "SM 499 - Babiak" },
  { value: "SM 499 - Phelan", label: "SM 499 - Phelan" },
  { value: "SOC 100 - Gaston", label: "SOC 100 - Gaston" },
  { value: "SOC 100 - McGinn", label: "SOC 100 - McGinn" },
  { value: "SOC 102 - Morenoff", label: "SOC 102 - Morenoff" },
  { value: "SOC 102 - Zelner", label: "SOC 102 - Zelner" },
  { value: "SOC 105 - Lacy", label: "SOC 105 - Lacy" },
  { value: "SOC 105 - Ybarra", label: "SOC 105 - Ybarra" },
  { value: "SOC 122 - Hicks", label: "SOC 122 - Hicks" },
  { value: "SOC 210 - Manduca", label: "SOC 210 - Manduca" },
  { value: "SOC 210 - Zhou", label: "SOC 210 - Zhou" },
  { value: "SOC 218", label: "SOC 218" },
  { value: "SOC 225 - Christensen", label: "SOC 225 - Christensen" },
  { value: "SOC 233 - Lacy", label: "SOC 233 - Lacy" },
  { value: "SOC 242 - Sullivan", label: "SOC 242 - Sullivan" },
  { value: "SOC 270 - Peterson", label: "SOC 270 - Peterson" },
  { value: "SOC 285 - McGinn", label: "SOC 285 - McGinn" },
  { value: "SOC 295 - Gong", label: "SOC 295 - Gong" },
  { value: "SOC 295 - Levine", label: "SOC 295 - Levine" },
  { value: "SOC 295 - Watkins-Hayes", label: "SOC 295 - Watkins-Hayes" },
  { value: "SOC 300 - McGinn", label: "SOC 300 - McGinn" },
  { value: "SOC 302 - Kelley", label: "SOC 302 - Kelley" },
  { value: "SOC 303 - Mahan", label: "SOC 303 - Mahan" },
  { value: "SOC 310 - Ybarra", label: "SOC 310 - Ybarra" },
  { value: "SOC 313 - Robinson", label: "SOC 313 - Robinson" },
  { value: "SOC 320 - Hwang", label: "SOC 320 - Hwang" },
  { value: "SOC 320 - Schoem", label: "SOC 320 - Schoem" },
  { value: "SOC 321", label: "SOC 321" },
  { value: "SOC 321 - Hicks", label: "SOC 321 - Hicks" },
  { value: "SOC 324", label: "SOC 324" },
  { value: "SOC 325 - Christensen", label: "SOC 325 - Christensen" },
  { value: "SOC 331 - Neidert", label: "SOC 331 - Neidert" },
  { value: "SOC 335 - Zelner", label: "SOC 335 - Zelner" },
  { value: "SOC 344 - Martin", label: "SOC 344 - Martin" },
  { value: "SOC 346 - McGann", label: "SOC 346 - McGann" },
  { value: "SOC 368 - McGann", label: "SOC 368 - McGann" },
  { value: "SOC 375", label: "SOC 375" },
  { value: "SOC 379 - Markovits", label: "SOC 379 - Markovits" },
  { value: "SOC 391 - Ellman", label: "SOC 391 - Ellman" },
  { value: "SOC 393 - Westwalewicz", label: "SOC 393 - Westwalewicz" },
  { value: "SOC 398 - Sullivan", label: "SOC 398 - Sullivan" },
  { value: "SOC 447 - McGann", label: "SOC 447 - McGann" },
  { value: "SOC 451 - Smock", label: "SOC 451 - Smock" },
  { value: "SOC 458 - Schoem", label: "SOC 458 - Schoem" },
  { value: "SOC 471 - Kaplowitz", label: "SOC 471 - Kaplowitz" },
  { value: "SOC 476 - Kelley", label: "SOC 476 - Kelley" },
  { value: "SOC 495 - Byrd", label: "SOC 495 - Byrd" },
  { value: "SOC 495 - Camp", label: "SOC 495 - Camp" },
  { value: "SOC 495 - Frye", label: "SOC 495 - Frye" },
  { value: "SOC 495 - Kelley", label: "SOC 495 - Kelley" },
  { value: "SOC 495 - Mahan", label: "SOC 495 - Mahan" },
  { value: "SOC 495 - Pickus", label: "SOC 495 - Pickus" },
  { value: "SOC 495 - Steinmetz", label: "SOC 495 - Steinmetz" },
  { value: "SOC 497 - Armstrong", label: "SOC 497 - Armstrong" },
  { value: "SOC 499 - Anspach", label: "SOC 499 - Anspach" },
  { value: "SPACE 101 - Gilbert", label: "SPACE 101 - Gilbert" },
  { value: "SPACE 103 - Landi", label: "SPACE 103 - Landi" },
  { value: "SPACE 280", label: "SPACE 280" },
  { value: "SPACE 323", label: "SPACE 323" },
  { value: "SPACE 350 - Huang", label: "SPACE 350 - Huang" },
  { value: "SPACE 381", label: "SPACE 381" },
  { value: "SPACE 405 - Ruf", label: "SPACE 405 - Ruf" },
  { value: "SPACE 423 - Liemohn", label: "SPACE 423 - Liemohn" },
  { value: "SPACE 431 - Budhu", label: "SPACE 431 - Budhu" },
  { value: "SPACE 471 - Moldwin", label: "SPACE 471 - Moldwin" },
  { value: "SPACE 477 - Jia", label: "SPACE 477 - Jia" },
  { value: "SPANISH 100", label: "SPANISH 100" },
  { value: "SPANISH 101", label: "SPANISH 101" },
  { value: "SPANISH 102", label: "SPANISH 102" },
  { value: "SPANISH 102 - Piper", label: "SPANISH 102 - Piper" },
  { value: "SPANISH 103", label: "SPANISH 103" },
  { value: "SPANISH 103 - Piper", label: "SPANISH 103 - Piper" },
  { value: "SPANISH 230", label: "SPANISH 230" },
  { value: "SPANISH 231", label: "SPANISH 231" },
  { value: "SPANISH 232", label: "SPANISH 232" },
  { value: "SPANISH 232 - Calixto", label: "SPANISH 232 - Calixto" },
  { value: "SPANISH 232 - McAlister", label: "SPANISH 232 - McAlister" },
  {
    value: "SPANISH 232 - Ramos Silgado",
    label: "SPANISH 232 - Ramos Silgado",
  },
  { value: "SPANISH 277", label: "SPANISH 277" },
  {
    value: "SPANISH 278 - Galvan-Santibanez",
    label: "SPANISH 278 - Galvan-Santibanez",
  },
  { value: "SPANISH 280 - dos Santos", label: "SPANISH 280 - dos Santos" },
  { value: "SPANISH 280 - Hernandez", label: "SPANISH 280 - Hernandez" },
  {
    value: "SPANISH 280 - Hernandez-Vogt",
    label: "SPANISH 280 - Hernandez-Vogt",
  },
  { value: "SPANISH 280 - Highfill", label: "SPANISH 280 - Highfill" },
  { value: "SPANISH 280 - Huerta Bravo", label: "SPANISH 280 - Huerta Bravo" },
  {
    value: "SPANISH 280 - Sanchez Gumiel",
    label: "SPANISH 280 - Sanchez Gumiel",
  },
  { value: "SPANISH 280 - Williams", label: "SPANISH 280 - Williams" },
  { value: "SPANISH 283 - Dorantes", label: "SPANISH 283 - Dorantes" },
  { value: "SPANISH 287 - Gallego", label: "SPANISH 287 - Gallego" },
  { value: "SPANISH 287 - Larrotiz", label: "SPANISH 287 - Larrotiz" },
  { value: "SPANISH 289 - Dorantes", label: "SPANISH 289 - Dorantes" },
  { value: "SPANISH 289 - Pollard", label: "SPANISH 289 - Pollard" },
  {
    value: "SPANISH 295 - Freire Hermida",
    label: "SPANISH 295 - Freire Hermida",
  },
  {
    value: "SPANISH 295 - Gutierrez Chavarria, Freire Hermida",
    label: "SPANISH 295 - Gutierrez Chavarria, Freire Hermida",
  },
  {
    value: "SPANISH 295 - Hansen, Freire Hermida",
    label: "SPANISH 295 - Hansen, Freire Hermida",
  },
  {
    value: "SPANISH 295 - Severs, Freire Hermida",
    label: "SPANISH 295 - Severs, Freire Hermida",
  },
  { value: "SPANISH 296 - Campbell", label: "SPANISH 296 - Campbell" },
  { value: "SPANISH 298 - Roosevelt", label: "SPANISH 298 - Roosevelt" },
  {
    value: "SPANISH 315 - Herrero-Olaizola",
    label: "SPANISH 315 - Herrero-Olaizola",
  },
  { value: "SPANISH 332 - Fridman", label: "SPANISH 332 - Fridman" },
  { value: "SPANISH 333 - Goetz", label: "SPANISH 333 - Goetz" },
  { value: "SPANISH 368 - Nunez", label: "SPANISH 368 - Nunez" },
  { value: "SPANISH 373", label: "SPANISH 373" },
  { value: "SPANISH 373 - Couret", label: "SPANISH 373 - Couret" },
  { value: "SPANISH 373 - Ferrari", label: "SPANISH 373 - Ferrari" },
  { value: "SPANISH 373 - Ruiz Mendoza", label: "SPANISH 373 - Ruiz Mendoza" },
  { value: "SPANISH 373 - Satterfield", label: "SPANISH 373 - Satterfield" },
  { value: "SPANISH 381 - Nemser", label: "SPANISH 381 - Nemser" },
  {
    value: "SPANISH 385 - De Entrambasaguas-Monsell",
    label: "SPANISH 385 - De Entrambasaguas-Monsell",
  },
  { value: "SPANISH 387 - Szpiech", label: "SPANISH 387 - Szpiech" },
  { value: "SPANISH 405 - Coll Ramirez", label: "SPANISH 405 - Coll Ramirez" },
  { value: "SPANISH 413 - Garcia-Amaya", label: "SPANISH 413 - Garcia-Amaya" },
  { value: "SPANISH 415 - Gallego", label: "SPANISH 415 - Gallego" },
  {
    value: "SPANISH 421 - De Entrambasaguas-Monsell",
    label: "SPANISH 421 - De Entrambasaguas-Monsell",
  },
  {
    value: "SPANISH 426 - Herrero-Olaizola",
    label: "SPANISH 426 - Herrero-Olaizola",
  },
  { value: "SPANISH 430 - Boalick", label: "SPANISH 430 - Boalick" },
  { value: "SPANISH 432 - Nunez", label: "SPANISH 432 - Nunez" },
  { value: "SPANISH 438 - Nemser", label: "SPANISH 438 - Nemser" },
  { value: "SPANISH 438 - Sanjines", label: "SPANISH 438 - Sanjines" },
  {
    value: "SPANISH 438 - Villalobos Ruminott",
    label: "SPANISH 438 - Villalobos Ruminott",
  },
  { value: "IOE 425 - Levantrosser", label: "IOE 425 - Levantrosser" },
  { value: "ITALIAN 101 - Garrido Baez", label: "ITALIAN 101 - Garrido Baez" },
  { value: "JUDAIC 217 - Wollenberg", label: "JUDAIC 217 - Wollenberg" },
  { value: "JUDAIC 440 - Goldman", label: "JUDAIC 440 - Goldman" },
  { value: "KRSTD 290 - Walsh", label: "KRSTD 290 - Walsh" },
  { value: "LATIN 101 - Goggin", label: "LATIN 101 - Goggin" },
  { value: "LATIN 410 - Dufallo", label: "LATIN 410 - Dufallo" },
  { value: "LATIN 472 - Soter", label: "LATIN 472 - Soter" },
  { value: "LSWA 230 - Manis", label: "LSWA 230 - Manis" },
  { value: "MATH 412 - Elduque", label: "MATH 412 - Elduque" },
  { value: "MATH 417 - Zou", label: "MATH 417 - Zou" },
  { value: "MATH 419 - Smythe", label: "MATH 419 - Smythe" },
  { value: "MATH 419 - Zhang", label: "MATH 419 - Zhang" },
  { value: "MATH 471 - Zhang", label: "MATH 471 - Zhang" },
  { value: "MCDB 417 - Csankovszki", label: "MCDB 417 - Csankovszki" },
  { value: "MECHENG 240 - Popa", label: "MECHENG 240 - Popa" },
  { value: "MECHENG 320 - Towne", label: "MECHENG 320 - Towne" },
  { value: "MEDCHEM 410", label: "MEDCHEM 410" },
  {
    value: "MICRBIOL 405 - Sandkvist, Eaton, Telesnitsky, Barron",
    label: "MICRBIOL 405 - Sandkvist, Eaton, Telesnitsky, Barron",
  },
  { value: "MIDEAST 338 - Richards", label: "MIDEAST 338 - Richards" },
  { value: "MILSCI 402", label: "MILSCI 402" },
  { value: "MKT 300 - Huang", label: "MKT 300 - Huang" },
  {
    value: "MOVESCI 110 - Bodary, Gross, Vesia",
    label: "MOVESCI 110 - Bodary, Gross, Vesia",
  },
  { value: "MOVESCI 443 - Ludlow", label: "MOVESCI 443 - Ludlow" },
  { value: "MUSICOL 140 - Clague", label: "MUSICOL 140 - Clague" },
  { value: "MUSICOL 345 - Lwanga", label: "MUSICOL 345 - Lwanga" },
  { value: "MUSPERF 401 - Wagner", label: "MUSPERF 401 - Wagner" },
  { value: "NAVARCH 431 - Lee", label: "NAVARCH 431 - Lee" },
  {
    value: "NAVARCH 475 - Collette, Khan",
    label: "NAVARCH 475 - Collette, Khan",
  },
  { value: "NAVSCI 402", label: "NAVSCI 402" },
  { value: "NURS 238 - Mutumba", label: "NURS 238 - Mutumba" },
  { value: "ORGSTUDY 490", label: "ORGSTUDY 490" },
  { value: "PAT 400 - Rush", label: "PAT 400 - Rush" },
  { value: "PHARMSCI 499 - Lee", label: "PHARMSCI 499 - Lee" },
  {
    value: "PHRMACOL 425 - Jutkiewicz, Lee, Morales Rodriguez",
    label: "PHRMACOL 425 - Jutkiewicz, Lee, Morales Rodriguez",
  },
  { value: "PHYSIOL 201 - Rust", label: "PHYSIOL 201 - Rust" },
  { value: "PIANO 460", label: "PIANO 460" },
  { value: "PNE 327", label: "PNE 327" },
  { value: "PNE 355", label: "PNE 355" },
  { value: "PNE 381", label: "PNE 381" },
  { value: "PNE 401", label: "PNE 401" },
  { value: "PNE 460", label: "PNE 460" },
  { value: "POLSCI 387 - Tsebelis", label: "POLSCI 387 - Tsebelis" },
  { value: "POLSCI 435 - Tessler", label: "POLSCI 435 - Tessler" },
  {
    value: "PORTUG 280 - Segarra Costaguta Mattos",
    label: "PORTUG 280 - Segarra Costaguta Mattos",
  },
  { value: "PSYCH 330 - Rosati", label: "PSYCH 330 - Rosati" },
  { value: "PSYCH 401 - Buvinger", label: "PSYCH 401 - Buvinger" },
  { value: "PSYCH 457 - Carter", label: "PSYCH 457 - Carter" },
  { value: "PUBPOL 457", label: "PUBPOL 457" },
  { value: "RCHUMS 334 - Burde", label: "RCHUMS 334 - Burde" },
  { value: "RCLANG 334", label: "RCLANG 334" },
  { value: "RCSSCI 461 - Caulfield", label: "RCSSCI 461 - Caulfield" },
  { value: "REEES 405 - Toman", label: "REEES 405 - Toman" },
  { value: "RUSSIAN 103", label: "RUSSIAN 103" },
  { value: "SIABRD 351", label: "SIABRD 351" },
  { value: "SLAVIC 281 - Pinsker", label: "SLAVIC 281 - Pinsker" },
  { value: "SOC 305 - Steinmetz", label: "SOC 305 - Steinmetz" },
  { value: "SPACE 321 - Li", label: "SPACE 321 - Li" },
  {
    value: "SPANISH 280 - Martinez Alvarez",
    label: "SPANISH 280 - Martinez Alvarez",
  },
  { value: "SPANISH 342 - Russo", label: "SPANISH 342 - Russo" },
  { value: "SPANISH 437 - Arnall", label: "SPANISH 437 - Arnall" },
  { value: "MATH 215 - Irmak", label: "MATH 215 - Irmak" },
  { value: "MATH 472 - Yan", label: "MATH 472 - Yan" },
  { value: "MO 302 - Sandelands", label: "MO 302 - Sandelands" },
  { value: "MUSPERF 401 - Lusmann", label: "MUSPERF 401 - Lusmann" },
  { value: "MUSTHTRE 442", label: "MUSTHTRE 442" },
  { value: "PHIL 417 - Belot", label: "PHIL 417 - Belot" },
];
